import { useApolloClient, useMutation } from '@apollo/client';
import { faDotCircle } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown, faChevronRight, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import _ from '@lodash';
import { Button, Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useEntitySearchApp } from 'app/shared-components/EntitySearch/EntitySearchApp';
import { EntitySearchMenu } from 'app/shared-components/EntitySearch/EntitySearchMenu';
import { UserSearchViewCreateDialog } from 'app/shared-components/UserSearchView/UserSearchViewCreateDialog';
import { UserSearchViewHitsListOption } from 'app/shared-components/UserSearchView/UserSearchViewHitsListOption';
import { UserSearchViewUpdateSettingsDialog } from 'app/shared-components/UserSearchView/UserSearchViewUpdateSettingsDialog';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInstantSearch } from 'react-instantsearch';
import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_USER_SEARCH_VIEW_CONTROL_DATA,
  GET_DEFAULT_USER_SEARCH_VIEW,
  PIN_USER_SEARCH_VIEW,
  SET_USER_SEARCH_VIEW_AS_DEFAULT,
  UNPIN_USER_SEARCH_VIEW,
  UNSET_USER_SEARCH_VIEW_AS_DEFAULT,
  UPDATE_USER_SEARCH_VIEW_DEFINITION,
} from './queries';
import { UserSearchViewDeleteDialog } from '../UserSearchViewDeleteDialog';

const UserSearchViewControl = memo(({ disabled, title }) => {
  const [adminMenu, setAdminMenu] = useState(null);
  const apolloClient = useApolloClient();
  const [activeView, setActiveView] = useState(null);
  const [activeViewChanged, setActiveViewChanged] = useState(false);
  const [activeViewLoading, setActiveViewLoading] = useState(false);
  const controlInitialized = useRef(false);
  const [defaultView, setDefaultView] = useState(null);
  const [defaultViewFetched, setDefaultViewFetched] = useState(false);
  const [defaultViewLoading, setDefaultViewLoading] = useState(false);
  const dispatch = useDispatch();
  const { gridReady, gridRef, indexName, initialColumnDefs, refreshSearch, setRefreshSearch } =
    useEntitySearchApp();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const listenersInitialized = useRef(false);
  const [selectMenu, setSelectMenu] = useState(null);
  const [uiColumnDefs, setUiColumnDefs] = useState([]);
  const uiColumnDefsRef = useRef(uiColumnDefs);
  const { setUiState, uiState } = useInstantSearch();
  const uiStateRef = useRef(uiState);
  const user = useSelector(selectUser);

  useEffect(() => {
    uiColumnDefsRef.current = uiColumnDefs;
  }, [uiColumnDefs]);

  useEffect(() => {
    uiStateRef.current = uiState;
  }, [uiState]);

  const [pinUserSearchView, { loading: pinUserSearchViewLoading }] = useMutation(
    PIN_USER_SEARCH_VIEW,
    {
      onCompleted: (data) => {
        dispatch(
          showMessage({
            message: 'User Search View Successfully Pinned',
            variant: 'success',
          })
        );

        if (data.pinUserSearchView) {
          setActiveView(data.pinUserSearchView);
        }
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Pinning User Search View', variant: 'error' }));
      },
    }
  );

  const [setUserSearchViewAsDefault, { loading: setUserSearchViewAsDefaultLoading }] = useMutation(
    SET_USER_SEARCH_VIEW_AS_DEFAULT,
    {
      onCompleted: (data) => {
        dispatch(
          showMessage({
            message: 'User Search View Successfully Set As Default',
            variant: 'success',
          })
        );

        if (data.setUserSearchViewAsDefault) {
          setActiveView(data.setUserSearchViewAsDefault);
        }
      },
      onError: (error) => {
        dispatch(
          showMessage({ message: 'Failed Setting User Search View As Default', variant: 'error' })
        );
      },
    }
  );

  const [unpinUserSearchView, { loading: unpinUserSearchViewLoading }] = useMutation(
    UNPIN_USER_SEARCH_VIEW,
    {
      onCompleted: (data) => {
        dispatch(
          showMessage({
            message: 'User Search View Successfully Unpinned',
            variant: 'success',
          })
        );

        if (data.unpinUserSearchView) {
          setActiveView(data.unpinUserSearchView);
        }
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Unpinning User Search View', variant: 'error' }));
      },
    }
  );

  const [unsetUserSearchViewAsDefault, { loading: unsetUserSearchViewAsDefaultLoading }] =
    useMutation(UNSET_USER_SEARCH_VIEW_AS_DEFAULT, {
      onCompleted: (data) => {
        dispatch(
          showMessage({
            message: 'User Search View Successfully Unset As Default',
            variant: 'success',
          })
        );

        if (data.unsetUserSearchViewAsDefault) {
          setActiveView(data.unsetUserSearchViewAsDefault);
        }
      },
      onError: (error) => {
        dispatch(
          showMessage({ message: 'Failed Unsetting User Search View As Default', variant: 'error' })
        );
      },
    });

  const [updateUserSearchViewDefinition, { loading: updateUserSearchViewDefinitionLoading }] =
    useMutation(UPDATE_USER_SEARCH_VIEW_DEFINITION, {
      onCompleted: (data) => {
        dispatch(
          showMessage({
            message: 'User Search View Successfully Updated',
            variant: 'success',
          })
        );

        if (data.updateUserSearchViewDefinition) {
          setActiveView(data.updateUserSearchViewDefinition);
        }
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Updating User Search View', variant: 'error' }));
      },
    });

  const canManageActiveView = useMemo(
    () => activeView?.user?.id === user?.id,
    [activeView?.user?.id, user?.id]
  );

  const updatingActiveView = useMemo(
    () =>
      [
        pinUserSearchViewLoading,
        setUserSearchViewAsDefaultLoading,
        unpinUserSearchViewLoading,
        unsetUserSearchViewAsDefaultLoading,
        updateUserSearchViewDefinitionLoading,
      ].includes(true),
    [
      pinUserSearchViewLoading,
      setUserSearchViewAsDefaultLoading,
      unpinUserSearchViewLoading,
      unsetUserSearchViewAsDefaultLoading,
      updateUserSearchViewDefinitionLoading,
    ]
  );

  const extendColumnDefs = useCallback(
    (columnDefs) =>
      columnDefs?.map(({ children, ...columnDef }) => ({
        children: children.map(({ colId, ...rest }) => ({
          ...rest,
          ...(_.first(
            _.filter(
              _.flatten(
                initialColumnDefs.map((initialColumnDef) =>
                  initialColumnDef.children?.find(({ field }) => field === colId)
                )
              ),
              (el) => el
            ) || []
          ) || {}),
          colId,
        })),
        ...columnDef,
      })),
    [initialColumnDefs]
  );

  const handleApplyUserSearchView = useCallback(
    ({ userSearchView }) => {
      if (gridReady && gridRef.current?.api && indexName && initialColumnDefs && setUiState) {
        setUiState((prev) => {
          return {
            ...prev,
            [indexName]: {
              ...(prev[indexName] || {}),
              menu: userSearchView.searchUiState?.[indexName]?.menu,
              query: userSearchView.searchUiState?.[indexName]?.query,
              refinementList: userSearchView.searchUiState?.[indexName]?.refinementList,
              sortBy: userSearchView.searchUiState?.[indexName]?.sortBy,
            },
          };
        });

        setTimeout(() => {
          gridRef.current.api.setGridOption(
            'columnDefs',
            extendColumnDefs(userSearchView.columnDefs)
          );
          gridRef.current.api.applyColumnState({ state: userSearchView.columnState });
        }, 300);

        setActiveView(userSearchView);
      }
    },
    [extendColumnDefs, gridReady, gridRef, indexName, initialColumnDefs, setUiState]
  );

  const handleCloseAdminMenu = () => setAdminMenu(null);

  const handleCloseSelectMenu = () => setSelectMenu(null);

  const handleFetchAndApplyActiveView = useCallback(
    async ({ where }) => {
      try {
        setActiveViewLoading(true);

        if (apolloClient) {
          const { data } = await apolloClient.query({
            fetchPolicy: 'network-only',
            query: FETCH_USER_SEARCH_VIEW_CONTROL_DATA,
            variables: { where },
          });

          if (data.userSearchView) {
            handleApplyUserSearchView({ userSearchView: data.userSearchView });
          }
        }
      } catch (err) {
        dispatch(
          showMessage({
            message: 'Failed Fetching / Applying Active View',
            variant: 'error',
          })
        );
      } finally {
        setActiveViewLoading(false);
      }
    },
    [apolloClient, dispatch, handleApplyUserSearchView]
  );

  const handleFetchDefaultView = useCallback(async () => {
    try {
      setDefaultViewLoading(true);

      if (apolloClient && indexName) {
        const { data } = await apolloClient.query({
          fetchPolicy: 'network-only',
          query: GET_DEFAULT_USER_SEARCH_VIEW,
          variables: { data: { indexName } },
        });

        setDefaultView(data?.getDefaultUserSearchView);
      }
    } catch (err) {
      dispatch(
        showMessage({
          message: 'Failed Fetching Default View',
          variant: 'error',
        })
      );
    } finally {
      setDefaultViewFetched(true);
      setDefaultViewLoading(false);
    }
  }, [apolloClient, dispatch, indexName]);

  const handleOpenAdminMenu = (event) => setAdminMenu(event.currentTarget);

  const handleOpenSelectMenu = (event) => setSelectMenu(event.currentTarget);

  const handleResetView = useCallback(() => {
    if (gridReady && gridRef.current?.api && indexName && initialColumnDefs && setUiState) {
      setUiState((prev) => {
        return {
          ...prev,
          [indexName]: {
            ...(prev[indexName] || {}),
            menu: undefined,
            query: undefined,
            refinementList: undefined,
            sortBy: undefined,
          },
        };
      });

      setTimeout(() => {
        gridRef.current.api.setGridOption('columnDefs', initialColumnDefs);
        gridRef.current.api.applyColumnState({
          defaultState: {
            hide: null,
            sort: null,
          },
        });
      }, 300);

      setActiveView(null);
    }
  }, [gridReady, gridRef, indexName, initialColumnDefs, setUiState]);

  const handleRevertActiveView = useCallback(() => {
    if (activeView && activeViewChanged) {
      handleApplyUserSearchView({ userSearchView: activeView });
    }
  }, [activeView, activeViewChanged, handleApplyUserSearchView]);

  useEffect(() => {
    if (apolloClient && !defaultViewFetched && !defaultViewLoading && indexName) {
      handleFetchDefaultView();
    }
  }, [apolloClient, defaultViewFetched, defaultViewLoading, handleFetchDefaultView, indexName]);

  useEffect(() => {
    if (
      !controlInitialized.current &&
      defaultViewFetched &&
      !defaultViewLoading &&
      gridReady &&
      handleFetchAndApplyActiveView &&
      indexName &&
      !refreshSearch &&
      setRefreshSearch &&
      setUiState
    ) {
      if (defaultView) {
        handleFetchAndApplyActiveView({ where: { id: defaultView.id } });
      } else if (initialColumnDefs && setUiState) {
        // ROADMAP: Handle Multiple Sorted Columns
        const sortColumnDef = _.find(
          _.flatten(initialColumnDefs.map(({ children }) => children)),
          ({ initialSort }) => !!initialSort
        );

        if (sortColumnDef) {
          const sortString = `${indexName}:${sortColumnDef.field}:${sortColumnDef.initialSort}`;

          if (uiStateRef.current?.[indexName]?.sortBy !== sortString) {
            setUiState((prev) => {
              return { ...prev, [indexName]: { ...(prev[indexName] || {}), sortBy: sortString } };
            });
          }
        }
      }

      setRefreshSearch(true);

      controlInitialized.current = true;
    }
  }, [
    defaultView,
    defaultViewFetched,
    defaultViewLoading,
    gridReady,
    handleFetchAndApplyActiveView,
    indexName,
    initialColumnDefs,
    refreshSearch,
    setRefreshSearch,
    setUiState,
  ]);

  useEffect(() => {
    if (controlInitialized.current && gridReady && gridRef.current?.api && initialColumnDefs) {
      gridRef.current.api.setGridOption(
        'columnDefs',
        extendColumnDefs(gridRef.current.api.getColumnDefs())
      );
    }
  }, [extendColumnDefs, gridReady, gridRef, initialColumnDefs]);

  useEffect(() => {
    if (gridReady && gridRef.current?.api && !listenersInitialized.current) {
      gridRef.current.api.addEventListener('columnMoved', (params) => {
        if (!params.column?.isMoving()) {
          setUiColumnDefs(params.api.getColumnDefs());
        }
      });

      gridRef.current.api.addEventListener('columnVisible', (params) => {
        setUiColumnDefs(params.api.getColumnDefs());
      });

      setUiColumnDefs(gridRef.current.api.getColumnDefs());

      listenersInitialized.current = true;
    }
  }, [gridReady, gridRef]);

  useEffect(() => {
    if (gridReady && gridRef.current?.api && listenersInitialized.current) {
      let _changed;

      if (
        !_.every(gridRef.current.api.getColumnDefs(), (columnDef, index) => {
          const normalizedGridColumnDef = {
            groupId: columnDef.groupId,
            children: columnDef.children?.map(({ colId, hide, sort }) => ({ colId, hide, sort })),
          };

          const normalizedActiveViewColumnDef = {
            groupId: activeView?.columnDefs[index].groupId,
            children: activeView?.columnDefs[index].children?.map(({ colId, hide, sort }) => ({
              colId,
              hide,
              sort,
            })),
          };

          return _.isEqual(normalizedGridColumnDef, normalizedActiveViewColumnDef);
        })
      ) {
        _changed = true;
      }

      if (
        !_.every(Object.keys(uiState), (key) => {
          return Boolean(
            _.isEqual(uiState[key]?.menu, activeView?.searchUiState?.[key].menu) &&
              _.isEqual(
                uiState[key]?.refinementList,
                activeView?.searchUiState?.[key].refinementList
              ) &&
              uiState[key]?.sortBy === activeView?.searchUiState?.[key].sortBy &&
              uiState[key]?.query === activeView?.searchUiState?.[key].query
          );
        })
      ) {
        _changed = true;
      }

      setActiveViewChanged(!!_changed);
    }
  }, [
    gridReady,
    gridRef,
    uiColumnDefs,
    uiState,
    activeView?.columnDefs,
    activeView?.searchUiState,
  ]);

  if (!gridReady) return null;

  return (
    <>
      {disabled ? (
        <div className="flex items-center w-full">
          <div className="flex-1">
            <Typography className="text-20 font-500">{title}</Typography>
          </div>

          <Button
            color="secondary"
            disabled={false}
            size="small"
            variant="contained"
            onClick={handleResetView}
          >
            Reset
          </Button>
        </div>
      ) : (
        <div className="flex flex-1 items-center">
          <Button
            fullWidth
            startIcon={
              <FontAwesomeIcon
                icon={selectMenu ? faChevronDown : faChevronRight}
                style={{ width: '18px', height: '18px' }}
              />
            }
            sx={{
              justifyContent: 'left',
              fontSize: '20px',
              fontWeight: '500',
              borderRadius: '4px',
            }}
            onClick={handleOpenSelectMenu}
          >
            <div className="text-left truncate" style={{ width: isMobile ? '190px' : '222px' }}>
              {activeView && activeViewChanged && !activeViewLoading && (
                <FontAwesomeIcon
                  className="mr-8"
                  icon={faDotCircle}
                  style={{ width: '18px', height: '18px' }}
                />
              )}

              {activeView?.name || title}
            </div>
          </Button>

          <EntitySearchMenu
            anchorEl={selectMenu}
            filters={[`indexName='${indexName}'`]}
            indexName="user_search_views"
            ListOption={UserSearchViewHitsListOption}
            listOptionParams={{
              activeView,
              onClick: ({ id }) => {
                handleCloseSelectMenu();
                handleFetchAndApplyActiveView({ where: { id } });
              },
            }}
            nameField="name"
            sortString="user_search_views:isPinned:desc,name:asc"
            onClose={handleCloseSelectMenu}
          />

          <IconButton
            className="ml-4"
            disabled={updatingActiveView}
            size="small"
            onClick={handleOpenAdminMenu}
          >
            <FontAwesomeIcon icon={faEllipsisV} style={{ width: '18px', height: '18px' }} />
          </IconButton>

          <Menu anchorEl={adminMenu} open={Boolean(adminMenu)} onClose={handleCloseAdminMenu}>
            <MenuItem
              disabled={!activeView || !activeViewChanged || !canManageActiveView}
              onClick={() => {
                handleCloseAdminMenu();
                updateUserSearchViewDefinition({
                  variables: {
                    where: { id: activeView.id },
                    data: {
                      columnDefs: gridRef.current.api.getColumnDefs(),
                      columnState: gridRef.current.api.getColumnState(),
                      searchUiState: uiStateRef.current,
                    },
                  },
                });
              }}
            >
              Save
            </MenuItem>

            <MenuItem
              disabled={false}
              onClick={() => {
                handleCloseAdminMenu();
                dispatch(
                  openDialog({
                    children: (
                      <UserSearchViewCreateDialog
                        columnDefs={gridRef.current.api.getColumnDefs()}
                        columnState={gridRef.current.api.getColumnState()}
                        indexName={indexName}
                        searchUiState={uiStateRef.current}
                        onClose={() => dispatch(closeDialog())}
                        onSuccess={({ createUserSearchView }) => {
                          dispatch(closeDialog());

                          if (createUserSearchView?.id) {
                            handleFetchAndApplyActiveView({
                              where: { id: createUserSearchView?.id },
                            });
                          }
                        }}
                      />
                    ),
                    classes: {
                      paper: 'w-full max-w-640 min-w-320 rounded-8',
                    },
                  })
                );
              }}
            >
              Save As...
            </MenuItem>

            <Divider />

            <MenuItem
              disabled={!activeView || !canManageActiveView}
              onClick={() => {
                handleCloseAdminMenu();
                dispatch(
                  openDialog({
                    children: (
                      <UserSearchViewDeleteDialog
                        userSearchViewId={activeView.id}
                        onClose={() => dispatch(closeDialog())}
                        onSuccess={({ deleteUserSearchView }) => {
                          dispatch(closeDialog());

                          if (deleteUserSearchView) {
                            handleResetView();
                          }
                        }}
                      />
                    ),
                    classes: {
                      paper: 'w-full max-w-640 min-w-320 rounded-8',
                    },
                  })
                );
              }}
            >
              Delete...
            </MenuItem>

            <MenuItem
              disabled={!activeView || !activeViewChanged}
              onClick={() => {
                handleCloseAdminMenu();
                handleRevertActiveView();
              }}
            >
              Revert
            </MenuItem>

            <MenuItem
              disabled={false}
              onClick={() => {
                handleCloseAdminMenu();
                handleResetView();
              }}
            >
              Reset
            </MenuItem>

            <Divider />

            {(!activeView || !activeView?.isDefault) && (
              <MenuItem
                disabled={!activeView || activeView?.isDefault || !canManageActiveView}
                onClick={() => {
                  handleCloseAdminMenu();
                  setUserSearchViewAsDefault({ variables: { where: { id: activeView.id } } });
                }}
              >
                Set As Default
              </MenuItem>
            )}

            {activeView && activeView?.isDefault && (
              <MenuItem
                disabled={!activeView || !activeView?.isDefault || !canManageActiveView}
                onClick={() => {
                  handleCloseAdminMenu();
                  unsetUserSearchViewAsDefault({ variables: { where: { id: activeView.id } } });
                }}
              >
                Unset As Default
              </MenuItem>
            )}

            {(!activeView || !activeView?.isPinned) && (
              <MenuItem
                disabled={!activeView || activeView?.isPinned || !canManageActiveView}
                onClick={() => {
                  handleCloseAdminMenu();
                  pinUserSearchView({ variables: { where: { id: activeView.id } } });
                }}
              >
                Pin View
              </MenuItem>
            )}

            {activeView && activeView?.isPinned && (
              <MenuItem
                disabled={!activeView || !activeView?.isPinned || !canManageActiveView}
                onClick={() => {
                  handleCloseAdminMenu();
                  unpinUserSearchView({ variables: { where: { id: activeView.id } } });
                }}
              >
                Unpin View
              </MenuItem>
            )}

            <MenuItem
              disabled={!activeView || !canManageActiveView}
              onClick={() => {
                handleCloseAdminMenu();
                dispatch(
                  openDialog({
                    children: (
                      <UserSearchViewUpdateSettingsDialog
                        userSearchViewId={activeView.id}
                        onClose={() => dispatch(closeDialog())}
                        onSuccess={({ updateUserSearchViewSettings }) => {
                          dispatch(closeDialog());

                          if (updateUserSearchViewSettings) {
                            setActiveView(updateUserSearchViewSettings);
                          }
                        }}
                      />
                    ),
                    classes: {
                      paper: 'w-full max-w-640 min-w-320 rounded-8',
                    },
                  })
                );
              }}
            >
              Settings...
            </MenuItem>
          </Menu>
        </div>
      )}
    </>
  );
});

export default UserSearchViewControl;
