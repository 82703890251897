// eslint-disable-next-line import/prefer-default-export
export const serviceTicketSearchScreenFilterDefs = [
  {
    name: 'Service Ticket',
    refinements: [
      {
        attribute: 'workOrder.issues.budget.name',
        label: 'Budget',
        operator: 'or',
      },
      {
        attribute: 'workOrder.issues.budgetProject.name',
        label: 'Budget Project',
        operator: 'or',
      },
      {
        attribute: 'workOrder.issues.budgetSpecialProject.name',
        label: 'Budget Special Project',
        operator: 'or',
      },
      {
        attribute: 'serviceProvision.organizationConnection.consumer.name',
        label: 'Consumer',
        operator: 'or',
      },
      {
        attribute: 'hasLockedIssues',
        label: 'Has Locked Issues',
        operator: 'or',
      },
      {
        attribute: 'hasOpenRfq',
        label: 'Has Open RFQ',
        operator: 'or',
      },
      {
        attribute: 'hasPausedIssues',
        label: 'Has Paused Issues',
        operator: 'or',
      },
      {
        attribute: 'phase.name',
        label: 'Phase',
        operator: 'or',
      },
      {
        attribute: 'programCampaign.program.name',
        label: 'Program',
        operator: 'or',
      },
      {
        attribute: 'programCampaign.name',
        label: 'Program Campaign',
        operator: 'or',
      },
      {
        attribute: 'isRequestForQuote',
        label: 'RFQ',
        operator: 'or',
      },
      {
        attribute: 'workOrder.issues.serviceIssue.serviceResponseSeverity.name',
        label: 'Severity',
        operator: 'or',
      },
      {
        attribute: 'status.name',
        label: 'Status',
        operator: 'or',
      },
      {
        attribute: 'serviceProvision.organizationConnection.vendor.name',
        label: 'Vendor',
        operator: 'or',
      },
    ],
  },
  {
    name: 'Facility',
    refinements: [
      {
        attribute: 'facility.businessConsultantContact.name',
        label: 'Business Consultant',
        operator: 'or',
      },
      {
        attribute: 'facility.esrContact.name',
        label: 'ESR',
        operator: 'or',
      },
      {
        attribute: 'facility.fieldConsultantContact.name',
        label: 'Field Consultant',
        operator: 'or',
      },
      {
        attribute: 'facility.address.city',
        label: 'City',
        operator: 'or',
      },
      {
        attribute: 'facility.leadAdvisorContact.name',
        label: 'Lead Advisor',
        operator: 'or',
      },
      {
        attribute: 'facility.marketTeam',
        label: 'Market Team',
        operator: 'or',
      },
      {
        attribute: 'facility.name',
        label: 'Name',
        operator: 'or',
      },
      {
        attribute: 'facility.operatorContact.name',
        label: 'Operator',
        operator: 'or',
      },
      {
        attribute: 'facility.projectAdministratorContact.name',
        label: 'Project Administrator',
        operator: 'or',
      },
      {
        attribute: 'facility.regionalCoordinatorContact.name',
        label: 'Regional Coordinator',
        operator: 'or',
      },
      {
        attribute: 'facility.address.region',
        label: 'Region',
        operator: 'or',
      },
      {
        attribute: 'facility.regionalTeam',
        label: 'Regional Team',
        operator: 'or',
      },
    ],
  },
];
