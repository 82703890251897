import { gql } from '@apollo/client';

export const WORK_ORDER_ISSUE_SCHEDULED_RESUMPTION_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment WorkOrderIssueScheduledResumptionUpdateDialogServiceTicketFragment on ServiceTicket {
    id
    hasPausedIssues
    latestCompleteBy
    workOrder {
      id
      issues {
        id
        completeBy
        scheduledResumptions {
          id
          createdAt
          isProcessed
          resumeAt
        }
        status {
          id
          enum
          name
        }
      }
    }
  }
`;

export const WORK_ORDER_ISSUE_SCHEDULED_RESUMPTION_UPDATE_DIALOG_WORK_ORDER_ISSUE_SCHEDULED_RESUMPTION_FRAGMENT = gql`
  fragment WorkOrderIssueScheduledResumptionUpdateDialogWorkOrderIssueScheduledResumptionFragment on WorkOrderIssueScheduledResumption {
    id
    resumeAt
  }
`;
