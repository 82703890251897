import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const WORK_ORDER_ISSUE_SHIPPING_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment WorkOrderIssueShippingUpdateDialogServiceTicketFragment on ServiceTicket {
    id

    workOrder {
      id
      issues {
        id
        actionItems {
          id
          notToExceedAmount
          subtotalAmount
          totalAmount
        }
        notToExceedAmount
        shippingAmount
        subtotalAmount
        taxAmount
        totalAmount
      }
      notToExceedAmount
      shippingAmount
      subtotalAmount
      taxAmount
      totalAmount
    }
  }
`;

export const WORK_ORDER_ISSUE_SHIPPING_UPDATE_DIALOG_WORK_ORDER_ISSUE_FRAGMENT = gql`
  fragment WorkOrderIssueShippingUpdateDialogWorkOrderIssueFragment on WorkOrderIssue {
    id
    shippingAmount
  }
`;
