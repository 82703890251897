import axios from 'axios';

const {
  REACT_APP_HYVE_CLIENT_VERSION,
  REACT_APP_HYVE_SYSTEM_CLIENT,
  REACT_APP_ULTRAVIOLET_INTERCHANGE_BASE_URL,
  REACT_APP_ULTRAVIOLET_INTERCHANGE_CSRF_ENDPOINT,
  REACT_APP_ULTRAVIOLET_INTERCHANGE_USER_NAMESPACE,
} = process.env;

class SocketIoClient {
  constructor({ baseURL, csrfTokenEndpoint, namespace }) {
    this.client = axios.create({
      baseURL,
      headers: {
        common: {
          'X-HYVE-Client-Version': REACT_APP_HYVE_CLIENT_VERSION,
          'X-HYVE-System-Client': REACT_APP_HYVE_SYSTEM_CLIENT,
        },
      },
      withCredentials: true,
    });
    this.csrfTokenEndpoint = csrfTokenEndpoint;
    this.namespace = namespace;

    this.#init();
  }

  #init() {
    if (!this.csrfTokenEndpoint || this.ready) {
      return;
    }

    Promise.all([
      new Promise((resolve, reject) => {
        this.client
          .get(this.csrfTokenEndpoint)
          .then(({ data }) => {
            this.client.defaults.headers.post['X-CSRF-Token'] = data.csrfToken;

            resolve();
          })
          .catch((error) => reject(error));
      }),
    ])
      .then(() => {
        this.ready = true;
      })
      .catch((error) => {
        this.ready = false;
      });
  }

  joinRoom = ({ accessToken, namespace, roomId, socketId }) => {
    return new Promise((resolveAction, rejectAction) => {
      this.client
        .post(
          '/rooms/join',
          {
            namespace,
            roomId,
            socketId,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then(async ({ data }) => {
          // PLACEHOLDER

          resolveAction(data);
        })
        .catch((err) => rejectAction(err));
    });
  };

  leaveRoom = ({ accessToken, namespace, roomId, socketId }) => {
    return new Promise((resolveAction, rejectAction) => {
      this.client
        .post(
          '/rooms/leave',
          {
            namespace,
            roomId,
            socketId,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then(async ({ data }) => {
          // PLACEHOLDER

          resolveAction(data);
        })
        .catch((err) => rejectAction(err));
    });
  };
}

const socketIoClient = new SocketIoClient({
  baseURL: REACT_APP_ULTRAVIOLET_INTERCHANGE_BASE_URL,
  csrfTokenEndpoint: REACT_APP_ULTRAVIOLET_INTERCHANGE_CSRF_ENDPOINT,
  namespace: REACT_APP_ULTRAVIOLET_INTERCHANGE_USER_NAMESPACE,
});

export default socketIoClient;
