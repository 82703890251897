import _ from '@lodash';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

const WorkOrderIssueReCodeDialogTimeoutDialog = ({ open, renameMoment }) => {
  const interval = useRef(null);
  const [time, setTime] = useState(null);

  useEffect(() => {
    if (interval.current) {
      clearInterval(interval.current);
    }

    if (open) {
      interval.current = setInterval(() => {
        const diff = renameMoment.diff(moment());
        const duration = moment.duration(diff, 'milliseconds');

        const count = [duration.hours(), duration.minutes(), duration.seconds()]
          .map((n) => _.padStart(n, 2, '0'))
          .join(':');

        console.log({
          diff,
          duration,
          count,
        });

        setTime(count);
      }, 1000);
    }

    return () => {
      clearInterval(interval.current);
    };
  }, [open, renameMoment]);

  return (
    <Dialog classes={{ paper: 'w-full max-w-640 min-w-320 rounded-8' }} open={!!open}>
      <DialogTitle>Timeout Title</DialogTitle>

      <DialogContent>Warning Message {time}</DialogContent>

      <DialogActions>Actions</DialogActions>
    </Dialog>
  );
};

export default WorkOrderIssueReCodeDialogTimeoutDialog;
