import { useMeilisearch } from 'app/providers/meilisearch';
import { SocketIoRoom } from 'app/providers/socketIo';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch';

const EntitySearchAppContext = React.createContext();

const EntitySearchAppProvider = ({
  availableViewModes,
  children,
  filters,
  hitsPerPage = 40,
  indexName,
  initialColumnDefs,
  initialViewMode,
  makeSearchAdapter,
  makeSearchAdapterParams,
  paginationTotalHits = 2000,
  socketIoIncludeSockets,
  socketIoRoomId,
}) => {
  const [gridReady, setGridReady] = useState(false);
  const gridRef = useRef(null);
  const { instantSearchClient } = useMeilisearch();
  const [refreshSearch, setRefreshSearch] = useState(false);
  const [searchClient, setSearchClient] = useState(null);
  const [viewMode, setViewMode] = useState(initialViewMode);

  useEffect(() => {
    if (instantSearchClient) {
      setSearchClient(
        typeof makeSearchAdapter === 'function'
          ? {
              ...instantSearchClient,
              search: makeSearchAdapter(instantSearchClient, makeSearchAdapterParams),
            }
          : instantSearchClient
      );
    }
  }, [instantSearchClient, makeSearchAdapter, makeSearchAdapterParams]);

  useEffect(() => {
    if (refreshSearch && setRefreshSearch) {
      setRefreshSearch(false);
    }
  }, [refreshSearch, setRefreshSearch]);

  const handleSocketIoMakeSocketListeners = useCallback(
    ({ socket }) => ({
      'document.delete': (value) => {
        if (value.roomId === socketIoRoomId) {
          setRefreshSearch(true);
        }
      },
      'document.upsert': (value) => {
        if (value.roomId === socketIoRoomId) {
          setRefreshSearch(true);
        }
      },
    }),
    [socketIoRoomId]
  );

  if (!searchClient) return null;

  return (
    <>
      <SocketIoRoom
        canConnect={!!gridReady}
        includeSockets={socketIoIncludeSockets}
        roomId={socketIoRoomId}
        makeSocketListeners={handleSocketIoMakeSocketListeners}
      />

      <InstantSearch indexName={indexName} searchClient={searchClient}>
        <Configure
          filters={filters}
          hitsPerPage={hitsPerPage}
          paginationTotalHits={paginationTotalHits}
        />

        <EntitySearchAppContext.Provider
          value={{
            availableViewModes,
            gridReady,
            gridRef,
            hitsPerPage,
            indexName,
            initialColumnDefs,
            refreshSearch,
            setGridReady,
            setRefreshSearch,
            setViewMode,
            viewMode,
          }}
        >
          {children}
        </EntitySearchAppContext.Provider>
      </InstantSearch>
    </>
  );
};

export const __EntitySearchAppContext = EntitySearchAppContext;
export default EntitySearchAppProvider;
