import { faBars, faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from '@lodash';
import { AppBar, Box, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import { useSocketIo } from 'app/providers/socketIo';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const WorkOrderViewHeader = ({ basePath, onToggleLeftSidebar }) => {
  const { connectedSockets } = useSocketIo();
  const dispatch = useDispatch();
  const params = useParams();

  const isConnected = useMemo(
    () =>
      !_.isEmpty(connectedSockets) &&
      _.every(Object.keys(connectedSockets), (socketId) => connectedSockets[socketId]),
    [connectedSockets]
  );

  return (
    <AppBar color="inherit" elevation={0} position="static">
      <Toolbar>
        <div className="flex flex-1 items-center">
          <Tooltip title="Toggle Menu">
            <IconButton
              color="default"
              edge="start"
              sx={{ mr: 2, width: 40, height: 40 }}
              onClick={onToggleLeftSidebar}
            >
              <FontAwesomeIcon icon={faBars} size="xs" />
            </IconButton>
          </Tooltip>

          <Typography component="div" noWrap variant="h6">
            Work Order
          </Typography>
        </div>

        <div>
          <Tooltip title={isConnected ? 'Cloud: Connected' : 'Cloud: Disconnected'}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                height: 40,
                justifyContent: 'center',
                width: 40,
              }}
            >
              <FontAwesomeIcon
                className={clsx({ 'text-green-500': isConnected, 'text-red-500': !isConnected })}
                icon={faRotate}
                size="xl"
              />
            </Box>
          </Tooltip>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default WorkOrderViewHeader;
