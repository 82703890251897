import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const WORK_ORDER_ISSUE_CANCEL_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment WorkOrderIssueCancelDialogServiceTicketFragment on ServiceTicket {
    id
    approvalPhaseEndedAt
    approvalPhaseStartedAt
    draftPhaseEndedAt
    draftPhaseStartedAt
    inProgressPhaseEndedAt
    inProgressPhaseStartedAt
    isApprovalPhaseCompleted
    isDraftPhaseCompleted
    isInProgressPhaseCompleted
    isQuotingPhaseCompleted
    isReleasePhaseCompleted
    isSiteVisitPhaseCompleted
    phase {
      id
      enum
      name
    }
    quotingPhaseEndedAt
    quotingPhaseStartedAt
    releasePhaseEndedAt
    releasePhaseStartedAt
    siteVisitPhaseEndedAt
    siteVisitPhaseStartedAt
    status {
      id
      enum
      name
    }
    workOrder {
      id
      issues {
        id
        actionItems {
          id
          commonStandardFlatRate {
            id
            quantity
          }
          commonStandardHourlyRate {
            id
            hours
            rateAmount
            technicians
          }
          commonStandardRateType {
            id
            enum
            name
          }
          costCode {
            id
            csiClassification {
              id
              name
              number
            }
          }
          description
          isCapitalExpense
          notToExceedAmount
          subtotalAmount
          totalAmount
        }
        notToExceedAmount
        serviceIssue {
          id
          commonTargetType {
            id
            enum
            name
          }
          targetFacilityAreas {
            id
            type {
              id
            }
          }
        }
        shippingAmount
        status {
          id
          enum
          name
        }
        subtotalAmount
        taxAmount
        totalAmount
      }
      notToExceedAmount
      shippingAmount
      subtotalAmount
      taxAmount
      totalAmount
    }
  }
`;
