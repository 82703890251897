import { useLazyQuery } from '@apollo/client';
import { faCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseLoading from '@fuse/core/FuseLoading';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import _ from '@lodash';
import {
  AppBar,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Toolbar,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ServiceInvoiceReviewApprovalDialog } from 'app/shared-components/ServiceInvoiceReview';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { selectUser } from 'app/store/userSlice';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FETCH_SERVICE_INVOICE_VIEW_DRAWER_DATA } from './queries';

const DRAWER_APP_BAR_HEIGHT = 64;

const ServiceInvoiceViewDrawer = ({ basePath, contentWidth, onCloseDrawer }) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const params = useParams();
  const user = useSelector(selectUser);

  const viewportOverflow = useMemo(() => DRAWER_APP_BAR_HEIGHT + (isMobile ? 0 : 64), [isMobile]);

  const [
    fetchServiceInvoiceViewDrawerData,
    {
      data: serviceInvoiceViewDrawerData,
      loading: serviceInvoiceViewDrawerLoading,
      refetch: serviceInvoiceViewDrawerRefetch,
    },
  ] = useLazyQuery(FETCH_SERVICE_INVOICE_VIEW_DRAWER_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const loading = useMemo(
    () => [serviceInvoiceViewDrawerLoading].includes(true),
    [serviceInvoiceViewDrawerLoading]
  );

  const serviceInvoice = useMemo(
    () => serviceInvoiceViewDrawerData?.serviceInvoice,
    [serviceInvoiceViewDrawerData?.serviceInvoice]
  );

  useEffect(() => {
    if (params?.viewId) {
      setFetched(false);
      fetchServiceInvoiceViewDrawerData({ variables: { where: { id: params?.viewId } } });
    }
  }, [fetchServiceInvoiceViewDrawerData, params?.viewId]);

  return (
    <div className="flex-1" style={{ width: contentWidth }}>
      <AppBar color="inherit" elevation={0} position="static">
        <Toolbar>
          <div className="flex flex-1">
            <Typography component="div" noWrap variant="h6">
              Service Invoice Details
            </Typography>
          </div>

          <IconButton sx={{ width: 40, height: 40 }} onClick={onCloseDrawer}>
            <FontAwesomeIcon icon={faTimes} size="xs" />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Paper
        className="flex flex-col p-24 overflow-auto border-t-1"
        elevation={0}
        square
        style={{ height: `calc(100vh - ${viewportOverflow}px)` }}
      >
        {(loading && !fetched) || !serviceInvoice ? (
          <div className="flex w-full h-full justify-center items-center">
            <FuseLoading />
          </div>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid xs={12}>
                <div>
                  <Typography className="text-10">Vendor Reference Number</Typography>
                  <Typography>{serviceInvoice.vendorReferenceNumber}</Typography>
                </div>
              </Grid>

              <Grid xs={12}>
                <div>
                  <Typography className="text-10">Submitted At</Typography>
                  <Typography>
                    {moment(serviceInvoice.submittedAt).format('MMM. DD, YYYY @ hh:mm A')}
                  </Typography>
                </div>
              </Grid>
            </Grid>

            <Divider className="my-16" />

            <Grid container spacing={2}>
              <Grid xs={12}>
                <Typography className="font-500">Work Order Issues</Typography>

                <List>
                  {serviceInvoice.workOrderIssues?.map((workOrderIssue) => (
                    <ListItem key={workOrderIssue.id}>
                      <ListItemIcon sx={{ minWidth: 32 }}>
                        <FontAwesomeIcon icon={faCircle} size="xs" />
                      </ListItemIcon>

                      <ListItemText>{workOrderIssue.serviceIssue?.name}</ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>

            <Divider className="my-16" />

            <Grid container spacing={2}>
              {serviceInvoice.isApproved && (
                <>
                  <Grid xs={6}>
                    <div>
                      <Typography className="text-10">Approved By</Typography>
                      <Typography>
                        {_.get(_.last(serviceInvoice.reviews || []), 'completor.name')}
                      </Typography>
                    </div>
                  </Grid>

                  <Grid xs={6}>
                    <div>
                      <Typography className="text-10">Completed At</Typography>
                      <Typography>
                        {_.get(_.last(serviceInvoice.reviews || []), 'completedAt')}
                      </Typography>
                    </div>
                  </Grid>
                </>
              )}

              <Grid xs={12}>
                <div className="mt-24">
                  {!serviceInvoice.isApproved &&
                    serviceInvoice?.consumer?.id === user?.data?.organization.id && (
                      <Button
                        className="mb-16"
                        fullWidth
                        variant="outlined"
                        onClick={() =>
                          dispatch(
                            openDialog({
                              children: (
                                <ServiceInvoiceReviewApprovalDialog
                                  serviceInvoiceReviewId={_.get(
                                    _.last(serviceInvoice.reviews || []),
                                    'id'
                                  )}
                                  serviceTicketId={serviceInvoice.serviceTicket?.id}
                                  onClose={() => dispatch(closeDialog())}
                                />
                              ),
                              classes: {
                                paper: 'w-full max-w-640 min-w-320 rounded-8',
                              },
                            })
                          )
                        }
                      >
                        Confirm Invoice Approval / Receipt
                      </Button>
                    )}

                  <Button
                    fullWidth
                    disabled={
                      !serviceInvoice?.isGenerated || !serviceInvoice?.commonStoredPdf?.isProcessed
                    }
                    variant="outlined"
                    onClick={() =>
                      window.open(
                        serviceInvoice?.commonStoredPdf?.originalPdfUrl,
                        '_blank',
                        'noreferrer'
                      )
                    }
                  >
                    {serviceInvoice?.isGenerated && serviceInvoice?.commonStoredPdf?.isProcessed
                      ? 'Download PDF'
                      : 'Processing...'}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    </div>
  );
};

export default ServiceInvoiceViewDrawer;
