import FuseLoading from '@fuse/core/FuseLoading';
import FusePageCarded from '@fuse/core/FusePageCarded';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  buildFacilitySearchScreenColumnDefs,
  facilitySearchScreenFilterDefs,
} from 'app/screens/FacilitySearchScreen';
// TODO:
// import { FacilityCreateDialog } from 'app/shared-components/Facility';
import {
  EntitySearchAppProvider,
  EntitySearchDrawer,
  EntitySearchHeader,
  EntitySearchInfiniteHitsGrid,
  EntitySearchToggleRefinement,
  makeEntitySearchCommonClientAdapter,
} from 'app/shared-components/EntitySearch';
// TODO:
// import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { selectUser } from 'app/store/userSlice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import BudgetViewScreenFacilityActiveCellRenderer from './BudgetViewScreenFacilityActiveCellRenderer';
import BudgetViewScreenHeader from './BudgetViewScreenHeader';

const BudgetViewScreenFacilitiesTabRoot = ({ budget }) => {
  const dispatch = useDispatch();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);
  const navigate = useNavigate();
  const params = useParams();
  const user = useSelector(selectUser);

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
  }, [isMobile]);

  const basePath = useMemo(
    () => `${params?.sectionName}/${params?.viewName}`,
    [params?.sectionName, params?.viewName]
  );

  const canCreateFacility = useMemo(
    () =>
      Boolean(
        user?.data?.team?.isOrganizationAdmin === true &&
          ['FRANCHISOR', 'OPERATOR'].includes(user?.data?.organization?.type.enum)
      ),
    [user]
  );

  const leftSidebarWidth = useMemo(() => 341, []);

  const handleLeftSidebarClose = useCallback(() => setLeftSidebarOpen(false), []);

  const handleLeftSidebarToggle = useCallback(
    () => setLeftSidebarOpen(!leftSidebarOpen),
    [leftSidebarOpen]
  );

  return (
    <FusePageCarded
      content={
        <>
          {!budget ? (
            <FuseLoading className="h-full" />
          ) : (
            <div className="flex flex-col w-full">
              <EntitySearchHeader
                dateRangeAttribute="createdAtTimestamp"
                leftSidebarToggle={handleLeftSidebarToggle}
                rightSidebarToggle={null}
                rightToolbarContent={
                  <>
                    <EntitySearchToggleRefinement
                      attribute="budgets.budget.id"
                      label="Hide Inactive"
                      labelPlacement="bottom"
                      on={budget?.id}
                      size="small"
                    />

                    <Button
                      color="success"
                      disabled={!canCreateFacility}
                      size="small"
                      variant="contained"
                      // TODO:
                      // onClick={() =>
                      //   dispatch(
                      //     openDialog({
                      //       children: (
                      //         <FacilityCreateDialog onClose={() => dispatch(closeDialog())} />
                      //       ),
                      //       classes: { paper: 'w-full max-w-640 min-w-320 rounded-8' },
                      //       onClose: () => null,
                      //     })
                      //   )
                      // }
                    >
                      Create
                    </Button>
                  </>
                }
              />

              <SizeMe monitorHeight monitorWidth={false}>
                {({ size }) => (
                  <div
                    style={{
                      // NOTE: NavBar + ScreenHeader + SearchHeader
                      height: `calc(100vh - ${isMobile ? 48 + 256 + 103 : 64 + 160 + 64}px)`,
                    }}
                  >
                    <EntitySearchInfiniteHitsGrid {...size} />
                  </div>
                )}
              </SizeMe>
            </div>
          )}
        </>
      }
      header={
        <>
          {!budget ? (
            <FuseLoading />
          ) : (
            <BudgetViewScreenHeader basePath={basePath} budget={budget} />
          )}
        </>
      }
      leftSidebarContent={
        <>
          {!budget ? (
            <FuseLoading />
          ) : (
            <>
              <EntitySearchDrawer
                contentWidth={leftSidebarWidth - 1}
                dateRangeAttribute="createdAtTimestamp"
                disableUserSearchViewControl
                filterDefs={facilitySearchScreenFilterDefs}
                offsetHeight={isMobile ? 0 : 160}
                title="Facilities"
                toggleDrawer={handleLeftSidebarToggle}
              />
            </>
          )}
        </>
      }
      leftSidebarOnClose={handleLeftSidebarClose}
      leftSidebarOpen={leftSidebarOpen}
      leftSidebarWidth={leftSidebarWidth}
      scroll={isMobile ? 'normal' : 'content'}
    />
  );
};

const BudgetViewScreenFacilitiesTab = (props) => {
  const theme = useTheme();
  const user = useSelector(selectUser);

  const facilitySearchScreenColumnDefs = buildFacilitySearchScreenColumnDefs({
    theme,
    user,
  });

  if (props.budget?.id) {
    facilitySearchScreenColumnDefs[0].children.unshift({
      cellRenderer: BudgetViewScreenFacilityActiveCellRenderer,
      cellRendererParams: {
        budgetId: props.budget.id,
      },
      field: 'budgets',
      headerName: 'Active',
      lockPinned: true,
      lockPosition: 'left',
      lockVisible: true,
      maxWidth: 100,
      minWidth: 100,
      pinned: 'left',
      resizable: false,
      sortable: false,
      width: 100,
    });
  }

  return (
    <EntitySearchAppProvider
      availableViewModes={['grid']}
      indexName="facilities"
      initialColumnDefs={facilitySearchScreenColumnDefs}
      initialViewMode="grid"
      makeSearchAdapter={makeEntitySearchCommonClientAdapter}
      makeSearchAdapterParams={{ dateRangeAttribute: 'createdAtTimestamp' }}
      socketIoIncludeSockets={['organization']}
      socketIoRoomId="aggregate:facilities"
    >
      <BudgetViewScreenFacilitiesTabRoot {...props} />
    </EntitySearchAppProvider>
  );
};

export default BudgetViewScreenFacilitiesTab;
