import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SERVICE_TICKET_EVENT_VIEW_APP_SERVICE_TICKET_FRAGMENT = gql`
  fragment ServiceTicketEventViewAppServiceTicketFragment on ServiceTicket {
    id
    events {
      id
      createdAt

      initiator {
        id
        name
      }
      occurredAt
      sourceServiceInvoice {
        id
        vendorReferenceNumber
      }
      sourceServiceInvoiceReview {
        id
        completor {
          id
          name
        }
        serviceInvoice {
          id
          vendorReferenceNumber
        }
      }
      sourceServiceTicketAttachment {
        id
      }
      sourceServiceTicketChatChannel {
        id
        chatChannel {
          id
          name
        }
      }
      sourceServiceTicketPhaseReview {
        id
        completor {
          id
          name
        }
        serviceTicketPhase {
          id
          enum
          name
        }
      }
      sourceServiceTicketTask {
        id
        completor {
          id
          name
        }
        name
      }
      # ROADMAP: Implement or Remove
      # sourceServiceVisit {
      #   id
      # }
      sourceType {
        id
        enum
        name
      }
      sourceWorkOrder {
        id
      }
      sourceWorkOrderIssue {
        id
        serviceIssue {
          id
          name
        }
      }
      sourceWorkOrderIssueActionItem {
        id
        costCode {
          id
          csiClassification {
            id
            name
            number
          }
        }
      }
      sourceWorkOrderIssueReview {
        id
        completor {
          id
          name
        }
        workOrderIssue {
          id
          serviceIssue {
            id
            name
          }
        }
      }
      type {
        id
        enum
        name
      }
    }
    serviceProvision {
      id
      name
      organizationConnection {
        id
        consumer {
          id
          name
          type {
            id
            enum
            name
          }
        }
        vendor {
          id
          name
          type {
            id
            enum
            name
          }
        }
      }
    }
  }
`;
