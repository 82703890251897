import { faFileImage, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from '@lodash';
import { Alert, Avatar, IconButton, Popover, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const WorkOrderIssueCompleteDialogExistingAttachmentsPopover = ({
  existingAttachments,
  PopoverProps,
  onSelectExistingAttachment,
}) => {
  const mainTheme = useSelector(selectMainTheme);

  const handleSelectExistingAttachment = useCallback(
    (params) => {
      if (typeof onSelectExistingAttachment === 'function') {
        onSelectExistingAttachment(params);
      }

      return false;
    },
    [onSelectExistingAttachment]
  );

  return (
    <Popover {...PopoverProps}>
      <div
        className="flex items-center p-16 h-64"
        style={{
          backgroundColor: mainTheme?.palette.mode === 'dark' ? '#0e131f' : grey[200],
        }}
      >
        <Typography className="text-16 font-500">Attachments</Typography>
      </div>

      <div className="p-16 w-288">
        {!_.isEmpty(existingAttachments) ? (
          <div className="flex flex-wrap items-center">
            {existingAttachments?.map((existingAttachment) => (
              <div key={existingAttachment.id}>
                {existingAttachment.commonStoredType?.enum === 'COMMON_STORED_IMAGE' && (
                  <IconButton onClick={() => handleSelectExistingAttachment(existingAttachment)}>
                    <Avatar
                      src={existingAttachment.commonStoredImage?.thumbnailImageUrl}
                      sx={{ border: '2px solid', width: 42, height: 42 }}
                    >
                      <FontAwesomeIcon icon={faFileImage} />
                    </Avatar>
                  </IconButton>
                )}

                {existingAttachment.commonStoredType?.enum === 'COMMON_STORED_PDF' && (
                  <IconButton onClick={() => handleSelectExistingAttachment(existingAttachment)}>
                    <Avatar
                      src={existingAttachment.commonStoredPdf?.thumbnailImageUrl}
                      sx={{ border: '2px solid', width: 42, height: 42 }}
                    >
                      <FontAwesomeIcon icon={faFilePdf} />
                    </Avatar>
                  </IconButton>
                )}
              </div>
            ))}
          </div>
        ) : (
          <Alert severity="info">No Attachments Found</Alert>
        )}
      </div>
    </Popover>
  );
};

export default WorkOrderIssueCompleteDialogExistingAttachmentsPopover;
