import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const WORK_ORDER_ISSUE_RESPONSE_SEVERITY_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment WorkOrderIssueResponseSeverityUpdateDialogServiceTicketFragment on ServiceTicket {
    id
    latestCompleteBy
    workOrder {
      id
      issues {
        id
        completeBy
        serviceIssue {
          id
          serviceResponseSeverity {
            id
            color
            commonTemporalDurationUnit {
              id
              enum
              name
            }
            name
            ordinal
            value
          }
        }
      }
    }
  }
`;

export const WORK_ORDER_ISSUE_RESPONSE_SEVERITY_UPDATE_DIALOG_WORK_ORDER_ISSUE_FRAGMENT = gql`
  fragment WorkOrderIssueResponseSeverityUpdateDialogWorkOrderIssueFragment on WorkOrderIssue {
    id
    completeBy
    serviceIssue {
      id
      serviceResponseSeverity {
        id
        color
        commonTemporalDurationUnit {
          id
          enum
          name
        }
        name
        ordinal
        value
      }
    }
  }
`;
