import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const WORK_ORDER_ISSUE_REVIEW_CREATE_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment WorkOrderIssueReviewCreateDialogServiceTicketFragment on ServiceTicket {
    id
    phase {
      id
      enum
      name
    }
    workOrder {
      id
      issues {
        id
        actionItems {
          id
          totalAmount
        }
        serviceIssue {
          id
          description
          name
        }
        reviews {
          id
          createdAt
          assignedOrganizationType {
            id
            enum
            name
          }
          isCancelled
          isCompleted
          type {
            id
            enum
            name
          }
        }
        status {
          id
          enum
          name
        }
        subtotalAmountCheck {
          id
          enum
          name
        }
      }
    }
  }
`;
