import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const WORK_ORDER_ISSUE_TAX_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment WorkOrderIssueTaxUpdateDialogServiceTicketFragment on ServiceTicket {
    id

    workOrder {
      id
      issues {
        id
        actionItems {
          id
          notToExceedAmount
          subtotalAmount
          totalAmount
        }
        notToExceedAmount
        shippingAmount
        subtotalAmount
        taxAmount
        totalAmount
      }
      notToExceedAmount
      shippingAmount
      subtotalAmount
      taxAmount
      totalAmount
    }
  }
`;

export const WORK_ORDER_ISSUE_TAX_UPDATE_DIALOG_WORK_ORDER_ISSUE_FRAGMENT = gql`
  fragment WorkOrderIssueTaxUpdateDialogWorkOrderIssueFragment on WorkOrderIssue {
    id
    taxAmount
  }
`;
