import { faCircleXmark, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDeepCompareEffect } from '@fuse/hooks';
import _ from '@lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { grey, lightBlue, red } from '@mui/material/colors';
import { CommonCurrencyField } from 'app/shared-components/Common';
import { CostCodeHitsListOption } from 'app/shared-components/CostCode';
import { EntitySearchSelectFieldV2 } from 'app/shared-components/EntitySearch';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

const ServiceTicketCreateDialogWorkOrderIssueActionItems = ({
  budgetFieldName,
  budgetProjectFieldName,
  budgetSpecialProjectFieldName,
  name,
  totalFieldName,
}) => {
  const [expandedFieldIndex, setExpandedFieldIndex] = useState(null);

  const {
    clearErrors,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const { append, fields, insert, move, prepend, remove, replace, swap, update } = useFieldArray({
    control,
    name,
  });

  const fieldCount = useRef(0);
  const watchBudget = watch(budgetFieldName);
  const watchBudgetProject = watch(budgetProjectFieldName);
  const watchBudgetSpecialProject = watch(budgetSpecialProjectFieldName);
  const watchFields = watch(name);

  const total = watchFields.reduce(
    (acc, { notToExceedAmount }) => acc + (notToExceedAmount || 0),
    0
  );

  useDeepCompareEffect(() => {
    if (fieldCount.current === 0 && fields?.length === 1) {
      clearErrors(name);
    }

    if (fields?.length > fieldCount.current) {
      setExpandedFieldIndex(fields?.length - 1);
    }

    fieldCount.current = fields?.length || 0;
  }, [fields]);

  useEffect(() => {
    if (fieldCount.current > 0 && total > 0 && totalFieldName) {
      setValue(totalFieldName, total, { shouldValidate: true });
    } else if (fieldCount.current === 0 || total === 0) {
      setValue(totalFieldName, '', { shouldValidate: false });
    }
  }, [clearErrors, errors, setValue, total, totalFieldName]);

  const costCodeFilter = useMemo(() => {
    if (!watchBudget?.id || !watchBudgetProject?.id) {
      return null;
    }

    let _costCodeFilter = [`budgetProjects.budgetProject.id = '${watchBudgetProject?.id}'`];

    if (watchBudgetSpecialProject?.id && watchBudgetSpecialProject?._count?.costCodes > 0) {
      _costCodeFilter = [
        ..._costCodeFilter,
        `budgetSpecialProjects.budgetSpecialProject.id = '${watchBudgetSpecialProject?.id}'`,
      ];
    }

    return _costCodeFilter;
  }, [
    watchBudget?.id,
    watchBudgetProject?.id,
    watchBudgetSpecialProject?._count?.costCodes,
    watchBudgetSpecialProject?.id,
  ]);

  const handleChangeExpandedFieldIndex = (index) => (event, isExpanded) => {
    setExpandedFieldIndex(isExpanded ? index : false);
  };

  return (
    <>
      {_.get(errors, name) && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {_.get(errors, [name, 'message']) || 'One or More Action Items Contain Errors'}
        </Alert>
      )}

      {fields.map((item, index) => {
        let borderLeftColor = grey[600];

        if (_.get(errors, name)?.[index]) {
          // eslint-disable-next-line prefer-destructuring
          borderLeftColor = red[800];
        } else if (expandedFieldIndex === index) {
          // eslint-disable-next-line prefer-destructuring
          borderLeftColor = lightBlue[600];
        }

        return (
          <Accordion
            expanded={expandedFieldIndex === index}
            key={item.id}
            variant="outlined"
            onChange={handleChangeExpandedFieldIndex(index)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ borderLeft: `4px solid ${borderLeftColor}` }}
            >
              <div className="flex flex-1 items-center">
                {_.isArray(_.get(errors, name)) &&
                  _.isPlainObject(_.get(errors, name)[index]) &&
                  _.some(Object.keys(_.get(errors, name)[index]), (key) =>
                    _.isPlainObject(_.get(errors, name)[index][key])
                  ) && (
                    <FontAwesomeIcon className="mr-8 text-red-800" icon={faCircleXmark} size="lg" />
                  )}

                <Typography>
                  {watchFields?.[index]?.costCode?.csiClassification
                    ? `${watchFields?.[index]?.costCode?.csiClassification.number} - ${watchFields?.[index]?.costCode?.csiClassification.name}`
                    : `Action Item ${index + 1}`}
                </Typography>
              </div>

              <IconButton
                sx={{ mr: 1, padding: 0, width: '24px', height: '24px' }}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  remove(index);
                }}
              >
                <FontAwesomeIcon icon={faTrashCan} size="xs" />
              </IconButton>
            </AccordionSummary>

            <AccordionDetails sx={{ borderLeft: `4px solid ${borderLeftColor}` }}>
              <Controller
                control={control}
                name={`${name}[${index}].costCode`}
                render={({ field }) => (
                  <EntitySearchSelectFieldV2
                    {...field}
                    className="mb-24"
                    components={{
                      Option: CostCodeHitsListOption,
                    }}
                    error={_.get(errors, `${name}[${index}].costCode`)}
                    filter={costCodeFilter}
                    getOptionLabel={(option) =>
                      `${option?.csiClassification?.number} - ${option?.csiClassification?.name}`
                    }
                    getOptionValue={(option) => option?.id}
                    indexName="cost_codes"
                    isClearable
                    isDisabled={!costCodeFilter}
                    isMulti={false}
                    placeholder="Select Cost Code..."
                    sort={['csiClassification.number:asc']}
                  />
                )}
              />

              <Controller
                control={control}
                name={`${name}[${index}].description`}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-24"
                    error={_.get(errors, `${name}[${index}].description`)}
                    helperText={_.get(errors, `${name}[${index}].description.message`)}
                    variant="outlined"
                    required={false}
                    fullWidth
                    placeholder="Enter Description; Where / What... (Optional)"
                  />
                )}
              />

              <Controller
                control={control}
                name={`${name}[${index}].notToExceedAmount`}
                render={({ field }) => (
                  <CommonCurrencyField
                    {...field}
                    className="mb-24"
                    control={control}
                    disabled={false}
                    error={_.get(errors, `${name}[${index}].notToExceedAmount`)}
                    placeholder="Enter Not To Exceed..."
                  />
                )}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}

      <div className="flex w-full items-center justify-end my-24">
        <Button
          color="inherit"
          disabled={false}
          variant="outlined"
          onClick={() => append({ costCode: null, description: '', notToExceedAmount: null })}
        >
          Add Action Item
        </Button>
      </div>
    </>
  );
};

export default ServiceTicketCreateDialogWorkOrderIssueActionItems;
