import _ from '@lodash';
import { Alert, AlertTitle, Button, Divider } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { CommonCircularProgressWithLabel } from 'app/shared-components/Common/CommonCircularProgress';
import { ServiceTicketCompleteQuotingPhaseDialog } from 'app/shared-components/ServiceTicket/ServiceTicketCompleteQuotingPhaseDialog';
import {
  WorkOrderIssueReviewCompleteDialog,
  WorkOrderIssueReviewCreateDialog,
  WorkOrderIssueReviewRecallDialog,
} from 'app/shared-components/WorkOrderIssueReview';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.mode === 'light' ? grey[400] : grey[800],
}));

const WorkOrderViewAppQuotingPhaseBar = ({
  consumer,
  phase,
  serviceTicketId,
  user,
  vendor,
  workOrder,
}) => {
  const dispatch = useDispatch();

  const openWorkOrderIssueReviewCount = useMemo(
    () =>
      workOrder?.issues?.filter(({ reviews }) =>
        _.some(reviews || [], ({ isCancelled, isCompleted }) =>
          Boolean(!isCancelled && !isCompleted)
        )
      ).length,
    [workOrder?.issues]
  );

  const workOrderIssueApprovedCount = useMemo(
    () =>
      workOrder?.issues?.filter(({ actionItems, reviews, status, subtotalAmountCheck }) =>
        Boolean(
          ['APPROVED'].includes(subtotalAmountCheck?.enum) &&
            ['PENDING', 'SCHEDULED'].includes(status?.enum) &&
            actionItems &&
            !_.some(actionItems || [], ({ totalAmount }) => !totalAmount) &&
            !_.some(reviews || [], ({ isCancelled, isCompleted }) =>
              Boolean(!isCancelled && !isCompleted)
            )
        )
      ).length,
    [workOrder?.issues]
  );

  const workOrderIssueCancelledCount = useMemo(
    () => workOrder?.issues?.filter(({ status }) => ['CANCELLED'].includes(status?.enum)).length,
    [workOrder?.issues]
  );

  const workOrderIssueCompletedPerc = useMemo(
    () =>
      workOrder?.issues?.length
        ? Math.round(
            ((workOrderIssueApprovedCount + workOrderIssueCancelledCount) /
              workOrder?.issues?.length) *
              100
          )
        : 0,
    [workOrder?.issues?.length, workOrderIssueApprovedCount, workOrderIssueCancelledCount]
  );

  const workOrderIssueRequiresReviewCount = useMemo(
    () =>
      workOrder?.issues?.filter(({ actionItems, reviews, status, subtotalAmountCheck }) =>
        Boolean(
          ['MANUAL', 'OVERAGE'].includes(subtotalAmountCheck?.enum) &&
            ['PENDING', 'SCHEDULED'].includes(status?.enum) &&
            actionItems &&
            !_.some(actionItems || [], ({ totalAmount }) => !totalAmount) &&
            !_.some(reviews || [], ({ isCancelled, isCompleted }) =>
              Boolean(!isCancelled && !isCompleted)
            )
        )
      ).length,
    [workOrder?.issues]
  );

  const workOrderIssueRequiresTotalAmountCount = useMemo(
    () =>
      workOrder?.issues?.filter(({ actionItems, status }) =>
        Boolean(
          ['PENDING', 'SCHEDULED'].includes(status?.enum) &&
            (_.isEmpty(actionItems) || _.some(actionItems || [], ({ totalAmount }) => !totalAmount))
        )
      ).length,
    [workOrder?.issues]
  );

  return (
    <>
      {phase.enum === 'QUOTING' && consumer?.id === user?.data?.organization?.id && (
        <>
          <Alert
            square
            action={
              <div className="flex items-center">
                <div className="mr-24">
                  <CommonCircularProgressWithLabel value={workOrderIssueCompletedPerc} />
                </div>
              </div>
            }
            classes={{ action: 'items-center' }}
            severity="info"
          >
            <AlertTitle>Quote Underway</AlertTitle>
            One or more service issue quotes have been started.
          </Alert>

          <StyledDivider />
        </>
      )}

      {phase.enum === 'QUOTING' && vendor?.id === user?.data?.organization?.id && (
        <>
          <Alert
            square
            action={
              <div className="flex items-center">
                <div className="mr-24">
                  <CommonCircularProgressWithLabel value={workOrderIssueCompletedPerc} />
                </div>

                <Button
                  className="mr-8"
                  disabled={workOrderIssueCompletedPerc < 100}
                  size="small"
                  variant="outlined"
                  onClick={() =>
                    dispatch(
                      openDialog({
                        children: (
                          <ServiceTicketCompleteQuotingPhaseDialog
                            serviceTicketId={serviceTicketId}
                            onClose={() => dispatch(closeDialog())}
                          />
                        ),
                        classes: {
                          paper: 'w-full max-w-640 min-w-320 rounded-8',
                        },
                      })
                    )
                  }
                >
                  Complete Quote
                </Button>
              </div>
            }
            classes={{ action: 'items-center' }}
            severity="warning"
          >
            <AlertTitle>Quote Service Issue(s)</AlertTitle>
            Provide a quote for each service issue and complete once done.
          </Alert>

          <StyledDivider />
        </>
      )}

      {!!workOrderIssueRequiresTotalAmountCount && vendor?.id === user?.data?.organization?.id && (
        <>
          <Alert square classes={{ action: 'items-center' }} severity="warning">
            <AlertTitle>Action Items / Total Amounts Required</AlertTitle>
            One or more service issues require action items / total amounts to be entered.
          </Alert>

          <StyledDivider />
        </>
      )}

      {!!workOrderIssueRequiresReviewCount && vendor?.id === user?.data?.organization?.id && (
        <>
          <Alert
            square
            action={
              <Button
                className="mr-8"
                disabled={false}
                size="small"
                variant="outlined"
                onClick={() =>
                  dispatch(
                    openDialog({
                      children: (
                        <WorkOrderIssueReviewCreateDialog
                          defaultWorkOrderIssueId={null}
                          serviceTicketId={serviceTicketId}
                          onClose={() => dispatch(closeDialog())}
                        />
                      ),
                      classes: {
                        paper: 'w-full max-w-640 min-w-320 rounded-8',
                      },
                    })
                  )
                }
              >
                Submit Review
              </Button>
            }
            classes={{ action: 'items-center' }}
            severity="warning"
          >
            <AlertTitle>Submit Service Issue(s) for Review</AlertTitle>
            One or more service issues exceeds its approved amount and requires review.
          </Alert>

          <StyledDivider />
        </>
      )}

      {!!openWorkOrderIssueReviewCount && consumer?.id === user?.data?.organization?.id && (
        <>
          <Alert
            square
            action={
              <Button
                className="mr-8"
                disabled={false}
                size="small"
                variant="outlined"
                onClick={() =>
                  dispatch(
                    openDialog({
                      children: (
                        <WorkOrderIssueReviewCompleteDialog
                          defaultWorkOrderIssueId={null}
                          serviceTicketId={serviceTicketId}
                          onClose={() => dispatch(closeDialog())}
                        />
                      ),
                      classes: {
                        paper: 'w-full max-w-640 min-w-320 rounded-8',
                      },
                    })
                  )
                }
              >
                Complete Review
              </Button>
            }
            classes={{ action: 'items-center' }}
            severity="warning"
          >
            <AlertTitle>Complete Service Issue Review(s)</AlertTitle>
            Review each service issue and provide an outcome decision.
          </Alert>

          <StyledDivider />
        </>
      )}

      {!!openWorkOrderIssueReviewCount && vendor?.id === user?.data?.organization?.id && (
        <>
          <Alert
            square
            action={
              <Button
                className="mr-8"
                disabled={false}
                size="small"
                variant="outlined"
                onClick={() =>
                  dispatch(
                    openDialog({
                      children: (
                        <WorkOrderIssueReviewRecallDialog
                          defaultWorkOrderIssueId={null}
                          serviceTicketId={serviceTicketId}
                          onClose={() => dispatch(closeDialog())}
                        />
                      ),
                      classes: {
                        paper: 'w-full max-w-640 min-w-320 rounded-8',
                      },
                    })
                  )
                }
              >
                Recall Review
              </Button>
            }
            classes={{ action: 'items-center' }}
            severity="info"
          >
            <AlertTitle>Recall Request For Review(s)</AlertTitle>
            Cancel complete service issue review tasks and reopen issues for editing.
          </Alert>

          <StyledDivider />
        </>
      )}
    </>
  );
};

export default WorkOrderViewAppQuotingPhaseBar;
