import { useMutation, useLazyQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { grey } from '@mui/material/colors';
import { CommonDatepicker, CommonSelect } from 'app/shared-components/Common';
import { EntitySearchSelectField } from 'app/shared-components/EntitySearch/EntitySearchSelectField';
import { SiteVisitHitsListOption } from 'app/shared-components/SiteVisit';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import {
  FETCH_WORK_ORDER_ISSUE_RESCHEDULE_SITE_VISIT_DIALOG_DATA,
  RESCHEDULE_SITE_VISIT_WORK_ORDER_ISSUE,
} from './queries';

const defaultValues = {
  addToSiteVisit: null,
  mutation: null,
};

const schema = yup.object().shape({
  addToSiteVisit: yup
    .mixed()
    .when('mutation', {
      is: (val) => val?.value === 'CONNECT',
      then: yup.object().shape({
        connect: yup.object().nullable().required('A site visit must be provided.'),
      }),
    })
    .when('mutation', {
      is: (val) => val?.value === 'CREATE',
      then: yup.object().shape({
        create: yup.object().shape({
          window: yup
            .array()
            .test(
              'requireValidArrivalWindow',
              'An arrival window must be provided.',
              (value) =>
                value?.length === 2 && !_.some(value || [], (date) => !moment(date).isValid())
            ),
        }),
      }),
    }),
  mutation: yup.object().nullable().required('A site visit connection type must be provided.'),
});

const WorkOrderIssueRescheduleSiteVisitDialog = ({
  removeFromSiteVisitId,
  serviceTicketId,
  workOrderIssueId,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [
    fetchWorkOrderIssueRescheduleSiteVisitDialogData,
    {
      data: workOrderIssueRescheduleSiteVisitDialogData,
      loading: workOrderIssueRescheduleSiteVisitDialogLoading,
      refetch: workOrderIssueRescheduleSiteVisitDialogRefetch,
    },
  ] = useLazyQuery(FETCH_WORK_ORDER_ISSUE_RESCHEDULE_SITE_VISIT_DIALOG_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const [rescheduleSiteVisitWorkOrderIssue, { loading: rescheduleSiteVisitWorkOrderIssueLoading }] =
    useMutation(RESCHEDULE_SITE_VISIT_WORK_ORDER_ISSUE, {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Work Order Issue Site Visit Successfully Rescheduled',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(
          showMessage({
            message: 'Failed Rescheduling Work Order Issue Site Visit',
            variant: 'error',
          })
        );
      },
    });

  const loading = useMemo(
    () =>
      [
        rescheduleSiteVisitWorkOrderIssueLoading,
        workOrderIssueRescheduleSiteVisitDialogLoading,
      ].includes(true),
    [rescheduleSiteVisitWorkOrderIssueLoading, workOrderIssueRescheduleSiteVisitDialogLoading]
  );

  const serviceTicket = useMemo(
    () => workOrderIssueRescheduleSiteVisitDialogData?.serviceTicket,
    [workOrderIssueRescheduleSiteVisitDialogData?.serviceTicket]
  );

  useEffect(() => {
    if (serviceTicketId) {
      fetchWorkOrderIssueRescheduleSiteVisitDialogData({
        variables: { where: { id: serviceTicketId } },
      });
    }
  }, [fetchWorkOrderIssueRescheduleSiteVisitDialogData, serviceTicketId]);

  const onSubmit = async (data) => {
    try {
      await rescheduleSiteVisitWorkOrderIssue({
        variables: {
          where: { id: serviceTicketId },
          data: {
            addToSiteVisit: {
              connect: data.addToSiteVisit?.connect && {
                id: data.addToSiteVisit?.connect.value,
              },
              create: data.addToSiteVisit?.create && {
                windowEndAt: _.last(data.addToSiteVisit.create?.window || []),
                windowStartAt: _.first(data.addToSiteVisit.create?.window || []),
              },
            },
            removeFromSiteVisit: { id: removeFromSiteVisitId },
            workOrderIssues: [{ id: workOrderIssueId }],
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Reschedule Work Order Issue Site Visit
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="work-order-issue-reschedule-site-visit-form"
            name="work-order-issue-reschedule-site-visit-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="mutation"
              render={({ field }) => (
                <div className="mb-24">
                  <CommonSelect
                    {...field}
                    error={errors?.mutation}
                    isClearable
                    isDisabled={false}
                    isMulti={false}
                    options={[
                      {
                        label: 'Connect to Existing Site Visit',
                        value: 'CONNECT',
                      },
                      {
                        label: 'Create New Site Visit',
                        value: 'CREATE',
                      },
                    ]}
                    placeholder="Select Site Visit Connection..."
                    onChange={(params) => {
                      setValue('addToSiteVisit', null);

                      field.onChange(params);
                    }}
                  />
                </div>
              )}
            />

            {watchFields?.mutation?.value === 'CONNECT' && (
              <>
                <Controller
                  control={control}
                  name="addToSiteVisit.connect"
                  render={({ field }) => (
                    <EntitySearchSelectField
                      {...field}
                      className="mb-24"
                      components={{ Option: SiteVisitHitsListOption }}
                      error={errors?.addToSiteVisit?.connect}
                      filter={[
                        `serviceTicket.id = '${serviceTicketId}'`,
                        `serviceTicket.status.enum = 'OPEN'`,
                      ]}
                      idField="id"
                      indexName="site_visits"
                      isClearable
                      isDisabled={false}
                      isMulti={false}
                      labelFn={(hit) =>
                        `${moment(hit?.windowStartAt).format('MMM. DD, YYYY @ hh:mm A')} - ${moment(
                          hit?.windowEndAt
                        ).format('MMM. DD, YYYY @ hh:mm A')}`
                      }
                      placeholder="Select Site Visit..."
                      nameField="name"
                      sort={['windowStartAtTimestamp:asc']}
                    />
                  )}
                />
              </>
            )}

            {watchFields?.mutation?.value === 'CREATE' && (
              <>
                <Controller
                  control={control}
                  name="addToSiteVisit.create.window"
                  render={({ field }) => (
                    <div className="mb-24">
                      <CommonDatepicker
                        {...field}
                        controls={['calendar', 'time']}
                        error={errors?.addToSiteVisit?.create?.window}
                        min={moment().add(1, 'days').startOf('day').toDate()}
                        placeholder="Select an Arrival Window..."
                        select="range"
                        stepMinute={15}
                      />
                    </div>
                  )}
                />
              </>
            )}
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="work-order-issue-reschedule-site-visit-form"
          type="submit"
          variant="contained"
        >
          Reschedule
        </Button>
      </DialogActions>
    </>
  );
};

export default WorkOrderIssueRescheduleSiteVisitDialog;
