import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SERVICE_INVOICE_VIEW_DRAWER_SERVICE_INVOICE_FRAGMENT = gql`
  fragment ServiceInvoiceViewDrawerServiceInvoiceFragment on ServiceInvoice {
    id
    createdAt
    updatedAt
    commonStoredPdf {
      id
      commonStoredUpload {
        id
        uploader {
          id
          name
        }
      }
      isProcessed
      lightboxImageUrl
      name
      originalPdfUrl
      standardImageUrl
      thumbnailImageUrl
    }
    consumer {
      id
      name
    }
    isApproved
    isGenerated
    reviews {
      id
      completor {
        id
        name
      }
      completedAt
    }
    serviceTicket {
      id
    }
    submittedAt
    vendor {
      id
      name
    }
    vendorReferenceNumber
    workOrderIssues {
      id
      serviceIssue {
        id
        description
        name
      }
    }
  }
`;
