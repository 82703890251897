import { useMutation, useLazyQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import {
  CONFIRM_CODING_WORK_ORDER_ISSUE,
  FETCH_WORK_ORDER_ISSUE_CONFIRM_CODING_DIALOG_DATA,
} from './queries';

const defaultValues = {
  workOrderIssues: [],
};

const schema = yup.object().shape({
  workOrderIssues: yup
    .array()
    .test(
      'requireOneWorkOrderIssue',
      'One or more work order issues must be provided.',
      (value) => value.filter((el) => el).length
    ),
});

const WorkOrderIssueConfirmCodingDialog = ({
  defaultWorkOrderIssueId,
  serviceTicketId,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);

  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [
    fetchWorkOrderIssueConfirmCodingDialogData,
    {
      data: workOrderIssueConfirmCodingDialogData,
      loading: workOrderIssueConfirmCodingDialogLoading,
      refetch: workOrderIssueConfirmCodingDialogRefetch,
    },
  ] = useLazyQuery(FETCH_WORK_ORDER_ISSUE_CONFIRM_CODING_DIALOG_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const [confirmCodingWorkOrderIssue, { loading: confirmCodingWorkOrderIssueLoading }] =
    useMutation(CONFIRM_CODING_WORK_ORDER_ISSUE, {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Work Order Issue Coding Successfully Confirmed',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(
          showMessage({
            message: 'Failed Confirming Work Order Issue Coding',
            variant: 'error',
          })
        );
      },
    });

  const filteredWorkOrderIssues = useMemo(
    () =>
      workOrderIssueConfirmCodingDialogData?.serviceTicket?.workOrder?.issues
        ?.filter(({ status }) => ['CODING'].includes(status?.enum))
        ?.map((workOrderIssues) => workOrderIssues),
    [workOrderIssueConfirmCodingDialogData?.serviceTicket]
  );

  const loading = useMemo(
    () =>
      [confirmCodingWorkOrderIssueLoading, workOrderIssueConfirmCodingDialogLoading].includes(true),
    [confirmCodingWorkOrderIssueLoading, workOrderIssueConfirmCodingDialogLoading]
  );

  const serviceTicket = useMemo(
    () => workOrderIssueConfirmCodingDialogData?.serviceTicket,
    [workOrderIssueConfirmCodingDialogData?.serviceTicket]
  );

  useEffect(() => {
    if (defaultWorkOrderIssueId) {
      setValue('workOrderIssues', [defaultWorkOrderIssueId], {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  }, [defaultWorkOrderIssueId, setValue]);

  useEffect(() => {
    if (serviceTicketId) {
      fetchWorkOrderIssueConfirmCodingDialogData({
        variables: { where: { id: serviceTicketId } },
      });
    }
  }, [fetchWorkOrderIssueConfirmCodingDialogData, serviceTicketId]);

  const handleToggleAllWorkOrderIssues = () => {
    if (
      filteredWorkOrderIssues?.length === watchFields.workOrderIssues?.length &&
      !_.some(watchFields.workOrderIssues || [], (value) => !value)
    ) {
      setValue('workOrderIssues', [], {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    } else {
      setValue(
        'workOrderIssues',
        filteredWorkOrderIssues?.map(({ id }) => id),
        { shouldDirty: true, shouldTouch: true, shouldValidate: true }
      );
    }
  };

  const onSubmit = async (data) => {
    try {
      await confirmCodingWorkOrderIssue({
        variables: {
          where: { id: serviceTicketId },
          data: {
            workOrderIssues: data.workOrderIssues?.filter((el) => el).map((id) => ({ id })),
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Confirm Work Order Issue Coding
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="work-order-issue-confirm-coding-form"
            name="work-order-issue-confirm-coding-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              control={control}
              name="workOrderIssues"
              render={({ field }) => (
                <>
                  <div className="mb-24">
                    <div
                      className="border-1 overflow-y-auto"
                      style={{
                        maxHeight: 290,
                        borderColor: errors.workOrderIssues ? '#f44336' : grey[300],
                      }}
                    >
                      <List disablePadding>
                        <ListItemButton onClick={handleToggleAllWorkOrderIssues}>
                          <ListItemIcon>
                            <Checkbox
                              checked={
                                filteredWorkOrderIssues?.length ===
                                  watchFields.workOrderIssues?.length &&
                                !_.some(watchFields.workOrderIssues || [], (value) => !value)
                              }
                              edge="start"
                              disableRipple
                              indeterminate={
                                (filteredWorkOrderIssues?.length !==
                                  watchFields.workOrderIssues?.length &&
                                  _.some(watchFields.workOrderIssues || [], (value) => value)) ||
                                (filteredWorkOrderIssues?.length ===
                                  watchFields.workOrderIssues?.length &&
                                  _.some(watchFields.workOrderIssues || [], (value) => value) &&
                                  _.some(watchFields.workOrderIssues || [], (value) => !value))
                              }
                              tabIndex={-1}
                              // value={id}
                              onChange={handleToggleAllWorkOrderIssues}
                            />
                          </ListItemIcon>

                          <ListItemText primary="Select All" />
                        </ListItemButton>

                        {filteredWorkOrderIssues?.map(({ id, serviceIssue }, index) => {
                          const handleChange = () => {
                            const newFieldValue = [...field?.value];

                            newFieldValue[index] = !newFieldValue[index] ? id : null;

                            field.onChange(newFieldValue);
                          };

                          return (
                            <ListItemButton key={id} onClick={handleChange}>
                              <ListItemIcon>
                                <Checkbox
                                  checked={!!field?.value?.[index]}
                                  edge="start"
                                  disableRipple
                                  tabIndex={-1}
                                  value={id}
                                  onChange={handleChange}
                                />
                              </ListItemIcon>

                              <ListItemText primary={serviceIssue.name} />
                            </ListItemButton>
                          );
                        })}
                      </List>
                    </div>

                    {errors.workOrderIssues && (
                      <div className="mx-14 mt-3">
                        <Typography sx={{ color: '#f44336', fontSize: '1.2rem', fontWeight: 400 }}>
                          {errors.workOrderIssues?.message}
                        </Typography>
                      </div>
                    )}
                  </div>
                </>
              )}
            />
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="work-order-issue-confirm-coding-form"
          type="submit"
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </>
  );
};

export default WorkOrderIssueConfirmCodingDialog;
