import { useLazyQuery } from '@apollo/client';
import {
  faCalendarAlt,
  faCheckCircle,
  faCircle as faCircleRegular,
  faCircleLeft,
  faCircleRight,
  faDotCircle,
  faFileAlt,
} from '@fortawesome/free-regular-svg-icons';
import {
  faBarsStaggered,
  faBullhorn,
  faCalendarWeek,
  faCircle as faCircleSolid,
  faDollarSign,
  faFileInvoice,
  faHome,
  faPaperclip,
  faPause,
  faScrewdriverWrench,
  faStream,
  faTasks,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseLoading from '@fuse/core/FuseLoading';
import { useThemeMediaQuery } from '@fuse/hooks';
import _ from '@lodash';
import { AppBar, Avatar, Chip, IconButton, List, Toolbar, Typography } from '@mui/material';
import { amber, blue, green, grey, purple, red } from '@mui/material/colors';
import { styled, useTheme } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {
  ServiceTicketViewDrawerChannelList,
  ServiceTicketViewDrawerListItem,
  ServiceTicketViewDrawerSectionItem,
} from 'app/shared-components/ServiceTicket/ServiceTicketViewDrawer/components';
import { showMessage } from 'app/store/fuse/messageSlice';
import { extractServiceTicketOrganizations } from 'app/utils';
import clsx from 'clsx';
import moment from 'moment';
import { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { FETCH_SERVICE_TICKET_VIEW_DRAWER_DATA } from './queries';

const DRAWER_APP_BAR_HEIGHT = 290;

const DrawerAppBar = styled(AppBar)(({ theme }) => ({
  borderBottomWidth: 1,
  borderColor: theme.palette.mode === 'dark' ? '#353e4f' : grey[400],
  borderStyle: 'solid',
  height: DRAWER_APP_BAR_HEIGHT,
}));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    border: '1px solid #dadde9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    maxWidth: 220,
  },
}));

const ServiceTicketViewDrawer = memo(({ basePath, contentWidth, onToggleDrawer, onViewChange }) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const params = useParams();
  const theme = useTheme();

  const viewportOverflow = useMemo(() => DRAWER_APP_BAR_HEIGHT + (isMobile ? 0 : 64), [isMobile]);

  const [
    fetchServiceTicketViewDrawerData,
    {
      data: serviceTicketViewDrawerData,
      loading: serviceTicketViewDrawerLoading,
      refetch: serviceTicketViewDrawerRefetch,
    },
  ] = useLazyQuery(FETCH_SERVICE_TICKET_VIEW_DRAWER_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const loading = useMemo(
    () => [serviceTicketViewDrawerLoading].includes(true),
    [serviceTicketViewDrawerLoading]
  );

  const serviceTicket = useMemo(
    () => serviceTicketViewDrawerData?.serviceTicket,
    [serviceTicketViewDrawerData?.serviceTicket]
  );

  const serviceTicketOrganizations = useMemo(
    () => serviceTicket && extractServiceTicketOrganizations(serviceTicket),
    [serviceTicket]
  );

  const workOrderIssueResponseSeverities = useMemo(() => {
    if (!serviceTicket?.workOrder.issues) return null;

    const groupedResponseSeverities = _.groupBy(
      serviceTicket?.workOrder.issues,
      'serviceIssue.serviceResponseSeverity.name'
    );

    const _workOrderIssueResponseSeverities = Object.keys(groupedResponseSeverities).map((key) => {
      const serviceResponseSeverity = _.get(
        _.first(groupedResponseSeverities[key] || []),
        'serviceIssue.serviceResponseSeverity'
      );

      return {
        color: serviceResponseSeverity?.color,
        count: groupedResponseSeverities[key]?.length,
        id: serviceResponseSeverity?.id,
        name: serviceResponseSeverity?.name,
        ordinal: serviceResponseSeverity?.ordinal,
      };
    });

    return _workOrderIssueResponseSeverities;
  }, [serviceTicket?.workOrder.issues]);

  const derivedStatus = useMemo(() => {
    let _derivedStatus;

    if (serviceTicket?.status?.enum === 'CANCELLED') {
      _derivedStatus = {
        color: grey[800],
        name: 'Cancelled',
      };
    } else if (serviceTicket?.status?.enum === 'COMPLETE') {
      _derivedStatus = {
        color: green[800],
        name: 'Complete',
      };
    } else if (serviceTicket?.status?.enum === 'OPEN') {
      if (serviceTicket?.phase?.enum === 'DRAFT') {
        _derivedStatus = {
          color: green[800],
          name: 'Draft',
        };
      } else if (moment(moment()).startOf('day').isAfter(serviceTicket?.latestCompleteBy)) {
        _derivedStatus = {
          color: red[800],
          name: 'Overdue',
        };
      } else if (
        moment().isBetween(
          moment(serviceTicket?.latestCompleteBy).subtract(2, 'days').startOf('day'),
          moment(serviceTicket?.latestCompleteBy).startOf('day')
        )
      ) {
        _derivedStatus = {
          color: amber[800],
          name: 'Open',
        };
      } else if (moment(moment()).startOf('day').isBefore(serviceTicket?.latestCompleteBy)) {
        _derivedStatus = {
          color: green[800],
          name: 'Open',
        };
      }
    }

    return _derivedStatus;
  }, [serviceTicket]);

  const lastActivityAtFormatted = useMemo(() => {
    if (!serviceTicket?.lastActivityAt) return null;

    const lastActivityAtMoment = moment(serviceTicket?.lastActivityAt);

    return lastActivityAtMoment.isAfter(moment().startOf('day'))
      ? lastActivityAtMoment.format('hh:mm A')
      : lastActivityAtMoment.format('MMM. DD, YYYY');
  }, [serviceTicket?.lastActivityAt]);

  const nextVisit = useMemo(() => {
    if (_.isEmpty(serviceTicket?.serviceVisits) && _.isEmpty(serviceTicket?.siteVisits)) {
      return null;
    }

    const orderedVisits = _.orderBy(
      [...(serviceTicket?.serviceVisits || []), ...(serviceTicket?.siteVisits || [])],
      ({ windowStartAt }) => moment(windowStartAt).unix(),
      ['asc']
    );

    return (
      _.first(
        _.filter(orderedVisits, ({ windowStartAt }) => moment(windowStartAt).isAfter(moment()))
      ) || _.last(orderedVisits)
    );
  }, [serviceTicket?.serviceVisits, serviceTicket?.siteVisits]);

  useEffect(() => {
    if (params?.serviceTicketId) {
      setFetched(false);
      fetchServiceTicketViewDrawerData({ variables: { where: { id: params?.serviceTicketId } } });
    }
  }, [fetchServiceTicketViewDrawerData, params?.serviceTicketId]);

  const handleViewChange = (_viewSection, _viewSectionId) => {
    navigate(`../${_viewSection}/${_viewSectionId}`);

    if (typeof onViewChange === 'function') {
      // ROADMAP:
      // onViewChange(_viewSection, _viewSectionId);
    }

    return null;
  };

  return (
    <div className="flex-1" style={{ width: contentWidth }}>
      <DrawerAppBar color="inherit" elevation={0} position="static">
        {(loading && !fetched) || !serviceTicket ? (
          <div className="flex w-full h-full justify-center items-center">
            <FuseLoading />
          </div>
        ) : (
          <Toolbar>
            <div className="flex flex-col w-full">
              <div className="flex items-center py-16">
                <div className="flex flex-1 items-start">
                  <IconButton
                    style={{ marginLeft: -8, marginRight: 12 }}
                    onClick={() => navigate('/service-tickets/search')}
                  >
                    <FontAwesomeIcon icon={faCircleLeft} size="sm" />
                  </IconButton>

                  <div className="flex flex-1 flex-col">
                    <Typography component="div" noWrap variant="h6">
                      {`SRN: ${serviceTicket?.serviceReferenceNumber}`}
                    </Typography>

                    <Typography component="div" noWrap variant="body1">
                      {`${serviceTicket?.facility?.franchiseNumber} - ${serviceTicket?.facility?.name}`}
                    </Typography>
                  </div>
                </div>

                {isMobile && onToggleDrawer && (
                  <IconButton className="w-32 h-32" onClick={onToggleDrawer}>
                    <FontAwesomeIcon icon={faTimes} size="sm" />
                  </IconButton>
                )}
              </div>

              <div className="flex flex-row pb-16">
                <div className="flex flex-1 items-center">
                  <div className="flex-1">
                    {derivedStatus && (
                      <Chip
                        className="mr-12 font-600 capitalize"
                        label={derivedStatus.name}
                        sx={{ color: 'white', bgcolor: derivedStatus.color }}
                      />
                    )}
                  </div>

                  {_.map(
                    _.orderBy(workOrderIssueResponseSeverities || [], 'ordinal', 'desc'),
                    (workOrderIssueResponseSeverity) => (
                      <Tooltip
                        key={workOrderIssueResponseSeverity.id}
                        title={workOrderIssueResponseSeverity.name}
                      >
                        <Avatar
                          className="mr-8 text-14 font-600 capitalize"
                          sx={{
                            width: 28,
                            height: 28,
                            color: 'white',
                            bgcolor: workOrderIssueResponseSeverity.color,
                          }}
                        >
                          {workOrderIssueResponseSeverity.count}
                        </Avatar>
                      </Tooltip>
                    )
                  )}

                  {serviceTicket?.isRequestForQuote && (
                    <Tooltip title="Is RFQ">
                      <Avatar
                        className="mr-8 text-14 font-600 capitalize"
                        sx={{
                          width: 28,
                          height: 28,
                          color: 'white',
                          bgcolor: purple[800],
                        }}
                      >
                        <FontAwesomeIcon icon={faDollarSign} />
                      </Avatar>
                    </Tooltip>
                  )}

                  {serviceTicket?.hasPausedIssues && (
                    <Tooltip title="Has Paused Issues">
                      <Avatar
                        className="mr-8 text-14 font-600 capitalize"
                        sx={{
                          width: 28,
                          height: 28,
                          color: 'white',
                          bgcolor: purple[800],
                        }}
                      >
                        <FontAwesomeIcon icon={faPause} />
                      </Avatar>
                    </Tooltip>
                  )}

                  {serviceTicket?.programCampaign && (
                    <Tooltip title={serviceTicket?.programCampaign?.name}>
                      <Avatar
                        className="mr-8 text-14 font-600 capitalize"
                        sx={{
                          width: 28,
                          height: 28,
                          color: 'white',
                          bgcolor: purple[800],
                        }}
                      >
                        <FontAwesomeIcon icon={faBullhorn} />
                      </Avatar>
                    </Tooltip>
                  )}
                </div>
              </div>

              <div className="flex flex-row pb-16">
                <div className="flex flex-1 flex-row items-start">
                  <div className="min-w-32 mt-2">
                    <FontAwesomeIcon icon={faBarsStaggered} size="lg" />
                  </div>

                  <div className="flex flex-1 flex-col">
                    <Typography className="text-11">Last Activity At:</Typography>
                    <Typography className="font-600">
                      {lastActivityAtFormatted ?? 'Unknown'}
                    </Typography>
                  </div>
                </div>

                <div className="flex flex-1 flex-row items-start">
                  <div className="min-w-32 mt-2">
                    <FontAwesomeIcon icon={faScrewdriverWrench} size="lg" />
                  </div>

                  <div className="flex flex-1 flex-col">
                    <Typography className="text-11">Vendor Organization:</Typography>
                    <Typography className="font-600">
                      {serviceTicketOrganizations?.vendor
                        ? _.truncate(serviceTicketOrganizations?.vendor?.name, { length: 14 })
                        : 'Not Set'}
                    </Typography>
                  </div>
                </div>
              </div>

              <div className="flex flex-row pb-16">
                <div className="flex flex-1 flex-row items-start">
                  <div className="min-w-32 mt-2">
                    <FontAwesomeIcon
                      className={clsx({
                        'text-amber-800':
                          serviceTicket?.status.enum === 'OPEN' &&
                          moment(moment()).startOf('day').isSame(serviceTicket?.latestCompleteBy),
                        'text-green-800':
                          serviceTicket?.status.enum === 'COMPLETE' ||
                          moment(moment()).startOf('day').isBefore(serviceTicket?.latestCompleteBy),
                        'text-red-800':
                          serviceTicket?.status.enum === 'OPEN' &&
                          moment(moment()).startOf('day').isAfter(serviceTicket?.latestCompleteBy),
                      })}
                      icon={faCircleSolid}
                      size="lg"
                    />
                  </div>

                  <div className="flex flex-1 flex-col">
                    <Typography className="text-11">Latest Complete By:</Typography>
                    <Typography className="font-600">
                      {moment(serviceTicket?.latestCompleteBy).format('MMM. DD, YYYY')}
                    </Typography>
                  </div>
                </div>

                <div className="flex flex-1 flex-row items-start">
                  <div className="min-w-32 mt-2">
                    <FontAwesomeIcon
                      className={clsx({
                        'text-green-800':
                          nextVisit &&
                          moment(moment(nextVisit?.windowStartAt))
                            .startOf('day')
                            .isBefore(serviceTicket?.latestCompleteBy),
                        'text-grey-800': !nextVisit,
                        'text-red-800':
                          nextVisit &&
                          moment(moment(nextVisit?.windowStartAt))
                            .startOf('day')
                            .isAfter(serviceTicket?.latestCompleteBy),
                      })}
                      icon={faCircleSolid}
                      size="lg"
                    />
                  </div>

                  <div className="flex flex-1 flex-col">
                    <Typography className="text-11">Next Visit:</Typography>
                    <Typography className="font-600">
                      {nextVisit
                        ? moment(nextVisit?.windowStartAt).format('MMM. DD, YYYY')
                        : 'Not Scheduled'}
                    </Typography>
                  </div>
                </div>
              </div>

              <div className="flex flex-row pt-8 pb-16">
                {[
                  {
                    id: 'DRAFT',
                    endedAt: serviceTicket?.draftPhaseEndedAt,
                    isCompleted: serviceTicket?.isDraftPhaseCompleted,
                    name: 'Draft Phase',
                    startedAt: serviceTicket?.draftPhaseStartedAt,
                  },
                  {
                    id: 'APPROVAL',
                    endedAt: serviceTicket?.approvalPhaseEndedAt,
                    isCompleted: serviceTicket?.isApprovalPhaseCompleted,
                    name: 'Approval Phase',
                    startedAt: serviceTicket?.approvalPhaseStartedAt,
                  },
                  {
                    id: 'SITE_VISIT',
                    endedAt: serviceTicket?.siteVisitPhaseEndedAt,
                    isCompleted: serviceTicket?.isSiteVisitPhaseCompleted,
                    name: 'Site Visit Phase',
                    startedAt: serviceTicket?.siteVisitPhaseStartedAt,
                  },
                  {
                    id: 'QUOTING',
                    endedAt: serviceTicket?.quotingPhaseEndedAt,
                    isCompleted: serviceTicket?.isQuotingPhaseCompleted,
                    name: 'Quoting Phase',
                    startedAt: serviceTicket?.quotingPhaseStartedAt,
                  },
                  {
                    id: 'RELEASE',
                    endedAt: serviceTicket?.releasePhaseEndedAt,
                    isCompleted: serviceTicket?.isReleasePhaseCompleted,
                    name: 'Release Phase',
                    startedAt: serviceTicket?.releasePhaseStartedAt,
                  },
                  {
                    id: 'IN_PROGRESS',
                    endedAt: serviceTicket?.inProgressPhaseEndedAt,
                    isCompleted: serviceTicket?.isInProgressPhaseCompleted,
                    name: 'In Progress Phase',
                    startedAt: serviceTicket?.inProgressPhaseStartedAt,
                  },
                ].map(({ id, endedAt, isCompleted, name, startedAt }, index, phaseArray) => (
                  <div
                    className={clsx('flex items-center', {
                      'flex-1': index + 1 < phaseArray.length,
                    })}
                    key={id}
                  >
                    <HtmlTooltip
                      title={
                        <>
                          <Typography className="mb-12 text-15 font-500" color="inherit">
                            {name}
                          </Typography>

                          <Typography className="text-10 uppercase" color="inherit">
                            Started At
                          </Typography>

                          <Typography className="flex items-center mb-6 text-14" color="inherit">
                            <FontAwesomeIcon
                              className={clsx('mr-8', {
                                'text-green-800': startedAt,
                                'text-grey-800': !startedAt,
                              })}
                              icon={faCircleSolid}
                              size="sm"
                            />

                            {startedAt && moment(startedAt).isValid()
                              ? moment(startedAt).format('MMM. DD, YYYY @ hh:mm A')
                              : 'Not Started'}
                          </Typography>

                          <Typography className="text-10 uppercase" color="inherit">
                            Ended At
                          </Typography>

                          <Typography className="flex items-center mb-6 text-14" color="inherit">
                            <FontAwesomeIcon
                              className={clsx('mr-8', {
                                'text-green-800': endedAt,
                                'text-grey-800': !endedAt,
                              })}
                              icon={faCircleSolid}
                              size="sm"
                            />

                            {endedAt && moment(endedAt).isValid()
                              ? moment(endedAt).format('MMM. DD, YYYY @ hh:mm A')
                              : 'Not Ended'}
                          </Typography>
                        </>
                      }
                    >
                      <div className="flex items-center">
                        {id === serviceTicket?.phase?.enum && (
                          <FontAwesomeIcon
                            icon={faDotCircle}
                            size="xl"
                            style={{ color: blue[600] }}
                          />
                        )}

                        {isCompleted && (
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            size="xl"
                            style={{ color: green[800] }}
                          />
                        )}

                        {id !== serviceTicket?.phase?.enum && !isCompleted && (
                          <>
                            {!isCompleted &&
                            _.some(
                              phaseArray.slice(index) || [],
                              (nextPhase) => nextPhase?.startedAt
                            ) ? (
                              <FontAwesomeIcon
                                icon={faCircleRight}
                                size="xl"
                                style={{
                                  color: green[800],
                                }}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faCircleRegular}
                                size="xl"
                                style={{
                                  color: theme.palette.mode === 'light' ? grey[600] : grey[200],
                                }}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </HtmlTooltip>

                    {index + 1 < phaseArray.length && (
                      <div className="flex flex-1 items-center px-8">
                        <div
                          style={{
                            width: '100%',
                            height: 2,
                            backgroundColor: theme.palette.mode === 'light' ? grey[400] : grey[100],
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Toolbar>
        )}
      </DrawerAppBar>

      <div
        className="flex flex-col overflow-auto"
        style={{ height: `calc(100vh - ${viewportOverflow}px)` }}
      >
        {(loading && !fetched) || !serviceTicket ? (
          <div className="flex w-full h-full justify-center items-center">
            <FuseLoading />
          </div>
        ) : (
          <List disablePadding>
            <ServiceTicketViewDrawerSectionItem disabled={false} isInitiallyOpen title="Activity">
              <List disablePadding>
                {[
                  {
                    count: 0,
                    disabled: false,
                    icon: faHome,
                    name: 'overview',
                    title: 'Overview',
                  },
                  {
                    // ROADMAP: Implement
                    count: 0,
                    disabled: false,
                    icon: faTasks,
                    name: 'tasks',
                    title: 'Tasks',
                  },
                  {
                    // ROADMAP: Implement
                    count: 0,
                    disabled: false,
                    icon: faCalendarAlt,
                    name: 'reminders',
                    title: 'Reminders',
                  },
                  {
                    count: 0,
                    disabled: false,
                    icon: faFileAlt,
                    name: 'work-order',
                    title: 'Work Order',
                  },
                  {
                    count: 0,
                    disabled: false,
                    icon: faFileInvoice,
                    name: 'service-invoices',
                    title: 'Service Invoices',
                  },
                  {
                    count: 0,
                    disabled: false,
                    icon: faCalendarWeek,
                    name: 'calendar',
                    title: 'Calendar',
                  },
                  {
                    count: 0,
                    disabled: false,
                    icon: faStream,
                    name: 'timeline',
                    title: 'Timeline',
                  },
                  {
                    count: 0,
                    disabled: false,
                    icon: faPaperclip,
                    name: 'file-attachments',
                    title: 'File Attachments',
                  },
                ].map(({ count, disabled, icon, name, title }) => (
                  <ServiceTicketViewDrawerListItem
                    count={count}
                    disabled={disabled}
                    icon={icon}
                    key={`activity-${name}`}
                    selected={params?.sectionName === 'activity' && params?.viewName === name}
                    title={title}
                    onViewChange={() => handleViewChange('activity', name)}
                  />
                ))}
              </List>
            </ServiceTicketViewDrawerSectionItem>

            <ServiceTicketViewDrawerSectionItem disabled={false} isInitiallyOpen title="Channels">
              <ServiceTicketViewDrawerChannelList
                serviceTicket={serviceTicket}
                onCreateGeneralChatChannel={() => serviceTicketViewDrawerRefetch()}
              />
            </ServiceTicketViewDrawerSectionItem>
          </List>
        )}
      </div>
    </div>
  );
});

export default ServiceTicketViewDrawer;
