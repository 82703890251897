import { gql } from '@apollo/client';
import { WORK_ORDER_ISSUE_CONFIRM_CODING_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

export const CONFIRM_CODING_WORK_ORDER_ISSUE = gql`
  mutation ConfirmCodingWorkOrderIssue(
    $where: ServiceTicketWhereUniqueInput!
    $data: WorkOrderIssueConfirmCodingInput!
  ) {
    confirmCodingWorkOrderIssue(where: $where, data: $data) {
      id
      ...WorkOrderIssueConfirmCodingDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_CONFIRM_CODING_DIALOG_SERVICE_TICKET_FRAGMENT}
`;

export const FETCH_WORK_ORDER_ISSUE_CONFIRM_CODING_DIALOG_DATA = gql`
  query FetchWorkOrderIssueConfirmCodingDialogData($where: ServiceTicketWhereUniqueInput!) {
    serviceTicket(where: $where) {
      id
      ...WorkOrderIssueConfirmCodingDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_CONFIRM_CODING_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
