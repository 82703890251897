import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// TODO:
// import i18next from 'i18next';

// import en from './i18n/en';
// import tr from './i18n/tr';
// import ar from './i18n/ar';

const WorkOrderIssuesSearchPage = lazy(() => import('./WorkOrderIssuesSearchPage'));
const workOrderIssuesSearchPageBase = '/work-order-issues';

// i18next.addResourceBundle('en', 'workOrderIssuesSearchPage', en);
// i18next.addResourceBundle('tr', 'workOrderIssuesSearchPage', tr);
// i18next.addResourceBundle('ar', 'workOrderIssuesSearchPage', ar);

const workOrderIssuesSearchPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: `${workOrderIssuesSearchPageBase}/search`,
      element: <WorkOrderIssuesSearchPage />,
    },
    {
      path: workOrderIssuesSearchPageBase,
      element: <Navigate to={`${workOrderIssuesSearchPageBase}/search`} />,
    },
  ],
};

export default workOrderIssuesSearchPageConfig;
