import {
  faAnglesRight,
  faEllipsis,
  faExclamationCircle,
  faPaperclip,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from '@lodash';
import {
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import {
  WorkOrderIssueActionItemDeleteDialog,
  WorkOrderIssueActionItemUpdateDialog,
} from 'app/shared-components/WorkOrderIssueActionItem';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { capitalCase } from 'change-case';
import numeral from 'numeral';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SizeMe } from 'react-sizeme';
import { WorkOrderViewAppAttachmentsPopover } from '.';

const WorkOrderIssueActionItemTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? 'white' : '#1b2535',
  borderColor: theme.palette.mode === 'light' ? grey[400] : grey[800],
}));

const WorkOrderViewAppWorkOrderIssueActionItemTable = ({
  // TODO:
  canUpdateWorkOrderIssue,
  handleOpenImageLightbox,
  handleOpenPdfWindow,
  handleOpenVideoJsPlayer,
  openWorkOrderIssueReview,
  phase,
  serviceTicketId,
  workOrderIssue,
}) => {
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState(null);
  const [actionMenuSelected, setActionMenuSelected] = useState(null);
  const [costCodeAttachmentsMenuAnchorEl, setCostCodeAttachmentsMenuAnchorEl] = useState(null);
  const [costCodeAttachmentsMenuSelected, setCostCodeAttachmentsMenuSelected] = useState(null);
  const dispatch = useDispatch();

  const workOrderIssueActionItems = useMemo(
    () => workOrderIssue?.actionItems,
    [workOrderIssue?.actionItems]
  );

  const handleCloseActionMenu = () => {
    setActionMenuAnchorEl(null);
    setActionMenuSelected(null);
  };

  const handleCloseCostCodeAttachmentsMenu = () => {
    setCostCodeAttachmentsMenuAnchorEl(null);
    setCostCodeAttachmentsMenuSelected(null);
  };

  const handleOpenActionMenu = ({ currentTarget, selected }) => {
    setActionMenuAnchorEl(currentTarget);
    setActionMenuSelected(selected);
  };

  const handleOpenCostCodeAttachmentsMenu = ({ currentTarget, selected }) => {
    setCostCodeAttachmentsMenuAnchorEl(currentTarget);
    setCostCodeAttachmentsMenuSelected(selected);
  };

  return (
    <SizeMe monitorHeight={false} monitorWidth>
      {({ size }) => (
        <TableContainer component={Paper} elevation={0} square>
          <Table>
            <TableBody>
              {!_.isEmpty(workOrderIssueActionItems) ? (
                <>
                  {_.map(
                    _.sortBy(workOrderIssueActionItems || [], 'costCode.csiClassification.name'),
                    (workOrderIssueActionItem) => {
                      let quantity;
                      let rateAmount;

                      if (workOrderIssueActionItem.commonStandardRateType?.enum === 'FLAT_RATE') {
                        rateAmount = workOrderIssueActionItem.commonStandardFlatRate?.quantity;
                      } else if (
                        workOrderIssueActionItem.commonStandardRateType?.enum === 'HOURLY_RATE'
                      ) {
                        quantity =
                          workOrderIssueActionItem.commonStandardHourlyRate?.hours *
                          workOrderIssueActionItem.commonStandardHourlyRate?.technicians;

                        rateAmount = workOrderIssueActionItem.commonStandardHourlyRate?.rateAmount;
                      }

                      return (
                        <TableRow key={workOrderIssueActionItem?.id}>
                          <WorkOrderIssueActionItemTableCell sx={{ width: '34%' }}>
                            <div className="flex flex-1 items-start">
                              {canUpdateWorkOrderIssue &&
                              !workOrderIssueActionItem.totalAmount &&
                              ['IN_PROGRESS', 'QUOTING'].includes(phase.enum) &&
                              ['IN_PROGRESS', 'OPEN', 'PENDING', 'QUOTED', 'SCHEDULED'].includes(
                                workOrderIssue?.status?.enum
                              ) ? (
                                <Tooltip title="Action Item Missing Details">
                                  <IconButton
                                    className="mr-12 sm:hidden md:flex"
                                    onClick={() => {
                                      handleCloseActionMenu();

                                      dispatch(
                                        openDialog({
                                          children: (
                                            <WorkOrderIssueActionItemUpdateDialog
                                              serviceTicketId={serviceTicketId}
                                              workOrderIssueActionItemId={
                                                workOrderIssueActionItem.id
                                              }
                                              onClose={() => dispatch(closeDialog())}
                                            />
                                          ),
                                          classes: {
                                            paper: 'w-full max-w-640 min-w-320 rounded-8',
                                          },
                                        })
                                      );
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      className="text-amber-800"
                                      icon={faExclamationCircle}
                                    />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <FontAwesomeIcon
                                  className="w-20 h-20 mr-12 p-6 text-grey-700 sm:hidden md:block"
                                  icon={faAnglesRight}
                                />
                              )}

                              <div className="flex flex-col">
                                {workOrderIssueActionItem.costCode?.accountingTaskCode && (
                                  <Typography className="text-11">
                                    {`${workOrderIssueActionItem.costCode?.accountingTaskCode.name} (${workOrderIssueActionItem.costCode?.accountingTaskCode.number})`}
                                  </Typography>
                                )}

                                <div
                                  className="flex items-center h-32"
                                  style={{
                                    width: Math.floor((size?.width || 0) * 0.34 - (32 + (32 + 12))),
                                  }}
                                >
                                  <Typography className="text-14" noWrap>
                                    {workOrderIssueActionItem.costCode?.csiClassification
                                      ? `${workOrderIssueActionItem.costCode?.csiClassification?.name} (${workOrderIssueActionItem.costCode?.csiClassification?.number})`
                                      : 'Cost Code Not Set'}
                                  </Typography>

                                  {!_.isEmpty(workOrderIssueActionItem.costCode?.attachments) && (
                                    <>
                                      <Tooltip title="Attachments">
                                        <IconButton
                                          className="ml-12 w-32 h-32"
                                          onClick={({ currentTarget }) =>
                                            handleOpenCostCodeAttachmentsMenu({
                                              currentTarget,
                                              selected: workOrderIssueActionItem.costCode?.id,
                                            })
                                          }
                                        >
                                          <FontAwesomeIcon icon={faPaperclip} size="sm" />
                                        </IconButton>
                                      </Tooltip>

                                      <WorkOrderViewAppAttachmentsPopover
                                        actionRenderer={null}
                                        attachments={workOrderIssueActionItem.costCode?.attachments}
                                        badgeContentRenderer={null}
                                        PopoverProps={{
                                          anchorEl: costCodeAttachmentsMenuAnchorEl,
                                          anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                          },
                                          open: Boolean(
                                            costCodeAttachmentsMenuAnchorEl &&
                                              costCodeAttachmentsMenuSelected ===
                                                workOrderIssueActionItem.costCode?.id
                                          ),
                                          transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'center',
                                          },
                                          onClose: handleCloseCostCodeAttachmentsMenu,
                                        }}
                                        onOpenImageLightbox={handleOpenImageLightbox}
                                        onOpenPdfWindow={handleOpenPdfWindow}
                                        onOpenVideoJsPlayer={handleOpenVideoJsPlayer}
                                      />
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>

                            {workOrderIssueActionItem.description && (
                              <div className="flex flex-1 md:pl-40 mt-16">
                                <Typography className="text-14">
                                  {workOrderIssueActionItem.description}
                                </Typography>
                              </div>
                            )}

                            {/* TODO: */}
                            {workOrderIssueActionItem.codingStrings?.map(
                              (workOrderIssueActionItemCodingString) => (
                                <div
                                  key={workOrderIssueActionItemCodingString.typeEnum}
                                  className="flex flex-1 flex-col md:pl-40 mt-16"
                                >
                                  <Typography className="mb-4 text-11">
                                    {capitalCase(workOrderIssueActionItemCodingString.typeEnum)}
                                  </Typography>

                                  <Typography className="text-14">
                                    {workOrderIssueActionItemCodingString.value}
                                  </Typography>
                                </div>
                              )
                            )}
                          </WorkOrderIssueActionItemTableCell>

                          <WorkOrderIssueActionItemTableCell sx={{ width: '15%' }} align="right">
                            {quantity || '-'}
                          </WorkOrderIssueActionItemTableCell>

                          <WorkOrderIssueActionItemTableCell sx={{ width: '15%' }} align="right">
                            {quantity && `${quantity} @ `}
                            {rateAmount ? numeral(rateAmount).format('$0,0.00') : '-'}
                          </WorkOrderIssueActionItemTableCell>

                          <WorkOrderIssueActionItemTableCell sx={{ width: '15%' }} align="right">
                            {workOrderIssueActionItem.totalAmount
                              ? numeral(workOrderIssueActionItem.totalAmount).format('$0,0.00')
                              : '-'}
                          </WorkOrderIssueActionItemTableCell>

                          <WorkOrderIssueActionItemTableCell sx={{ width: '15%' }} align="right">
                            {workOrderIssueActionItem.notToExceedAmount
                              ? numeral(workOrderIssueActionItem.notToExceedAmount).format(
                                  '$0,0.00'
                                )
                              : '-'}
                          </WorkOrderIssueActionItemTableCell>

                          <WorkOrderIssueActionItemTableCell
                            sx={{ width: '6%', paddingLeft: 0 }}
                            align="right"
                          >
                            {canUpdateWorkOrderIssue &&
                              !openWorkOrderIssueReview &&
                              ['DRAFT', 'IN_PROGRESS', 'QUOTING'].includes(phase.enum) &&
                              ['IN_PROGRESS', 'OPEN', 'PENDING', 'QUOTED', 'SCHEDULED'].includes(
                                workOrderIssue?.status?.enum
                              ) && (
                                <>
                                  <IconButton
                                    className="w-32 h-32"
                                    onClick={({ currentTarget }) =>
                                      handleOpenActionMenu({
                                        currentTarget,
                                        selected: workOrderIssueActionItem.id,
                                      })
                                    }
                                  >
                                    <FontAwesomeIcon icon={faEllipsis} size="sm" />
                                  </IconButton>

                                  <Menu
                                    anchorEl={actionMenuAnchorEl}
                                    id="action-menu"
                                    open={Boolean(
                                      actionMenuAnchorEl &&
                                        actionMenuSelected === workOrderIssueActionItem.id
                                    )}
                                    onClose={handleCloseActionMenu}
                                  >
                                    <MenuItem
                                      disabled={!canUpdateWorkOrderIssue}
                                      onClick={() => {
                                        handleCloseActionMenu();

                                        dispatch(
                                          openDialog({
                                            children: (
                                              <WorkOrderIssueActionItemUpdateDialog
                                                serviceTicketId={serviceTicketId}
                                                workOrderIssueActionItemId={
                                                  workOrderIssueActionItem.id
                                                }
                                                onClose={() => dispatch(closeDialog())}
                                              />
                                            ),
                                            classes: {
                                              paper: 'w-full max-w-640 min-w-320 rounded-8',
                                            },
                                          })
                                        );
                                      }}
                                    >
                                      Edit Action Item
                                    </MenuItem>

                                    <MenuItem
                                      disabled={!canUpdateWorkOrderIssue}
                                      onClick={() => {
                                        handleCloseActionMenu();

                                        dispatch(
                                          openDialog({
                                            children: (
                                              <WorkOrderIssueActionItemDeleteDialog
                                                serviceTicketId={serviceTicketId}
                                                workOrderIssueActionItemId={
                                                  workOrderIssueActionItem.id
                                                }
                                                onClose={() => dispatch(closeDialog())}
                                              />
                                            ),
                                            classes: {
                                              paper: 'w-full max-w-640 min-w-320 rounded-8',
                                            },
                                          })
                                        );
                                      }}
                                    >
                                      Delete Action Item
                                    </MenuItem>
                                  </Menu>
                                </>
                              )}
                          </WorkOrderIssueActionItemTableCell>
                        </TableRow>
                      );
                    }
                  )}
                </>
              ) : (
                <TableRow>
                  <WorkOrderIssueActionItemTableCell align="center">
                    <Typography className="font-500">No Action Items Found</Typography>
                  </WorkOrderIssueActionItemTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </SizeMe>
  );
};

export default WorkOrderViewAppWorkOrderIssueActionItemTable;
