import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const WORK_ORDER_ISSUE_CONFIRM_CODING_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment WorkOrderIssueConfirmCodingDialogServiceTicketFragment on ServiceTicket {
    id
    phase {
      id
      enum
      name
    }
    workOrder {
      id
      issues {
        id
        serviceIssue {
          id
          description
          name
        }
        status {
          id
          enum
          name
        }
      }
    }
  }
`;
