import { gql } from '@apollo/client';

export const SERVICE_INVOICE_REVIEW_APPROVAL_DIALOG_SERVICE_INVOICE_FRAGMENT = gql`
  fragment ServiceInvoiceReviewApprovalDialogServiceInvoiceFragment on ServiceInvoice {
    id
    createdAt
    updatedAt
    commonStoredPdf {
      id
      commonStoredUpload {
        id
        uploader {
          id
          name
        }
      }
      isProcessed
      lightboxImageUrl
      name
      originalPdfUrl
      standardImageUrl
      thumbnailImageUrl
    }
    consumer {
      id
      name
    }
    isApproved
    reviews {
      id
      completor {
        id
        name
      }
      completedAt
    }
    submittedAt
    vendor {
      id
      name
    }
    vendorReferenceNumber
    workOrderIssues {
      id
      serviceIssue {
        id
        description
        name
      }
    }
  }
`;

export const SERVICE_INVOICE_REVIEW_APPROVAL_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment ServiceInvoiceReviewApprovalDialogServiceTicketFragment on ServiceTicket {
    id
    approvalPhaseEndedAt
    approvalPhaseStartedAt
    draftPhaseEndedAt
    draftPhaseStartedAt
    inProgressPhaseEndedAt
    inProgressPhaseStartedAt
    isApprovalPhaseCompleted
    isDraftPhaseCompleted
    isInProgressPhaseCompleted
    isQuotingPhaseCompleted
    isReleasePhaseCompleted
    isSiteVisitPhaseCompleted
    phase {
      id
      enum
      name
    }
    quotingPhaseEndedAt
    quotingPhaseStartedAt
    releasePhaseEndedAt
    releasePhaseStartedAt
    serviceInvoices {
      id
      ...ServiceInvoiceReviewApprovalDialogServiceInvoiceFragment
    }
    siteVisitPhaseEndedAt
    siteVisitPhaseStartedAt
    status {
      id
      enum
      name
    }
  }
  ${SERVICE_INVOICE_REVIEW_APPROVAL_DIALOG_SERVICE_INVOICE_FRAGMENT}
`;
