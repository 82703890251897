import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from '@lodash';
import { Box, Tooltip } from '@mui/material';
import { useSocketIo } from 'app/providers/socketIo';
import clsx from 'clsx';
import { useMemo } from 'react';

const EntitySearchHeaderConnectedStatusIcon = ({ className }) => {
  const { connectedSockets } = useSocketIo();

  const isConnected = useMemo(
    () =>
      !_.isEmpty(connectedSockets) &&
      _.every(Object.keys(connectedSockets), (socketId) => connectedSockets[socketId]),
    [connectedSockets]
  );

  return (
    <div className={className}>
      <Tooltip title={isConnected ? 'Cloud: Connected' : 'Cloud: Disconnected'}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            height: 40,
            justifyContent: 'center',
            width: 40,
          }}
        >
          <FontAwesomeIcon
            className={clsx({ 'text-green-500': isConnected, 'text-red-500': !isConnected })}
            icon={faRotate}
            size="lg"
          />
        </Box>
      </Tooltip>
    </div>
  );
};

export default EntitySearchHeaderConnectedStatusIcon;
