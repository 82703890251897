// eslint-disable-next-line import/prefer-default-export
export const budgetProjectSearchScreenFilterDefs = [
  {
    name: 'Budget Project',
    refinements: [
      {
        attribute: 'budget.name',
        label: 'Budget',
        operator: 'or',
      },
      {
        attribute: 'commonStandardRateTypes.commonStandardRateType.name',
        label: 'Rate Type',
        operator: 'or',
      },
      {
        attribute: 'generalLedgerAccount',
        label: 'GL Account',
        operator: 'or',
      },
      {
        attribute: 'generalLedgerNumber',
        label: 'GL Number',
        operator: 'or',
      },
      {
        attribute: 'isDefault',
        label: 'Default',
        operator: 'or',
      },
      {
        attribute: 'owner.name',
        label: 'Owner',
        operator: 'or',
      },
      {
        attribute: 'projectCode',
        label: 'Project Code',
        operator: 'or',
      },
      {
        attribute: 'status.name',
        label: 'Status',
        operator: 'or',
      },
    ],
  },
];
