import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SERVICE_TICKET_COMPLETE_RELEASE_PHASE_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment ServiceTicketCompleteReleasePhaseDialogServiceTicketFragment on ServiceTicket {
    id
    approvalPhaseEndedAt
    approvalPhaseStartedAt
    draftPhaseEndedAt
    draftPhaseStartedAt
    hasPausedIssues
    inProgressPhaseEndedAt
    inProgressPhaseStartedAt
    isApprovalPhaseCompleted
    isDraftPhaseCompleted
    isInProgressPhaseCompleted
    isQuotingPhaseCompleted
    isReleasePhaseCompleted
    isSiteVisitPhaseCompleted
    latestCompleteBy
    phase {
      id
      enum
      name
    }
    quotingPhaseEndedAt
    quotingPhaseStartedAt
    releasePhaseEndedAt
    releasePhaseStartedAt
    siteVisitPhaseEndedAt
    siteVisitPhaseStartedAt
    status {
      id
      enum
      name
    }
    workOrder {
      id
      issues {
        id
        completeBy
        notToExceedAmount
        scheduledResumptions {
          id
          createdAt
          isProcessed
          resumeAt
        }
        serviceIssue {
          id
          description
          name
        }
        shippingAmount
        status {
          id
          enum
          name
        }
        subtotalAmount
        taxAmount
        totalAmount
      }
    }
  }
`;
