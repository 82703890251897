import { useLazyQuery } from '@apollo/client';
import FuseLoading from '@fuse/core/FuseLoading';
import _ from '@lodash';
import { List, Paper, Typography } from '@mui/material';
import { showMessage } from 'app/store/fuse/messageSlice';
import { extractServiceTicketOrganizations } from 'app/utils';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import { ServiceInvoiceViewAppListItem } from './components';
import { FETCH_SERVICE_INVOICE_VIEW_APP_DATA } from './queries';

const ServiceInvoiceViewApp = ({ basePath }) => {
  const [fetched, setFetched] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  const [
    fetchServiceInvoiceViewAppData,
    {
      data: serviceInvoiceViewAppData,
      loading: serviceInvoiceViewAppLoading,
      refetch: serviceInvoiceViewAppRefetch,
    },
  ] = useLazyQuery(FETCH_SERVICE_INVOICE_VIEW_APP_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Service Ticket Data',
          variant: 'error',
        })
      );
    },
  });

  const loading = useMemo(
    () => [serviceInvoiceViewAppLoading].includes(true),
    [serviceInvoiceViewAppLoading]
  );

  const serviceTicket = useMemo(
    () => serviceInvoiceViewAppData?.serviceTicket,
    [serviceInvoiceViewAppData?.serviceTicket]
  );

  const serviceTicketOrganizations = useMemo(
    () => serviceTicket && extractServiceTicketOrganizations(serviceTicket),
    [serviceTicket]
  );

  useEffect(() => {
    if (params?.serviceTicketId) {
      fetchServiceInvoiceViewAppData({ variables: { where: { id: params?.serviceTicketId } } });
    }
  }, [fetchServiceInvoiceViewAppData, params?.serviceTicketId]);

  return (
    <SizeMe monitorHeight monitorWidth={false}>
      {({ size }) => (
        <div className="flex flex-1 flex-row h-full">
          {(loading && !fetched) || !serviceTicket ? (
            <div className="flex w-full justify-center items-center">
              <FuseLoading />
            </div>
          ) : (
            <Paper
              className="w-full overflow-scroll"
              elevation={0}
              square
              sx={{ height: size?.height }}
            >
              {_.isEmpty(serviceTicket.serviceInvoices) ? (
                <div className="flex items-center justify-center w-full h-full">
                  <Typography className="text-24">No Service Invoices Found</Typography>
                </div>
              ) : (
                <List disablePadding>
                  {_.map(
                    _.sortBy(serviceTicket.serviceInvoices || [], 'submittedAt'),
                    (serviceInvoice) => (
                      <ServiceInvoiceViewAppListItem
                        basePath={basePath}
                        key={serviceInvoice.id}
                        serviceInvoice={serviceInvoice}
                        serviceTicketOrganizations={serviceTicketOrganizations}
                      />
                    )
                  )}
                </List>
              )}
            </Paper>
          )}
        </div>
      )}
    </SizeMe>
  );
};

export default ServiceInvoiceViewApp;
