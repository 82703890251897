import _ from '@lodash';
import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const buildProgramSearchScreenColumnDefs = ({ theme, user }) => [
  {
    groupId: 'program',
    headerName: 'Program',
    children: [
      {
        cellDataType: 'text',
        field: 'id',
        headerName: 'ID',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: 'createdAtTimestamp',
        headerName: 'Created',
        sortable: true,
        initialSort: 'desc',
        valueFormatter: ({ value }) =>
          value ? moment.unix(value).format('MMM. DD, YYYY @ hh:mm A') : undefined,
      },
      {
        cellDataType: 'text',
        field: 'name',
        headerName: 'Name',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'description',
        headerName: 'Description',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: 'issueTemplates',
        headerName: 'Issue Templates',
        sortable: false,
        valueGetter: ({ data }) => data?.issueTemplates && data?.issueTemplates.length,
      },
      {
        cellDataType: 'text',
        field: 'status.name',
        headerName: 'Status',
        sortable: true,
      },
      {
        cellDataType: 'object',
        field: 'tags',
        headerName: 'Tags',
        sortable: false,
        valueFormatter: ({ value }) => {
          const tags = value
            ?.map((el) => el?.name)
            .filter((el) => el)
            .sort();

          return !_.isEmpty(tags) ? _.uniq(tags).join(', ') : undefined;
        },
      },
      {
        cellDataType: 'text',
        field: 'creator.name',
        headerName: 'Creator',
        sortable: true,
      },
    ],
  },
  {
    groupId: 'sharing-rule',
    headerName: 'Sharing Rule',
    children: [
      {
        cellDataType: 'text',
        field: 'commonSharingRule.type.name',
        headerName: 'Type',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'commonSharingRule.subjectType.name',
        headerName: 'Subject Type',
        sortable: true,
      },
      {
        cellDataType: 'object',
        field: 'commonSharingRule.subjectTeams',
        headerName: 'Subject Teams',
        sortable: false,
        valueFormatter: ({ value }) => {
          const subjectTeams = value
            ?.map((el) => el?.name)
            .filter((el) => el)
            .sort();

          return !_.isEmpty(subjectTeams) ? _.uniq(subjectTeams).join(', ') : undefined;
        },
        valueGetter: ({ data }) =>
          data?.commonSharingRule?.subjectTeams?.map(({ team }) => team)?.filter((el) => el) ||
          undefined,
      },
    ],
  },
];
