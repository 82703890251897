import { gql } from '@apollo/client';
import { WORK_ORDER_ISSUE_RE_CODE_DIALOG_SERVICE_TICKET_FRAGMENT } from './fragments';

export const CHECK_IN_WORK_ORDER_ISSUE = gql`
  mutation CheckInWorkOrderIssue(
    $where: ServiceTicketWhereUniqueInput!
    $data: WorkOrderIssueCheckInInput!
  ) {
    checkInWorkOrderIssue(where: $where, data: $data) {
      id
      ...WorkOrderIssueReCodeDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_RE_CODE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;

export const CHECK_OUT_WORK_ORDER_ISSUE = gql`
  mutation CheckOutWorkOrderIssue(
    $where: ServiceTicketWhereUniqueInput!
    $data: WorkOrderIssueCheckOutInput!
  ) {
    checkOutWorkOrderIssue(where: $where, data: $data) {
      id
      ...WorkOrderIssueReCodeDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_RE_CODE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;

export const FETCH_WORK_ORDER_ISSUE_RE_CODE_DIALOG_DATA = gql`
  query FetchWorkOrderIssueReCodeDialogData {
    workOrderIssueReCodeActionTypes(where: {}) {
      id
      enum
      name
    }
  }
`;

export const RE_CODE_WORK_ORDER_ISSUE = gql`
  mutation ReCodeWorkOrderIssue(
    $where: ServiceTicketWhereUniqueInput!
    $data: WorkOrderIssueReCodeInput!
  ) {
    reCodeWorkOrderIssue(where: $where, data: $data) {
      id
      ...WorkOrderIssueReCodeDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_RE_CODE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
