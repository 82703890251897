import { gql } from '@apollo/client';
import {
  WORK_ORDER_ISSUE_TAX_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT,
  WORK_ORDER_ISSUE_TAX_UPDATE_DIALOG_WORK_ORDER_ISSUE_FRAGMENT,
} from './fragments';

export const FETCH_WORK_ORDER_ISSUE_TAX_UPDATE_DIALOG_DATA = gql`
  query FetchWorkOrderIssueTaxUpdateDialogData($where: WorkOrderIssueWhereUniqueInput!) {
    workOrderIssue(where: $where) {
      id
      ...WorkOrderIssueTaxUpdateDialogWorkOrderIssueFragment
    }
  }
  ${WORK_ORDER_ISSUE_TAX_UPDATE_DIALOG_WORK_ORDER_ISSUE_FRAGMENT}
`;

export const UPDATE_WORK_ORDER_ISSUE_TAX = gql`
  mutation UpdateWorkOrderIssueTax(
    $where: ServiceTicketWhereUniqueInput!
    $data: WorkOrderIssueTaxUpdateInput!
  ) {
    updateWorkOrderIssueTax(where: $where, data: $data) {
      id
      ...WorkOrderIssueTaxUpdateDialogServiceTicketFragment
    }
  }
  ${WORK_ORDER_ISSUE_TAX_UPDATE_DIALOG_SERVICE_TICKET_FRAGMENT}
`;
