import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const WORK_ORDER_ISSUE_ACTION_ITEM_CREATE_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment WorkOrderIssueActionItemCreateDialogServiceTicketFragment on ServiceTicket {
    id
    phase {
      id
      enum
      name
    }
    workOrder {
      id
      issues {
        id
        actionItems {
          id
          commonStandardFlatRate {
            id
            quantity
          }
          commonStandardHourlyRate {
            id
            hours
            rateAmount
            technicians
          }
          commonStandardRateType {
            id
            enum
            name
          }
          costCode {
            id
            csiClassification {
              id
              name
              number
            }
          }
          description
          isCapitalExpense
          notToExceedAmount
          subtotalAmount
          totalAmount
        }
        budget {
          id
          name
        }
        budgetProject {
          id
          commonStandardRateTypes {
            commonStandardRateType {
              id
              enum
              name
            }
          }
          name
          _count {
            costCodes
            serviceCompletionItems
          }
        }
        budgetSpecialProject {
          id
          name
          _count {
            costCodes
            serviceCompletionItems
          }
        }
        notToExceedAmount
        serviceIssue {
          id
          commonTargetType {
            id
            enum
            name
          }
          targetFacilityAreas {
            id
            type {
              id
            }
          }
        }
        status {
          id
          enum
          name
        }
        shippingAmount
        subtotalAmount
        subtotalAmountCheck {
          id
          enum
          name
        }
        taxAmount
        totalAmount
      }
      notToExceedAmount
      shippingAmount
      subtotalAmount
      taxAmount
      totalAmount
    }
  }
`;
