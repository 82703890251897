import { faFileImage, faFilePdf, faFileVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from '@lodash';
import { Alert, Avatar, Badge, IconButton, Popover, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const WorkOrderIssueAttachmentBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: '20%',
    top: '20%',
    padding: '0 2px',
  },
}));

const WorkOrderViewAppAttachmentsPopover = ({
  actionRenderer,
  attachments,
  badgeContentRenderer,
  PopoverProps,
  onOpenImageLightbox,
  onOpenPdfWindow,
  onOpenVideoJsPlayer,
}) => {
  const mainTheme = useSelector(selectMainTheme);

  const handleOpenImageLightbox = useCallback(
    (params) => {
      if (typeof onOpenImageLightbox === 'function') {
        onOpenImageLightbox(params);
      }

      return false;
    },
    [onOpenImageLightbox]
  );

  const handleOpenPdfWindow = useCallback(
    (params) => {
      if (typeof onOpenPdfWindow === 'function') {
        onOpenPdfWindow(params);
      }

      return false;
    },
    [onOpenPdfWindow]
  );

  const handleOpenVideoJsPlayer = useCallback(
    (params) => {
      if (typeof onOpenVideoJsPlayer === 'function') {
        onOpenVideoJsPlayer(params);
      }

      return false;
    },
    [onOpenVideoJsPlayer]
  );

  return (
    <Popover {...PopoverProps}>
      <div
        className="flex items-center p-16 h-64"
        style={{
          backgroundColor: mainTheme?.palette.mode === 'dark' ? '#0e131f' : grey[200],
        }}
      >
        <Typography className="text-16 font-500">Attachments</Typography>
      </div>

      <div className="p-16 w-288">
        {!_.isEmpty(attachments) ? (
          <div className="flex flex-wrap items-center">
            {attachments?.map((attachment) => (
              <div key={attachment.id}>
                {attachment.commonStoredType?.enum === 'COMMON_STORED_IMAGE' && (
                  <WorkOrderIssueAttachmentBadge
                    badgeContent={
                      typeof badgeContentRenderer === 'function'
                        ? badgeContentRenderer({ attachment })
                        : null
                    }
                    color="secondary"
                    overlap="circular"
                  >
                    <IconButton
                      onClick={() => {
                        handleOpenImageLightbox({
                          slides: [
                            {
                              src: attachment.commonStoredImage?.lightboxImageUrl,
                            },
                          ],
                        });
                      }}
                    >
                      <Avatar
                        src={attachment.commonStoredImage?.thumbnailImageUrl}
                        sx={{ border: '2px solid', width: 42, height: 42 }}
                      >
                        <FontAwesomeIcon icon={faFileImage} />
                      </Avatar>
                    </IconButton>
                  </WorkOrderIssueAttachmentBadge>
                )}

                {attachment.commonStoredType?.enum === 'COMMON_STORED_PDF' && (
                  <WorkOrderIssueAttachmentBadge
                    badgeContent={
                      typeof badgeContentRenderer === 'function'
                        ? badgeContentRenderer({ attachment })
                        : null
                    }
                    color="secondary"
                    overlap="circular"
                  >
                    <IconButton
                      onClick={() =>
                        handleOpenPdfWindow({
                          pdfUrl: attachment.commonStoredPdf?.originalPdfUrl,
                        })
                      }
                    >
                      <Avatar
                        src={attachment.commonStoredPdf?.thumbnailImageUrl}
                        sx={{ border: '2px solid', width: 42, height: 42 }}
                      >
                        <FontAwesomeIcon icon={faFilePdf} />
                      </Avatar>
                    </IconButton>
                  </WorkOrderIssueAttachmentBadge>
                )}

                {attachment.commonStoredType?.enum === 'COMMON_STORED_VIDEO' && (
                  <IconButton
                    onClick={() => {
                      handleOpenVideoJsPlayer({
                        options: {
                          autoplay: false,
                          controls: true,
                          responsive: true,
                          fluid: true,
                          poster: attachment.commonStoredVideo?.standardImageUrl,
                          sources: [
                            {
                              src: attachment.commonStoredVideo?.playlistFileUrl,
                              type: 'application/x-mpegURL',
                            },
                          ],
                          tracks: [
                            {
                              src: attachment.commonStoredVideo?.captionsFileUrl,
                              kind: 'captions',
                              srclang: 'en',
                              label: 'English',
                            },
                          ],
                        },
                      });
                    }}
                  >
                    <Avatar
                      src={attachment.commonStoredVideo?.thumbnailImageUrl}
                      sx={{ border: '2px solid', width: 42, height: 42 }}
                    >
                      <FontAwesomeIcon icon={faFileVideo} />
                    </Avatar>
                  </IconButton>
                )}
              </div>
            ))}
          </div>
        ) : (
          <Alert severity="info">No Attachments Found</Alert>
        )}
      </div>

      {typeof actionRenderer === 'function' && actionRenderer()}
    </Popover>
  );
};

export default WorkOrderViewAppAttachmentsPopover;
