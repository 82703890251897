import _ from '@lodash';
import { useSocketIo } from 'app/providers/socketIo';
import { selectUser } from 'app/store/userSlice';
import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

const SocketIoRoom = ({
  canConnect,
  includeSockets,
  makeSocketListeners,
  roomId,
  onConnect,
  onDisconnect,
}) => {
  const initializedSockets = useRef({});
  const user = useSelector(selectUser);

  const { joinRoom, leaveRoom, organizationSocket, setConnectedSockets, teamSocket, userSocket } =
    useSocketIo();

  const roomSockets = useMemo(() => {
    const keyedSockets = {
      organization: organizationSocket,
      team: teamSocket,
      user: userSocket,
    };

    return Object.keys(keyedSockets)
      .filter((key) => includeSockets?.includes(key))
      .map((key) => keyedSockets[key]);
  }, [includeSockets, organizationSocket, teamSocket, userSocket]);

  useEffect(() => {
    const cleanUpSocket = ({ socket }) => {
      if (socket.connected && socket.id) {
        leaveRoom({
          namespace: socket.nsp,
          roomId: socket.nsp.indexOf('users') !== -1 ? `user:${user?.id}/${roomId}` : roomId,
          socketId: socket.id,
        });
      }

      setConnectedSockets((prev) => {
        const next = { ...(prev || {}) };

        _.unset(next, socket.nsp);

        return next;
      });

      initializedSockets.current[socket.nsp] = false;
    };

    const initializeSocket = ({ socket }) => {
      setTimeout(() => {
        if (socket.connected && socket.id) {
          joinRoom({
            namespace: socket.nsp,
            roomId: socket.nsp.indexOf('users') !== -1 ? `user:${user?.id}/${roomId}` : roomId,
            socketId: socket.id,
          });

          initializedSockets.current[socket.nsp] = true;
        }
      }, 100);
    };

    const makeOnConnect =
      ({ socket }) =>
      () => {
        if (typeof onConnect === 'function') {
          onConnect({ socket });
        }

        setConnectedSockets((prev) => ({ ...(prev || {}), [socket.nsp]: true }));

        if (!initializedSockets.current[socket.nsp]) {
          initializeSocket({ socket });
        }
      };

    const makeOnDisconnect =
      ({ socket }) =>
      () => {
        if (typeof onDisconnect === 'function') {
          onDisconnect({ socket });
        }

        setConnectedSockets((prev) => ({ ...(prev || {}), [socket.nsp]: false }));

        initializedSockets.current[socket.nsp] = false;
      };

    const socketListeners = {};

    if (canConnect && roomSockets) {
      roomSockets.forEach((socket) => {
        socketListeners[socket.nsp] = {
          connect: makeOnConnect({ socket }),
          disconnect: makeOnDisconnect({ socket }),
          ...(typeof makeSocketListeners === 'function' ? makeSocketListeners({ socket }) : {}),
        };

        Object.keys(socketListeners[socket.nsp]).forEach((eventName) => {
          socket.on(eventName, socketListeners[socket.nsp][eventName]);
        });

        if (socket?.connected) {
          socketListeners[socket.nsp].connect();
        }
      });
    }

    return () => {
      if (canConnect && roomSockets) {
        roomSockets.forEach((socket) => {
          Object.keys(socketListeners[socket.nsp]).forEach((eventName) => {
            socket.off(eventName, socketListeners[socket.nsp][eventName]);
          });

          cleanUpSocket({ socket });
        });
      }
    };
  }, [
    canConnect,
    joinRoom,
    leaveRoom,
    makeSocketListeners,
    onConnect,
    onDisconnect,
    roomId,
    roomSockets,
    setConnectedSockets,
    user?.id,
  ]);

  return null;
};

export default SocketIoRoom;
