import { faCircleXmark, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDeepCompareEffect } from '@fuse/hooks';
import _ from '@lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { grey, lightBlue, red } from '@mui/material/colors';
import { useMeilisearch } from 'app/providers/meilisearch';
import { CommonCurrencyField } from 'app/shared-components/Common/CommonField';
import { CommonDatepicker } from 'app/shared-components/Common/CommonDatepicker';
import { CommonTargetTypeSelectField } from 'app/shared-components/CommonTargetType';
import { EntitySearchSelectFieldV2 } from 'app/shared-components/EntitySearch/EntitySearchSelectFieldV2';
import { ServiceIssueHitsListOption } from 'app/shared-components/ServiceIssue';
import { ServiceIssueTagSelectField } from 'app/shared-components/ServiceIssueTag';
import { ServiceResponseSeveritySelectField } from 'app/shared-components/ServiceResponseSeverity';
// ROADMAP: Re-Implement or Remove
// import { ServiceResponsiblePartySelectField } from 'app/shared-components/ServiceResponsibleParty';
import { selectUser } from 'app/store/userSlice';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  ServiceTicketCreateDialogWorkOrderIssueActionItems,
  ServiceTicketCreateDialogWorkOrderIssueAttachments,
  ServiceTicketCreateDialogWorkOrderIssueTemplate,
} from '.';

const ServiceTicketCreateDialogWorkOrderIssues = ({
  defaultBudget,
  defaultBudgetProject,
  defaultCommonTargetType,
  defaultServiceResponseSeverity,
  name,
}) => {
  const { axiosSearchClient } = useMeilisearch();
  const dispatch = useDispatch();
  const [expandedFieldIndex, setExpandedFieldIndex] = useState(null);
  const user = useSelector(selectUser);

  const {
    clearErrors,
    control,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    watch,
  } = useFormContext();

  const { append, fields, insert, move, prepend, remove, replace, swap, update } = useFieldArray({
    control,
    name,
  });

  const fieldCount = useRef(0);
  const watchFacility = watch('facility');
  const watchServiceProvision = watch('serviceProvision');
  const watchFields = watch(name);

  useDeepCompareEffect(() => {
    if (fieldCount.current === 0 && fields?.length === 1) {
      clearErrors(name);
    }

    if (fields?.length > fieldCount.current) {
      setExpandedFieldIndex(fields?.length - 1);
    }

    fieldCount.current = fields?.length || 0;
  }, [fields]);

  useEffect(() => {
    if (getValues && name && setValue) {
      const values = getValues(name);

      setValue(
        name,
        values?.map((value) => ({
          ...value,
          budget: defaultBudget || null,
          budgetProject: defaultBudgetProject || null,
        }))
      );
    }
  }, [defaultBudget, defaultBudgetProject, getValues, name, setValue]);

  const handleChangeExpandedFieldIndex = (index) => (event, isExpanded) => {
    setExpandedFieldIndex(isExpanded ? index : false);
  };

  const handleIssueTemplateSelect = async ({ index, issueTemplate }) => {
    const {
      data: { hits },
    } = await axiosSearchClient.post('/indexes/facility_areas/search', {
      filter: [
        `facility.id = '${watchFacility?.id}'`,
        `serviceProvisions.serviceProvision.id = '${watchServiceProvision?.id}'`,
      ],
      limit: undefined,
      q: '',
    });

    const targetFacilityAreas = hits?.filter((facilityArea) =>
      issueTemplate?.serviceIssue?.targetFacilityAreaTypes
        .map(({ facilityAreaType }) => facilityAreaType.id)
        .includes(facilityArea.typeId)
    );

    [
      {
        path: 'serviceIssue.mutation',
        value: 'CREATE',
      },
      {
        path: 'isSaveAsIssueTemplate',
        value: false,
      },
      {
        path: 'serviceIssue.create.name',
        value: issueTemplate.serviceIssue?.name ?? '',
      },
      {
        path: 'budget',
        value: issueTemplate.workOrderIssue?.budget,
      },
      {
        path: 'budgetProject',
        value: issueTemplate.workOrderIssue?.budgetProject,
      },
      {
        path: 'budgetSpecialProject',
        value: issueTemplate.workOrderIssue?.budgetSpecialProject,
      },
      {
        path: 'serviceIssue.create.commonTargetType',
        value: {
          label: issueTemplate.serviceIssue?.commonTargetType?.name,
          result: issueTemplate.serviceIssue?.commonTargetType,
          value: issueTemplate.serviceIssue?.commonTargetType?.id,
        },
      },
      {
        path: 'serviceIssue.create.targetFacilityAreas',
        value: targetFacilityAreas,
      },
      {
        path: 'serviceIssue.create.description',
        value: issueTemplate.serviceIssue?.description ?? '',
      },
      {
        path: 'serviceIssue.create.tags',
        value:
          issueTemplate.serviceIssue?.serviceIssueTags?.map((serviceIssueTag) => ({
            label: serviceIssueTag?.name,
            result: serviceIssueTag,
            value: serviceIssueTag?.id,
          })) || [],
      },
      {
        path: 'actionItems',
        value:
          issueTemplate.workOrderIssue?.actionItems?.map((actionItem) => ({
            costCode: actionItem?.costCode,
            description: actionItem?.description ?? '',
            notToExceedAmount: actionItem?.notToExceedAmount || null,
          })) || [],
      },
      {
        path: 'notToExceedAmount',
        value: issueTemplate.workOrderIssue?.notToExceedAmount,
      },
      {
        path: 'serviceIssue.create.attachments',
        value: issueTemplate.serviceIssue?.attachments
          ?.map((attachment, attachmentIndex) => {
            let attachmentsValue;

            if (attachment.commonStoredType?.enum === 'COMMON_STORED_CAD') {
              attachmentsValue = {
                file: {
                  name:
                    attachment.commonStoredCad?.metadata?.name ||
                    `Issue Template Attachment ${attachmentIndex + 1}`,
                  preview: null,
                },
                commonStoredUpload: { key: attachment.commonStoredCad?.commonStoredUpload?.key },
              };
            } else if (attachment.commonStoredType?.enum === 'COMMON_STORED_IMAGE') {
              attachmentsValue = {
                file: {
                  name:
                    attachment.commonStoredImage?.metadata?.name ||
                    `Issue Template Attachment ${attachmentIndex + 1}`,
                  preview: null,
                },
                commonStoredUpload: { key: attachment.commonStoredImage?.commonStoredUpload?.key },
              };
            } else if (attachment.commonStoredType?.enum === 'COMMON_STORED_PDF') {
              attachmentsValue = {
                file: {
                  name:
                    attachment.commonStoredPdf?.metadata?.name ||
                    `Issue Template Attachment ${attachmentIndex + 1}`,
                  preview: null,
                },
                commonStoredUpload: { key: attachment.commonStoredPdf?.commonStoredUpload?.key },
              };
            } else if (attachment.commonStoredType?.enum === 'COMMON_STORED_VIDEO') {
              attachmentsValue = {
                file: {
                  name:
                    attachment.commonStoredVideo?.metadata?.name ||
                    `Issue Template Attachment ${attachmentIndex + 1}`,
                  preview: null,
                },
                commonStoredUpload: { key: attachment.commonStoredVideo?.commonStoredUpload?.key },
              };
            }

            return attachmentsValue;
          })
          .filter((el) => el),
      },
    ].forEach(({ path, value }) => {
      setValue(`${`${name}[${index}]`}.${path}`, value, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: false,
      });
    });

    trigger();
  };

  return (
    <>
      {errors?.[name] && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {_.get(errors, [name, 'message']) || 'One or More Service Issues Contain Errors'}
        </Alert>
      )}

      {fields.map((item, index) => {
        let borderLeftColor = grey[600];

        if (_.isArray(_.get(errors, name)) && errors[name][index]) {
          // eslint-disable-next-line prefer-destructuring
          borderLeftColor = red[800];
        } else if (expandedFieldIndex === index) {
          // eslint-disable-next-line prefer-destructuring
          borderLeftColor = lightBlue[600];
        }

        return (
          <Accordion
            expanded={expandedFieldIndex === index}
            key={item.id}
            variant="outlined"
            onChange={handleChangeExpandedFieldIndex(index)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ borderLeft: `4px solid ${borderLeftColor}` }}
            >
              <div className="flex flex-1 items-center">
                {_.isArray(_.get(errors, name)) &&
                  _.isPlainObject(_.get(errors, name)[index]) &&
                  _.some(Object.keys(_.get(errors, name)[index]), (key) =>
                    _.isPlainObject(_.get(errors, name)[index][key])
                  ) && (
                    <FontAwesomeIcon className="mr-8 text-red-800" icon={faCircleXmark} size="lg" />
                  )}

                <Typography>
                  {item?.serviceIssue?.create?.name ||
                    item?.serviceIssue?.connect?.name ||
                    `Service Issue ${index + 1}`}
                </Typography>
              </div>

              <IconButton
                sx={{ mr: 1, padding: 0, width: '24px', height: '24px' }}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  remove(index);
                }}
              >
                <FontAwesomeIcon icon={faTrashCan} size="xs" />
              </IconButton>
            </AccordionSummary>

            <AccordionDetails sx={{ borderLeft: `4px solid ${borderLeftColor}` }}>
              {item.serviceIssue?.mutation === 'CONNECT' && (
                <>
                  <Controller
                    control={control}
                    name={`${name}[${index}].serviceIssue.connect`}
                    render={({ field }) => (
                      <EntitySearchSelectFieldV2
                        {...field}
                        className="mb-24"
                        components={{ Option: ServiceIssueHitsListOption }}
                        error={_.get(errors, `${name}[${index}].serviceIssue.connect`)}
                        filter={[
                          `targetFacilityAreas.facilityArea.facility.id = '${watchFacility?.id}'`,
                          `targetFacilityAreas.facilityArea.serviceProvisions.serviceProvision.id = '${watchServiceProvision?.id}'`,
                          `status.enum != 'IN_PROGRESS'`,
                        ]}
                        getOptionLabel={(option) => option?.name}
                        getOptionValue={(option) => option?.id}
                        indexName="service_issues"
                        isClearable
                        isDisabled={false}
                        isMulti={false}
                        placeholder="Select Service Issue..."
                        sort={['name:asc']}
                        onChange={(params) => {
                          setValue(`${name}[${index}].budget`, (params && defaultBudget) ?? null);
                          setValue(
                            `${name}[${index}].budgetProject`,
                            (params && defaultBudgetProject) ?? null
                          );
                          setValue(`${name}[${index}].budgetSpecialProject`, null);
                          setValue(
                            `${name}[${index}].serviceIssue.create.serviceResponseSeverity`,
                            (params && {
                              label: params.serviceResponseSeverity?.name,
                              result: params.serviceResponseSeverity,
                              value: params.serviceResponseSeverity?.id,
                            }) ??
                              null
                          );
                          setValue(
                            `${name}[${index}].completeBy`,
                            (params &&
                              moment()
                                .add(
                                  params?.serviceResponseSeverity?.value,
                                  params?.serviceResponseSeverity?.commonTemporalDurationUnit?.enum
                                )
                                .format()) ??
                              null
                          );

                          watchFields?.[index]?.actionItems?.forEach(
                            (actionItem, actionItemIndex) => {
                              setValue(`${name}[${index}].actionItems[${actionItemIndex}]`, {
                                ...actionItem,
                                costCode: null,
                              });
                            }
                          );

                          if (params && _.isEmpty(watchFields?.[index]?.actionItems)) {
                            setValue(
                              `${name}[${index}].notToExceedAmount`,
                              params?.estimatedAmount
                            );
                          }

                          field.onChange(params);
                        }}
                      />
                    )}
                  />
                </>
              )}

              {item.serviceIssue?.mutation === 'CREATE' && (
                <>
                  <ServiceTicketCreateDialogWorkOrderIssueTemplate
                    workOrderIssueFieldName={`${name}[${index}]`}
                    onIssueTemplateSelect={({ issueTemplate }) =>
                      handleIssueTemplateSelect({ index, issueTemplate })
                    }
                  />

                  <Divider sx={{ mb: 3 }} />

                  <Controller
                    control={control}
                    name={`${name}[${index}].serviceIssue.create.name`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mb-24"
                        error={_.get(errors, `${name}[${index}].serviceIssue.create.name`)}
                        fullWidth
                        helperText={_.get(
                          errors,
                          `${name}[${index}].serviceIssue.create.name.message`
                        )}
                        placeholder="Enter Name..."
                        required
                        variant="outlined"
                      />
                    )}
                  />
                </>
              )}

              <Controller
                control={control}
                name={`${name}[${index}].budget`}
                render={({ field }) => (
                  <EntitySearchSelectFieldV2
                    {...field}
                    className="mb-24"
                    error={_.get(errors, `${name}[${index}].budget`)}
                    // ROADMAP: Limit to Budget Segments
                    filter={[
                      `facilities.facility.id = '${watchFacility?.id}'`,
                      `owner.id IN [${watchFacility?.organizations
                        ?.map(({ organization }) => organization.id)
                        .filter((el) => el)}]`,
                      `status.enum = 'ACTIVE'`,
                    ]}
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?.id}
                    indexName="budgets"
                    isClearable
                    isDisabled={false}
                    isMulti={false}
                    placeholder="Select Budget..."
                    sort={['name:asc']}
                    onChange={(params) => {
                      setValue(`${name}[${index}].budgetProject`, null);
                      setValue(`${name}[${index}].budgetSpecialProject`, null);

                      if (item.serviceIssue?.mutation === 'CREATE') {
                        setValue(`${name}[${index}].serviceIssue.create.commonTargetType`, null);
                        setValue(`${name}[${index}].serviceIssue.create.targetFacilityAreas`, []);
                      }

                      watchFields?.[index]?.actionItems?.forEach((actionItem, actionItemIndex) => {
                        setValue(`${name}[${index}].actionItems[${actionItemIndex}]`, {
                          ...actionItem,
                          costCode: null,
                        });
                      });

                      field.onChange(params);
                    }}
                  />
                )}
              />

              <Controller
                control={control}
                name={`${name}[${index}].budgetProject`}
                render={({ field }) => (
                  <EntitySearchSelectFieldV2
                    {...field}
                    className="mb-24"
                    error={_.get(errors, `${name}[${index}].budgetProject`)}
                    filter={[
                      `budget.id = '${watchFields?.[index]?.budget?.id}'`,
                      `owner.id IN [${watchFacility?.organizations
                        ?.map(({ organization }) => organization.id)
                        .filter((el) => el)}]`,
                      `status.enum = 'ACTIVE'`,
                    ]}
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?.id}
                    indexName="budget_projects"
                    isClearable
                    isDisabled={!watchFields?.[index]?.budget}
                    isMulti={false}
                    placeholder="Select Budget Project..."
                    sort={['name:asc']}
                    onChange={(params) => {
                      setValue(`${name}[${index}].budgetSpecialProject`, null);

                      watchFields?.[index]?.actionItems?.forEach((actionItem, actionItemIndex) => {
                        setValue(`${name}[${index}].actionItems[${actionItemIndex}]`, {
                          ...actionItem,
                          costCode: null,
                        });
                      });

                      field.onChange(params);
                    }}
                  />
                )}
              />

              <Controller
                control={control}
                name={`${name}[${index}].budgetSpecialProject`}
                render={({ field }) => (
                  <EntitySearchSelectFieldV2
                    {...field}
                    className="mb-24"
                    error={_.get(errors, `${name}[${index}].budgetSpecialProject`)}
                    filter={[
                      `owner.id IN [${watchFacility?.organizations
                        ?.map(({ organization }) => organization.id)
                        .filter((el) => el)}]`,
                      `status.enum = 'ACTIVE'`,
                    ]}
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?.id}
                    indexName="budget_special_projects"
                    isClearable
                    isDisabled={
                      !watchFields?.[index]?.budget || !watchFields?.[index]?.budgetProject
                    }
                    isMulti={false}
                    placeholder="Select Budget Special Project..."
                    sort={['name:asc']}
                    onChange={(params) => {
                      watchFields?.[index]?.actionItems?.forEach((actionItem, actionItemIndex) => {
                        setValue(`${name}[${index}].actionItems[${actionItemIndex}]`, {
                          ...actionItem,
                          costCode: null,
                        });
                      });

                      field.onChange(params);
                    }}
                  />
                )}
              />

              {item.serviceIssue?.mutation === 'CREATE' &&
                watchFields?.[index]?.budget &&
                watchFields?.[index]?.budgetProject && (
                  <>
                    <Controller
                      control={control}
                      name={`${name}[${index}].serviceIssue.create.commonTargetType`}
                      render={({ field }) => (
                        <CommonTargetTypeSelectField
                          {...field}
                          className="mb-24"
                          error={_.get(
                            errors,
                            `${name}[${index}].serviceIssue.create.commonTargetType`
                          )}
                          isClearable
                          isDisabled={false}
                          isMulti={false}
                          placeholder="Select Target Type..."
                          onChange={(params) => {
                            setValue(
                              `${name}[${index}].serviceIssue.create.targetFacilityAreas`,
                              []
                            );

                            field.onChange(params);
                          }}
                        />
                      )}
                    />

                    {watchFields?.[index]?.serviceIssue?.create?.commonTargetType?.result?.enum ===
                      'FACILITY_AREA' && (
                      <Controller
                        control={control}
                        name={`${name}[${index}].serviceIssue.create.targetFacilityAreas`}
                        render={({ field }) => (
                          <EntitySearchSelectFieldV2
                            {...field}
                            className="mb-24"
                            error={_.get(
                              errors,
                              `${name}[${index}].serviceIssue.create.targetFacilityAreas`
                            )}
                            // ROADMAP: Limit to Budget Segments
                            filter={[
                              `facility.id = '${watchFacility?.id}'`,
                              `serviceProvisions.serviceProvision.id = '${watchServiceProvision?.id}'`,
                            ].filter((el) => el)}
                            getOptionLabel={(option) => option?.name}
                            getOptionValue={(option) => option?.id}
                            indexName="facility_areas"
                            isClearable
                            isDisabled={false}
                            isMulti
                            placeholder="Select Target Facility Areas..."
                            sort={['name:asc']}
                          />
                        )}
                      />
                    )}
                  </>
                )}

              {(item.serviceIssue?.mutation === 'CONNECT' ||
                item.serviceIssue?.mutation === 'CREATE') &&
                watchFields?.[index]?.budget &&
                watchFields?.[index]?.budgetProject && (
                  <div className="flex flex-1 flex-col sm:flex-row mb-24">
                    <Controller
                      control={control}
                      name={`${name}[${index}].serviceIssue.create.serviceResponseSeverity`}
                      render={({ field }) => (
                        <ServiceResponseSeveritySelectField
                          {...field}
                          className="flex-1 mr-0 sm:mr-12 mb-12 sm:mb-0"
                          error={_.get(
                            errors,
                            `${name}[${index}].serviceIssue.create.serviceResponseSeverity`
                          )}
                          filters={null}
                          isClearable
                          isDisabled={item.serviceIssue?.mutation !== 'CREATE'}
                          isMulti={false}
                          placeholder="Select Response Severity..."
                          onChange={(params) => {
                            setValue(
                              `${name}[${index}].completeBy`,
                              params
                                ? moment()
                                    .add(
                                      params?.result.value,
                                      params?.result.commonTemporalDurationUnit.enum
                                    )
                                    .format()
                                : null,
                              { shouldValidate: true }
                            );

                            field.onChange(params);
                          }}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name={`${name}[${index}].completeBy`}
                      render={({ field }) => (
                        <div className="flex-1 ml-0 sm:ml-12 mt-12 sm:mt-0">
                          <CommonDatepicker
                            {...field}
                            controls={['calendar']}
                            disabled={
                              !watchFields?.[index]?.serviceIssue?.create?.serviceResponseSeverity
                            }
                            error={_.get(errors, `${name}[${index}].completeBy`)}
                            min={moment().add(1, 'day').startOf('day')}
                            placeholder="Select Complete By Date..."
                            select="date"
                          />
                        </div>
                      )}
                    />
                  </div>
                )}

              {item.serviceIssue?.mutation === 'CREATE' &&
                watchFields?.[index]?.budget &&
                watchFields?.[index]?.budgetProject && (
                  <>
                    <Controller
                      control={control}
                      name={`${name}[${index}].serviceIssue.create.description`}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="mb-24"
                          error={_.get(errors, `${name}[${index}].serviceIssue.create.description`)}
                          fullWidth
                          helperText={_.get(
                            errors,
                            `${name}[${index}].serviceIssue.create.description.message`
                          )}
                          maxRows={4}
                          minRows={4}
                          multiline
                          placeholder="Enter Description; Where / What..."
                          required
                          variant="outlined"
                        />
                      )}
                    />

                    {['FRANCHISOR', 'OPERATOR'].includes(user?.data?.organization?.type.enum) && (
                      <Controller
                        control={control}
                        name={`${name}[${index}].serviceIssue.create.tags`}
                        render={({ field }) => (
                          <ServiceIssueTagSelectField
                            {...field}
                            className="mb-24"
                            error={_.get(errors, `${name}[${index}].serviceIssue.create.tags`)}
                            isClearable
                            isDisabled={false}
                            isMulti
                            placeholder="Select / Create Service Issue Tags..."
                          />
                        )}
                      />
                    )}

                    {/* ROADMAP: Re-Implement or Remove */}
                    {/* {['FRANCHISOR', 'OPERATOR'].includes(user?.data?.organization?.type.enum) && (
                      <>
                        <Controller
                          control={control}
                          name={`${name}[${index}].serviceIssue.create.serviceResponsibleParty`}
                          render={({ field }) => (
                            <ServiceResponsiblePartySelectField
                              {...field}
                              className="mb-24"
                              error={_.get(
                                errors,
                                `${name}[${index}].serviceIssue.create.serviceResponsibleParty`
                              )}
                              filters={null}
                              isClearable
                              isDisabled={false}
                              isMulti={false}
                              placeholder="Select Responsible Party..."
                            />
                          )}
                        />
                      </>
                    )} */}
                  </>
                )}

              <Controller
                control={control}
                name={`${name}[${index}].notToExceedAmount`}
                render={({ field }) => (
                  <CommonCurrencyField
                    {...field}
                    className="mb-24"
                    control={control}
                    disabled={!!watchFields[index].actionItems?.length}
                    error={_.get(errors, `${name}[${index}].notToExceedAmount`)}
                    placeholder="Enter Not To Exceed..."
                  />
                )}
              />

              <ServiceTicketCreateDialogWorkOrderIssueActionItems
                budgetFieldName={`${name}[${index}].budget`}
                budgetProjectFieldName={`${name}[${index}].budgetProject`}
                budgetSpecialProjectFieldName={`${name}[${index}].budgetSpecialProject`}
                name={`${name}[${index}].actionItems`}
                totalFieldName={`${name}[${index}].notToExceedAmount`}
              />

              {item.serviceIssue?.mutation === 'CREATE' && (
                <>
                  <Divider sx={{ mb: 3 }} />

                  <ServiceTicketCreateDialogWorkOrderIssueAttachments
                    name={`${name}[${index}].serviceIssue.create.attachments`}
                  />
                </>
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}

      <div className="flex w-full items-center justify-center my-24">
        <Button
          color="inherit"
          disabled={false}
          sx={{ mx: 1 }}
          variant="outlined"
          onClick={() =>
            append({
              actionItems: [],
              budget: defaultBudget ?? null,
              budgetProject: defaultBudgetProject ?? null,
              budgetSpecialProject: null,
              completeBy:
                (defaultServiceResponseSeverity &&
                  moment()
                    .add(
                      defaultServiceResponseSeverity?.result?.value,
                      defaultServiceResponseSeverity?.result?.commonTemporalDurationUnit?.enum
                    )
                    .format()) ??
                null,
              isSaveAsIssueTemplate: false,
              issueTemplate: null,
              notToExceedAmount: null,
              saveAsIssueTemplate: {
                description: '',
                name: '',
                tags: [],
              },
              serviceIssue: {
                create: {
                  commonTargetType: defaultCommonTargetType ?? null,
                  description: '',
                  name: '',
                  targetAsset: null,
                  targetFacilityAreas: [],
                  serviceResponseSeverity: defaultServiceResponseSeverity ?? null,
                },
                mutation: 'CREATE',
              },
            })
          }
        >
          Add New Service Issue
        </Button>

        <Button
          color="inherit"
          disabled={false}
          sx={{ mx: 1 }}
          variant="outlined"
          onClick={() =>
            append({
              actionItems: [],
              budget: null,
              budgetProject: null,
              budgetSpecialProject: null,
              completeBy: null,
              notToExceedAmount: null,
              serviceIssue: {
                connect: null,
                mutation: 'CONNECT',
              },
            })
          }
        >
          Add Existing Service Issue
        </Button>
      </div>
    </>
  );
};

export default ServiceTicketCreateDialogWorkOrderIssues;
