// eslint-disable-next-line import/prefer-default-export
export const issueTemplateSearchScreenFilterDefs = [
  {
    name: 'Issue Template',
    refinements: [
      {
        attribute: 'status.name',
        label: 'Status',
        operator: 'or',
      },
      {
        attribute: 'tags.name',
        label: 'Tags',
        operator: 'or',
      },
      {
        attribute: 'creator.name',
        label: 'Creator',
        operator: 'or',
      },
    ],
  },
  {
    name: 'Work Order Issue',
    refinements: [
      {
        attribute: 'workOrderIssue.budget.name',
        label: 'Budget',
        operator: 'or',
      },
      {
        attribute: 'workOrderIssue.budgetProject.name',
        label: 'Budget Project',
        operator: 'or',
      },
      {
        attribute: 'workOrderIssue.budgetSpecialProject.name',
        label: 'Budget Special Project',
        operator: 'or',
      },
      {
        attribute: 'serviceIssue.commonTargetType.name',
        label: 'Target Type',
        operator: 'or',
      },
      {
        attribute: 'serviceIssue.targetFacilityAreaTypes.facilityAreaType.name',
        label: 'Facility Area Types',
        operator: 'or',
      },
      {
        attribute: 'serviceIssue.serviceIssueTags.name',
        label: 'Tags',
        operator: 'or',
      },
    ],
  },
  {
    name: 'Sharing Rule',
    refinements: [
      {
        attribute: 'commonSharingRule.type.name',
        label: 'Type',
        operator: 'or',
      },
      {
        attribute: 'commonSharingRule.subjectType.name',
        label: 'Subject Type',
        operator: 'or',
      },
      {
        attribute: 'commonSharingRule.subjectTeams.team.name',
        label: 'Subject Teams',
        operator: 'or',
      },
    ],
  },
];
