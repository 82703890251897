import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SERVICE_INVOICE_VIEW_APP_SERVICE_TICKET_FRAGMENT = gql`
  fragment ServiceInvoiceViewAppServiceTicketFragment on ServiceTicket {
    id
    serviceInvoices {
      id
      createdAt
      updatedAt
      commonStoredPdf {
        id
        commonStoredUpload {
          id
          uploader {
            id
            name
          }
        }
        isProcessed
        lightboxImageUrl
        name
        originalPdfUrl
        standardImageUrl
        thumbnailImageUrl
      }
      isApproved
      reviews {
        id
        completor {
          id
          name
        }
        completedAt
      }
      submittedAt
      vendorReferenceNumber
      workOrderIssues {
        id
        serviceIssue {
          id
          description
          name
        }
      }
    }
    serviceProvision {
      id
      name
      organizationConnection {
        id
        consumer {
          id
          name
          type {
            id
            enum
            name
          }
        }
        vendor {
          id
          name
          type {
            id
            enum
            name
          }
        }
      }
    }
  }
`;
