import { gql } from '@apollo/client';

// TODO: Review
export const WORK_ORDER_ISSUE_RE_CODE_DIALOG_WORK_ORDER_ISSUE_FRAGMENT = gql`
  fragment WorkOrderIssueReCodeDialogWorkOrderIssueFragment on WorkOrderIssue {
    id
    actionItems {
      id
      # TODO: codingStrings
      commonStandardFlatRate {
        id
        quantity
      }
      commonStandardHourlyRate {
        id
        hours
        rateAmount
        technicians
      }
      commonStandardRateType {
        id
        enum
        name
      }
      costCode {
        id
        accountingTaskCode {
          id
          name
          number
        }
        csiClassification {
          id
          name
          number
        }
      }
      description
      isCapitalExpense
      notToExceedAmount
      subtotalAmount
      totalAmount
    }
    budget {
      id
      name
    }
    budgetProject {
      id
      # TODO:
      # commonStandardRateTypes {
      #   commonStandardRateType {
      #     id
      #     enum
      #     name
      #   }
      # }
      name
      # TODO:
      _count {
        costCodes
        serviceCompletionItems
      }
    }
    budgetSpecialProject {
      id
      name
      # TODO:
      _count {
        costCodes
        serviceCompletionItems
      }
    }
    isLocked
    lockedAt
    status {
      id
      enum
      name
    }
  }
`;

// TODO: Review
export const WORK_ORDER_ISSUE_RE_CODE_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment WorkOrderIssueReCodeDialogServiceTicketFragment on ServiceTicket {
    id
    lastActivityAt
    workOrder {
      id
      issues {
        id
        ...WorkOrderIssueReCodeDialogWorkOrderIssueFragment
      }
    }
  }
  ${WORK_ORDER_ISSUE_RE_CODE_DIALOG_WORK_ORDER_ISSUE_FRAGMENT}
`;
