import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDeepCompareEffect } from '@fuse/hooks';
import _ from '@lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Divider,
  Typography,
} from '@mui/material';
import { grey, lightBlue, red } from '@mui/material/colors';
import { CommonCurrencyField, CommonDatepicker, CommonSelect } from 'app/shared-components/Common';
import { EntitySearchSelectField } from 'app/shared-components/EntitySearch';
import { ServiceVisitHitsListOption } from 'app/shared-components/ServiceVisit';
import { selectUser } from 'app/store/userSlice';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import WorkOrderIssueCompleteDialogWorkOrderIssueAttachments from './WorkOrderIssueCompleteDialogWorkOrderIssueAttachments';

const WorkOrderIssueCompleteDialogWorkOrderIssues = ({
  filteredWorkOrderIssues,
  name,
  serviceTicket,
}) => {
  const dispatch = useDispatch();
  const [expandedField, setExpandedField] = useState(null);
  const user = useSelector(selectUser);

  const {
    clearErrors,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const { append, fields, insert, move, prepend, remove, replace, swap, update } = useFieldArray({
    control,
    name,
  });

  const fieldCount = useRef(0);
  const watchCheckedWorkOrderIssues = watch('checkedWorkOrderIssues');
  const watchFields = watch(name);

  useEffect(() => {
    const checkedWorkOrderIssues = watchCheckedWorkOrderIssues
      .filter((el) => el)
      .map((workOrderIssueId) => ({ workOrderIssueId }));

    if (checkedWorkOrderIssues?.length > fields?.length) {
      const diff = _.differenceBy(checkedWorkOrderIssues, fields, 'workOrderIssueId');

      diff?.forEach(({ workOrderIssueId }) => {
        const workOrderIssue = filteredWorkOrderIssues.find(({ id }) => id === workOrderIssueId);

        const lastServiceVisit = _.last(
          _.orderBy(
            workOrderIssue?.serviceVisits || [],
            ({ windowStartAt }) => moment(windowStartAt).unix(),
            ['asc']
          )
        );

        append({
          attachments: {},
          serviceVisit: {
            connect: lastServiceVisit
              ? {
                  label: `${moment(lastServiceVisit?.windowStartAt).format(
                    'MMM. DD, YYYY @ hh:mm A'
                  )} - ${moment(lastServiceVisit?.windowEndAt).format('MMM. DD, YYYY @ hh:mm A')}`,
                  value: lastServiceVisit?.id,
                  hit: lastServiceVisit,
                }
              : null,
            create: null,
            mutation: lastServiceVisit
              ? {
                  label: 'Connect to Existing Service Visit',
                  value: 'CONNECT',
                }
              : null,
          },
          shippingAmount: workOrderIssue?.shippingAmount || null,
          taxAmount: workOrderIssue?.taxAmount || null,
          workOrderIssueId,
        });
      });
    } else if (checkedWorkOrderIssues?.length < fields?.length) {
      const diff = _.differenceBy(fields, checkedWorkOrderIssues, 'workOrderIssueId');

      diff?.forEach(({ workOrderIssueId }) => {
        const removeIndex = fields.findIndex(
          (field) => field.workOrderIssueId === workOrderIssueId
        );

        remove(removeIndex);
      });
    }
  }, [append, fields, filteredWorkOrderIssues, remove, watchCheckedWorkOrderIssues]);

  useDeepCompareEffect(() => {
    if (fieldCount.current === 0 && fields?.length === 1) {
      clearErrors(name);
    }

    if (fields?.length > fieldCount.current) {
      setExpandedField(_.get(_.last(fields || []), 'id'));
    }

    fieldCount.current = fields?.length || 0;
  }, [fields]);

  const handleChangeExpandedField = (fieldId) => (event, newFieldId) => {
    setExpandedField(newFieldId ? fieldId : false);
  };

  return (
    <>
      {errors?.[name] && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {_.get(errors, [name, 'message']) || 'One or More Service Issues Contain Errors'}
        </Alert>
      )}

      <div className="mb-24">
        {fields.map((item, index) => {
          let borderLeftColor = grey[600];

          if (_.isArray(_.get(errors, name)) && errors[name][index]) {
            // eslint-disable-next-line prefer-destructuring
            borderLeftColor = red[800];
          } else if (expandedField === item.id) {
            // eslint-disable-next-line prefer-destructuring
            borderLeftColor = lightBlue[600];
          }

          const workOrderIssue = filteredWorkOrderIssues.find(
            ({ id }) => id === item.workOrderIssueId
          );

          return (
            <Accordion
              expanded={expandedField === item.id}
              key={item.id}
              variant="outlined"
              onChange={handleChangeExpandedField(item.id)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ borderLeft: `4px solid ${borderLeftColor}` }}
              >
                <div className="flex flex-1 items-center">
                  {_.isArray(_.get(errors, name)) &&
                    _.isPlainObject(_.get(errors, name)[index]) &&
                    _.some(Object.keys(_.get(errors, name)[index]), (key) =>
                      _.isPlainObject(_.get(errors, name)[index][key])
                    ) && (
                      <FontAwesomeIcon
                        className="mr-8 text-red-800"
                        icon={faCircleXmark}
                        size="lg"
                      />
                    )}

                  <Typography>{workOrderIssue?.serviceIssue?.name}</Typography>
                </div>
              </AccordionSummary>

              <AccordionDetails sx={{ borderLeft: `4px solid ${borderLeftColor}` }}>
                <Typography className="mb-24">Confirm Shipping / Tax:</Typography>

                <div className="flex flex-1 flex-col sm:flex-row mb-24">
                  <Controller
                    control={control}
                    name={`${name}[${index}].shippingAmount`}
                    render={({ field }) => (
                      <CommonCurrencyField
                        {...field}
                        className="flex-1 mr-0 sm:mr-12 mb-12 sm:mb-0"
                        control={control}
                        disabled={false}
                        error={_.get(errors, `${name}[${index}].shippingAmount`)}
                        placeholder="Enter Shipping..."
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={`${name}[${index}].taxAmount`}
                    render={({ field }) => (
                      <CommonCurrencyField
                        {...field}
                        className="flex-1 ml-0 sm:ml-12 mt-12 sm:mt-0"
                        control={control}
                        disabled={false}
                        error={_.get(errors, `${name}[${index}].taxAmount`)}
                        placeholder="Enter Tax..."
                      />
                    )}
                  />
                </div>

                <Typography className="mb-24">Confirm Service Visit:</Typography>

                <Controller
                  control={control}
                  name={`${name}[${index}].serviceVisit.mutation`}
                  render={({ field }) => (
                    <div className="mb-24">
                      <CommonSelect
                        {...field}
                        error={_.get(errors, `${name}[${index}].serviceVisit.mutation`)}
                        isClearable
                        isDisabled={false}
                        isMulti={false}
                        options={[
                          {
                            label: 'Connect to Existing Service Visit',
                            value: 'CONNECT',
                          },
                          {
                            label: 'Create New Service Visit',
                            value: 'CREATE',
                          },
                        ]}
                        placeholder="Select Service Visit Connection..."
                        onChange={(params) => {
                          setValue(`${name}[${index}].serviceVisit.connect`, null);
                          setValue(`${name}[${index}].serviceVisit.create`, null);

                          field.onChange(params);
                        }}
                      />
                    </div>
                  )}
                />

                {item.serviceVisit?.mutation?.value === 'CONNECT' && (
                  <>
                    <Controller
                      control={control}
                      name={`${name}[${index}].serviceVisit.connect`}
                      render={({ field }) => (
                        <EntitySearchSelectField
                          {...field}
                          className="mb-24"
                          components={{ Option: ServiceVisitHitsListOption }}
                          error={_.get(errors, `${name}[${index}].serviceVisit.connect`)}
                          filter={[
                            `serviceTicket.id = '${serviceTicket?.id}'`,
                            `serviceTicket.status.enum = 'OPEN'`,
                          ]}
                          idField="id"
                          indexName="service_visits"
                          isClearable
                          isDisabled={false}
                          isMulti={false}
                          labelFn={(hit) =>
                            `${moment(hit?.windowStartAt).format(
                              'MMM. DD, YYYY @ hh:mm A'
                            )} - ${moment(hit?.windowEndAt).format('MMM. DD, YYYY @ hh:mm A')}`
                          }
                          placeholder="Select Service Visit..."
                          nameField="name"
                          skipInitialOptions
                          sort={['windowStartAtTimestamp:asc']}
                        />
                      )}
                    />
                  </>
                )}

                {item.serviceVisit?.mutation?.value === 'CREATE' && (
                  <>
                    <Controller
                      control={control}
                      name={`${name}[${index}].serviceVisit.create.window`}
                      render={({ field }) => (
                        <div className="mb-24">
                          <CommonDatepicker
                            {...field}
                            controls={['calendar', 'time']}
                            error={_.get(errors, `${name}[${index}].serviceVisit.create.window`)}
                            max={moment().endOf('day').toDate()}
                            min={moment(workOrderIssue?.createdAt).startOf('day').toDate()}
                            placeholder="Select an Arrival Window..."
                            select="range"
                            stepMinute={15}
                          />
                        </div>
                      )}
                    />
                  </>
                )}

                {workOrderIssue?.requiredServiceCompletionItems?.map((serviceCompletionItem) => (
                  <div key={serviceCompletionItem.id}>
                    <Divider sx={{ mb: 3 }} />

                    <WorkOrderIssueCompleteDialogWorkOrderIssueAttachments
                      existingAttachments={workOrderIssue?.serviceIssue?.attachments}
                      label={serviceCompletionItem.label}
                      name={`${name}[${index}].attachments.${serviceCompletionItem.id}`}
                      referenceAttachments={serviceCompletionItem.attachments}
                      serviceCompletionItem={serviceCompletionItem}
                    />
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </>
  );
};

export default WorkOrderIssueCompleteDialogWorkOrderIssues;
