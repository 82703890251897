import _ from '@lodash';
import moment from 'moment';
import numeral from 'numeral';

// eslint-disable-next-line import/prefer-default-export
export const buildIssueTemplateSearchScreenColumnDefs = ({ theme, user }) => [
  {
    groupId: 'issue-template',
    headerName: 'Issue Template',
    children: [
      {
        cellDataType: 'text',
        field: 'id',
        headerName: 'ID',
        sortable: true,
      },
      {
        cellDataType: 'number',
        field: 'createdAtTimestamp',
        headerName: 'Created',
        sortable: true,
        initialSort: 'desc',
        valueFormatter: ({ value }) =>
          value ? moment.unix(value).format('MMM. DD, YYYY @ hh:mm A') : undefined,
      },
      {
        cellDataType: 'text',
        field: 'name',
        headerName: 'Name',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'description',
        headerName: 'Description',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'status.name',
        headerName: 'Status',
        sortable: true,
      },
      {
        cellDataType: 'object',
        field: 'tags',
        headerName: 'Tags',
        sortable: false,
        valueFormatter: ({ value }) => {
          const tags = value
            ?.map((el) => el?.name)
            .filter((el) => el)
            .sort();

          return !_.isEmpty(tags) ? _.uniq(tags).join(', ') : undefined;
        },
      },
      {
        cellDataType: 'text',
        field: 'creator.name',
        headerName: 'Creator',
        sortable: true,
      },
    ],
  },
  {
    groupId: 'work-order-issue',
    headerName: 'Work Order Issue',
    children: [
      {
        cellDataType: 'text',
        field: 'serviceIssue.name',
        headerName: 'Name',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'workOrderIssue.budget.name',
        headerName: 'Budget',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'workOrderIssue.budgetProject.name',
        headerName: 'Budget Project',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'workOrderIssue.budgetSpecialProject.name',
        headerName: 'Budget Special Project',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'serviceIssue.commonTargetType.name',
        headerName: 'Target Type',
        sortable: true,
      },
      {
        cellDataType: 'object',
        field: 'serviceIssue.targetFacilityAreaTypes',
        headerName: 'Facility Area Types',
        sortable: false,
        valueFormatter: ({ value }) => {
          const facilityAreaTypes = value
            ?.map((el) => el?.name)
            .filter((el) => el)
            .sort();

          return !_.isEmpty(facilityAreaTypes) ? _.uniq(facilityAreaTypes).join(', ') : undefined;
        },
        valueGetter: ({ data }) =>
          data?.serviceIssue?.targetFacilityAreaTypes
            ?.map(({ facilityAreaType }) => facilityAreaType)
            ?.filter((el) => el) || undefined,
      },
      {
        cellDataType: 'text',
        field: 'serviceIssue.description',
        headerName: 'Description',
        sortable: true,
      },

      {
        cellDataType: 'object',
        field: 'serviceIssue.serviceIssueTags',
        headerName: 'Tags',
        sortable: false,
        valueFormatter: ({ value }) => {
          const serviceIssueTags = value
            ?.map((el) => el?.name)
            .filter((el) => el)
            .sort();

          return !_.isEmpty(serviceIssueTags) ? _.uniq(serviceIssueTags).join(', ') : undefined;
        },
        valueGetter: ({ data }) =>
          data?.serviceIssue?.serviceIssueTags?.filter((el) => el) || undefined,
      },
      {
        cellDataType: 'number',
        field: 'workOrderIssue.notToExceedAmount',
        headerName: 'NTE',
        sortable: true,
        valueFormatter: ({ value }) => (value ? numeral(value).format('$0,0.00') : '-'),
      },
    ],
  },
  {
    headerName: 'Sharing Rule',
    children: [
      {
        cellDataType: 'text',
        field: 'commonSharingRule.type.name',
        headerName: 'Type',
        sortable: true,
      },
      {
        cellDataType: 'text',
        field: 'commonSharingRule.subjectType.name',
        headerName: 'Subject Type',
        sortable: true,
      },
      {
        cellDataType: 'object',
        field: 'commonSharingRule.subjectTeams',
        headerName: 'Subject Teams',
        sortable: false,
        valueFormatter: ({ value }) => {
          const subjectTeams = value
            ?.map((el) => el?.name)
            .filter((el) => el)
            .sort();

          return !_.isEmpty(subjectTeams) ? _.uniq(subjectTeams).join(', ') : undefined;
        },
        valueGetter: ({ data }) =>
          data?.commonSharingRule?.subjectTeams?.map(({ team }) => team)?.filter((el) => el) ||
          undefined,
      },
    ],
  },
];
