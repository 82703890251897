import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const WORK_ORDER_ISSUE_SCHEDULE_SERVICE_VISIT_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment WorkOrderIssueScheduleServiceVisitDialogServiceTicketFragment on ServiceTicket {
    id
    phase {
      id
      enum
      name
    }
    serviceVisits {
      id
      windowEndAt
      windowStartAt
    }
    workOrder {
      id
      issues {
        id
        serviceIssue {
          id
          description
          name
          serviceResponseSeverity {
            id
            color
            commonTemporalDurationUnit {
              id
              enum
              name
            }
            name
            value
          }
        }
        serviceVisits {
          id
          windowEndAt
          windowStartAt
        }
        status {
          id
          enum
          name
        }
      }
    }
  }
`;
