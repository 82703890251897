import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const WORK_ORDER_ISSUE_COMPLETE_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment WorkOrderIssueCompleteDialogServiceTicketFragment on ServiceTicket {
    id
    phase {
      id
      enum
      name
    }
    workOrder {
      id
      issues {
        id
        createdAt
        actionItems {
          id
          totalAmount
        }
        requiredServiceCompletionItems {
          id
          attachments {
            id
            createdAt
            commonStoredImage {
              id
              name
              originalImageUrl
              thumbnailImageUrl
            }
            commonStoredPdf {
              id
              name
              originalPdfUrl
              thumbnailImageUrl
            }
            commonStoredType {
              id
              enum
              name
            }
          }
          label
          name
          targetType {
            id
            enum
            name
          }
        }
        serviceIssue {
          id
          attachments {
            id
            createdAt
            commonStoredImage {
              id
              commonStoredUpload {
                id
                key
                uploader {
                  id
                  name
                }
              }
              lightboxImageUrl
              name
              thumbnailImageUrl
            }
            commonStoredPdf {
              id
              commonStoredUpload {
                id
                key
                uploader {
                  id
                  name
                }
              }
              name
              originalPdfUrl
              thumbnailImageUrl
            }
            commonStoredType {
              id
              enum
              name
            }
            commonStoredVideo {
              id
              captionsFileUrl
              commonStoredUpload {
                id
                key
                uploader {
                  id
                  name
                }
              }
              name
              playlistFileUrl
              standardImageUrl
              thumbnailImageUrl
            }
          }
          description
          name
        }
        serviceVisits {
          id
          windowEndAt
          windowStartAt
        }
        shippingAmount
        status {
          id
          enum
          name
        }
        subtotalAmountCheck {
          id
          enum
          name
        }
        taxAmount
      }
    }
  }
`;
