import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const SERVICE_INVOICE_CREATE_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment ServiceInvoiceCreateDialogServiceTicketFragment on ServiceTicket {
    id
    phase {
      id
      enum
      name
    }
    workOrder {
      id
      issues {
        id
        budget {
          id
          name
        }
        budgetProject {
          id
          name
        }
        budgetSpecialProject {
          id
          name
        }
        serviceInvoice {
          id
          createdAt
          isApproved
          submittedAt
          vendorReferenceNumber
        }
        serviceIssue {
          id
          description
          name
        }
        status {
          id
          enum
          name
        }
      }
    }
  }
`;
