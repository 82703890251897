import FusePageSimple from '@fuse/core/FusePageSimple';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { Button } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import {
  EntitySearchAppProvider,
  EntitySearchDrawer,
  EntitySearchHeader,
  EntitySearchInfiniteHitsGrid,
  makeEntitySearchCommonClientAdapter,
  useEntitySearchApp,
} from 'app/shared-components/EntitySearch';
import { SegmentCreateDialog } from 'app/shared-components/Segment';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { selectUser } from 'app/store/userSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import { buildSegmentSearchScreenColumnDefs } from './columnDefs';
import { segmentSearchScreenFilterDefs } from './filterDefs';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderColor: theme.palette.divider,
    borderStyle: 'solid',
  },
  '& .FusePageSimple-sidebarContent': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-toolbar': {},
}));

const SegmentSearchScreenRoot = () => {
  const dispatch = useDispatch();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);
  const navigate = useNavigate();
  const { viewMode } = useEntitySearchApp();

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
  }, [isMobile]);

  return (
    <Root
      content={
        <SizeMe monitorHeight monitorWidth={false}>
          {({ size }) => (
            <div className="flex flex-row w-full h-full">
              {viewMode === 'grid' && (
                <EntitySearchInfiniteHitsGrid
                  onRowClicked={({ id }) => id && navigate(`/segments/view/${id}`)}
                  {...size}
                />
              )}
            </div>
          )}
        </SizeMe>
      }
      header={
        <EntitySearchHeader
          dateRangeAttribute="createdAtTimestamp"
          leftSidebarToggle={(ev) => {
            setLeftSidebarOpen(!leftSidebarOpen);
          }}
          rightSidebarToggle={null}
          rightToolbarContent={
            <>
              <Button
                color="success"
                size="small"
                variant="contained"
                onClick={() =>
                  dispatch(
                    openDialog({
                      children: (
                        <SegmentCreateDialog
                          onClose={() => dispatch(closeDialog())}
                          onSuccess={({ createSegment }) =>
                            navigate(`/segments/view/${createSegment.id}`)
                          }
                        />
                      ),
                      classes: { paper: 'w-full max-w-640 min-w-320 rounded-8' },
                      onClose: () => null,
                    })
                  )
                }
              >
                Create
              </Button>
            </>
          }
        />
      }
      leftSidebarContent={
        <EntitySearchDrawer
          dateRangeAttribute="createdAtTimestamp"
          filterDefs={segmentSearchScreenFilterDefs}
          title="Segments"
          toggleDrawer={(ev) => {
            setLeftSidebarOpen(!leftSidebarOpen);
          }}
        />
      }
      leftSidebarOnClose={() => {
        setLeftSidebarOpen(false);
      }}
      leftSidebarOpen={leftSidebarOpen}
      leftSidebarWidth={341}
      scroll="content"
    />
  );
};

const SegmentSearchScreen = () => {
  const theme = useTheme();
  const user = useSelector(selectUser);

  const segmentSearchScreenColumnDefs = buildSegmentSearchScreenColumnDefs({
    theme,
    user,
  });

  return (
    <EntitySearchAppProvider
      availableViewModes={['grid']}
      indexName="segments"
      initialColumnDefs={segmentSearchScreenColumnDefs}
      initialViewMode="grid"
      makeSearchAdapter={makeEntitySearchCommonClientAdapter}
      makeSearchAdapterParams={{ dateRangeAttribute: 'createdAtTimestamp' }}
      socketIoIncludeSockets={['team', 'user']}
      socketIoRoomId="aggregate:segments"
    >
      <SegmentSearchScreenRoot />
    </EntitySearchAppProvider>
  );
};

export default SegmentSearchScreen;
