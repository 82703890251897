import _ from '@lodash';
import {
  jwtService,
  JWT_SERVICE_EVENTS,
  JWT_SERVICE_TOKEN_TYPES,
} from 'app/providers/auth/services';
import { selectUser } from 'app/store/userSlice';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { socketIoClient } from './clients';

const {
  REACT_APP_ULTRAVIOLET_INTERCHANGE_BASE_URL,
  REACT_APP_ULTRAVIOLET_INTERCHANGE_ORGANIZATION_NAMESPACE,
  REACT_APP_ULTRAVIOLET_INTERCHANGE_TEAM_NAMESPACE,
  REACT_APP_ULTRAVIOLET_INTERCHANGE_USER_NAMESPACE,
} = process.env;

const SocketIoContext = createContext();

const SocketIoProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null);
  const [connectedSockets, setConnectedSockets] = useState({});
  const user = useSelector(selectUser);

  const joinRoom = useCallback(
    ({ namespace, roomId, socketId }) => {
      if (jwtService.isTokenValid(accessToken)) {
        socketIoClient.joinRoom({ accessToken, namespace, roomId, socketId });
      }
    },
    [accessToken]
  );

  const leaveRoom = useCallback(
    ({ namespace, roomId, socketId }) => {
      if (jwtService.isTokenValid(accessToken)) {
        socketIoClient.leaveRoom({ accessToken, namespace, roomId, socketId });
      }
    },
    [accessToken]
  );

  const organizationSocket = useMemo(
    () =>
      accessToken && user?.data?.organization.id
        ? io(
            [
              REACT_APP_ULTRAVIOLET_INTERCHANGE_BASE_URL,
              REACT_APP_ULTRAVIOLET_INTERCHANGE_ORGANIZATION_NAMESPACE,
              user?.data?.organization.id,
            ].join('/'),
            {
              autoConnect: false,
              auth: {
                token: accessToken,
              },
              transports: ['websocket'],
            }
          )
        : null,
    [accessToken, user?.data?.organization.id]
  );

  const teamSocket = useMemo(
    () =>
      accessToken && user?.data?.team.id
        ? io(
            [
              REACT_APP_ULTRAVIOLET_INTERCHANGE_BASE_URL,
              REACT_APP_ULTRAVIOLET_INTERCHANGE_TEAM_NAMESPACE,
              user?.data?.team.id,
            ].join('/'),
            {
              autoConnect: false,
              auth: {
                token: accessToken,
              },
              transports: ['websocket'],
            }
          )
        : null,
    [accessToken, user?.data?.team.id]
  );

  const userSocket = useMemo(
    () =>
      accessToken
        ? io(
            [
              REACT_APP_ULTRAVIOLET_INTERCHANGE_BASE_URL,
              REACT_APP_ULTRAVIOLET_INTERCHANGE_USER_NAMESPACE,
            ].join('/'),
            {
              autoConnect: false,
              auth: {
                token: accessToken,
              },
              transports: ['websocket'],
            }
          )
        : null,
    [accessToken]
  );

  useEffect(() => {
    jwtService.on(
      [
        JWT_SERVICE_EVENTS.ON_COMPLETE_SIGN_IN,
        JWT_SERVICE_EVENTS.ON_REASSIGN_SESSION,
        JWT_SERVICE_EVENTS.ON_REFRESH_SESSION,
      ],
      ({ data }) => {
        if (_.get(data, JWT_SERVICE_TOKEN_TYPES.ACCESS_TOKEN)) {
          setAccessToken(_.get(data, JWT_SERVICE_TOKEN_TYPES.ACCESS_TOKEN));
        }
      }
    );

    jwtService.on(
      [JWT_SERVICE_EVENTS.ON_CLIENT_RESPONSE_ERROR, JWT_SERVICE_EVENTS.ON_REVOKE_SESSION],
      () => setAccessToken(null)
    );
  }, []);

  useEffect(() => {
    if (organizationSocket && teamSocket && userSocket) {
      organizationSocket.connect();
      teamSocket.connect();
      userSocket.connect();
    }

    return () => {
      organizationSocket?.disconnect();
      teamSocket?.disconnect();
      userSocket?.disconnect();
    };
  }, [organizationSocket, teamSocket, userSocket]);

  return (
    <SocketIoContext.Provider
      value={{
        connectedSockets,
        joinRoom,
        leaveRoom,
        organizationSocket,
        setConnectedSockets,
        teamSocket,
        userSocket,
      }}
    >
      {children}
    </SocketIoContext.Provider>
  );
};

export const __SocketIoContext = SocketIoContext;
export default SocketIoProvider;
