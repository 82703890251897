import { faCircleCheck, faCircleXmark, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { useDeepCompareEffect } from '@fuse/hooks';
import _ from '@lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Checkbox,
  FormControlLabel,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import { CommonDatepicker } from 'app/shared-components/Common';
import { EntitySearchSelectField } from 'app/shared-components/EntitySearch';
import { ProgramSelectableFacilitySelectField } from 'app/shared-components/ProgramSelectableFacility';
import { ServiceProvisionHitsListOption } from 'app/shared-components/ServiceProvision';
import { ServiceResponseSeveritySelectField } from 'app/shared-components/ServiceResponseSeverity';
import { selectUser } from 'app/store/userSlice';
import moment from 'moment';
import { useRef, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

const ProgramCampaignCreateDialogFacilities = ({
  defaultServiceResponseSeverity,
  issueTemplatesFieldName,
  name,
  program = {},
  selectableFacilitiesFieldName,
}) => {
  const [expandedField, setExpandedField] = useState(null);
  const fieldCount = useRef(0);
  const [isSelectableFacilitiesMenuOpen, setIsSelectableFacilitiesMenuOpen] = useState(false);
  const [selectableFacilityOptions, setSelectableFacilityOptions] = useState([]);
  const user = useSelector(selectUser);

  const {
    clearErrors,
    control,
    formState: { errors },
    setValue,
    trigger,
    watch,
  } = useFormContext();

  const { append, fields, insert, move, prepend, remove, replace, swap, update } = useFieldArray({
    control,
    name,
  });

  const watchFields = watch(name);
  const watchIssueTemplates = watch(issueTemplatesFieldName);
  const watchSelectableFacilities = watch(selectableFacilitiesFieldName);

  useDeepCompareEffect(() => {
    if (fieldCount.current === 0 && fields?.length === 1) {
      clearErrors(name);
    }

    fieldCount.current = fields?.length || 0;
  }, [fields]);

  useDeepCompareEffect(() => {
    if (!isSelectableFacilitiesMenuOpen) {
      const _fields = _.orderBy(
        watchSelectableFacilities?.map(({ result }) => {
          const existingField = watchFields?.find(
            (watchField) => watchField?.facility?.value === result?.facility.id
          );

          const matchingServiceProvision = result?.matchingServiceProvisions?.length
            ? _.first(result?.matchingServiceProvisions || [])
            : null;

          return (
            existingField || {
              completeBy:
                (defaultServiceResponseSeverity &&
                  moment()
                    .add(
                      defaultServiceResponseSeverity?.result?.value,
                      defaultServiceResponseSeverity?.result?.commonTemporalDurationUnit?.enum
                    )
                    .format()) ??
                null,
              facility: {
                label: result?.facility.name,
                value: result?.facility.id,
                result: result?.facility,
              },
              isNotToExceedAmountZeroedOut: false,
              isRequestForQuote: false,
              serviceProvision: matchingServiceProvision
                ? {
                    label: matchingServiceProvision?.name,
                    value: matchingServiceProvision?.id,
                    hit: matchingServiceProvision,
                  }
                : null,
              serviceResponseSeverity: defaultServiceResponseSeverity || null,
            }
          );
        }),
        'facility.result.name'
      );

      replace(_fields);
      trigger([name]);
    }
  }, [isSelectableFacilitiesMenuOpen, watchSelectableFacilities || []]);

  const handleChangeExpandedField = (fieldId) => (event, newFieldId) => {
    setExpandedField(newFieldId ? fieldId : false);
  };

  return (
    <>
      <Controller
        control={control}
        name={selectableFacilitiesFieldName}
        render={({ field }) => (
          <ProgramSelectableFacilitySelectField
            {...field}
            className="mb-24"
            error={_.get(errors, selectableFacilitiesFieldName)}
            filters={{
              program: { id: program?.id },
              issueTemplates: watchIssueTemplates?.map(({ value }) => ({ id: value })),
            }}
            isClearable
            isDisabled={_.isEmpty(watchIssueTemplates)}
            isMulti
            placeholder="Select Campaign Facilities..."
            onMenuClose={() => setIsSelectableFacilitiesMenuOpen(false)}
            onMenuOpen={() => setIsSelectableFacilitiesMenuOpen(true)}
          />
        )}
      />

      {errors?.[name] && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {_.get(errors, [name, 'message']) || 'One or More Campaign Facilities Contain Errors'}
        </Alert>
      )}

      {_.isEmpty(fields) && !errors?.[name] && (
        <Alert severity="info" sx={{ mb: 3 }}>
          No Campaign Facilities Selected
        </Alert>
      )}

      {!_.isEmpty(fields) && (
        <div className="mb-24">
          {['FRANCHISOR', 'OPERATOR'].includes(user?.data?.organization?.type.enum) && (
            <div className="flex items-center justify-end mb-12">
              {[
                {
                  fieldName: 'isNotToExceedAmountZeroedOut',
                  label: 'Zero Out',
                },
                {
                  fieldName: 'isRequestForQuote',
                  label: 'RFQ',
                },
              ].map(({ fieldName, label }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!_.some(watchFields || [], (watchField) => !watchField[fieldName])}
                      color="info"
                      indeterminate={
                        _.some(watchFields || [], (watchField) => !watchField[fieldName]) &&
                        _.some(watchFields || [], (watchField) => watchField[fieldName])
                      }
                      onChange={() => {
                        if (!_.some(watchFields || [], (watchField) => !watchField[fieldName])) {
                          fields.forEach((item, index) => {
                            setValue(`${name}[${index}].${fieldName}`, false, {
                              shouldDirty: true,
                              shouldTouch: true,
                              shouldValidate: true,
                            });
                          });
                        } else {
                          fields.forEach((item, index) => {
                            setValue(`${name}[${index}].${fieldName}`, true, {
                              shouldDirty: true,
                              shouldTouch: true,
                              shouldValidate: true,
                            });
                          });
                        }
                      }}
                    />
                  }
                  key={`${fieldName}-checkbox`}
                  label={label}
                />
              ))}
            </div>
          )}

          {fields.map((item, index) => {
            if (!watchFields?.[index]) return null;

            const borderLeftColor =
              _.isArray(_.get(errors, name)) && errors[name][index] ? red[800] : green[800];

            const watchCompleteBy = watchFields?.[index]?.completeBy;
            const watchFacility = watchFields?.[index]?.facility;
            const watchIsNotToExceedAmountZeroedOut =
              watchFields?.[index]?.isNotToExceedAmountZeroedOut;
            const watchIsRequestForQuote = watchFields?.[index]?.isRequestForQuote;
            const watchServiceProvision = watchFields?.[index]?.serviceProvision;
            const watchServiceResponseSeverity = watchFields?.[index]?.serviceResponseSeverity;

            return (
              <Accordion
                expanded={expandedField === item.id}
                key={item.id}
                TransitionProps={{ unmountOnExit: true }}
                variant="outlined"
                onChange={handleChangeExpandedField(item.id)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ borderLeft: `4px solid ${borderLeftColor}` }}
                >
                  <div className="flex flex-1 items-start">
                    {_.isArray(_.get(errors, name)) &&
                    _.isPlainObject(_.get(errors, name)[index]) &&
                    _.some(Object.keys(_.get(errors, name)[index]), (key) =>
                      _.isPlainObject(_.get(errors, name)[index][key])
                    ) ? (
                      <FontAwesomeIcon
                        className="mt-3 mr-8 text-red-800"
                        icon={faCircleXmark}
                        size="lg"
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="mt-3 mr-8 text-green-800"
                        icon={faCircleCheck}
                        size="lg"
                      />
                    )}

                    <div className="flex flex-col">
                      <Typography className="font-500">
                        {`${watchFacility?.result?.franchiseNumber} - ${watchFacility?.result?.name}`}
                      </Typography>

                      <Typography className="text-13">
                        Vendor:{' '}
                        <span className="font-500">
                          {watchServiceProvision?.hit?.organizationConnection.vendor.name ||
                            'Not Set'}
                        </span>
                      </Typography>

                      <Typography className="text-13">
                        Complete By:{' '}
                        <span className="font-500">
                          {watchCompleteBy && watchServiceResponseSeverity
                            ? `${moment(watchCompleteBy).format('MMMM DD, YYYY')} (${
                                watchServiceResponseSeverity.result?.name
                              })`
                            : 'Not Set'}
                        </span>
                      </Typography>
                    </div>
                  </div>

                  <div className="flex items-center">
                    {['FRANCHISOR', 'OPERATOR'].includes(user?.data?.organization?.type.enum) && (
                      <>
                        <Tooltip placement="bottom" title="Zero Out">
                          <IconButton
                            color={watchIsNotToExceedAmountZeroedOut ? 'info' : 'default'}
                            sx={{ mr: 2, padding: 0, width: '24px', height: '24px' }}
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();

                              setValue(
                                `${name}[${index}].isNotToExceedAmountZeroedOut`,
                                !watchIsNotToExceedAmountZeroedOut
                              );
                            }}
                          >
                            <FuseSvgIcon>material-outline:price_change</FuseSvgIcon>
                          </IconButton>
                        </Tooltip>

                        <Tooltip placement="bottom" title="RFQ">
                          <IconButton
                            color={watchIsRequestForQuote ? 'info' : 'default'}
                            sx={{ mr: 2, padding: 0, width: '24px', height: '24px' }}
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();

                              setValue(
                                `${name}[${index}].isRequestForQuote`,
                                !watchIsRequestForQuote
                              );
                            }}
                          >
                            <FuseSvgIcon>material-outline:note_alt</FuseSvgIcon>
                          </IconButton>
                        </Tooltip>

                        <div className="mr-12 py-8 h-full">
                          <div className="w-1 h-full bg-grey-600" />
                        </div>
                      </>
                    )}

                    <Tooltip placement="bottom" title="Remove">
                      <IconButton
                        sx={{ mr: 1, padding: 0, width: '24px', height: '24px' }}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();

                          setValue(
                            selectableFacilitiesFieldName,
                            watchSelectableFacilities?.filter(
                              ({ result }) => result?.facility?.id !== watchFacility?.value
                            )
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashCan} size="xs" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </AccordionSummary>

                <AccordionDetails sx={{ borderLeft: `4px solid ${borderLeftColor}` }}>
                  <Controller
                    control={control}
                    name={`${name}[${index}].serviceProvision`}
                    render={({ field }) => (
                      <EntitySearchSelectField
                        {...field}
                        className="mb-24"
                        components={{ Option: ServiceProvisionHitsListOption }}
                        error={_.get(errors, `${name}[${index}].serviceProvision`)}
                        // ROADMAP: Filter if Vendor is Viewing
                        filter={[
                          `facilities.id = '${watchFacility?.value}'`,
                          // ROADMAP: Implement
                          // "status.enum = 'active'",
                        ]}
                        idField="id"
                        indexName="service_provisions"
                        isClearable
                        isDisabled={false}
                        isMulti={false}
                        placeholder="Select Service Provision ..."
                        nameField="name"
                        sort={['name:asc']}
                      />
                    )}
                  />

                  <div className="flex flex-1 flex-col sm:flex-row mb-24">
                    <Controller
                      control={control}
                      name={`${name}[${index}].serviceResponseSeverity`}
                      render={({ field }) => (
                        <ServiceResponseSeveritySelectField
                          {...field}
                          className="flex-1 mr-0 sm:mr-12 mb-12 sm:mb-0"
                          error={_.get(errors, `${name}[${index}].serviceResponseSeverity`)}
                          filters={null}
                          isClearable
                          isDisabled={false}
                          isMulti={false}
                          placeholder="Select Response Severity..."
                          onChange={(params) => {
                            setValue(
                              `${name}[${index}].completeBy`,
                              params
                                ? moment()
                                    .add(
                                      params?.result.value,
                                      params?.result.commonTemporalDurationUnit.enum
                                    )
                                    .format()
                                : null,
                              { shouldValidate: true }
                            );

                            field.onChange(params);
                          }}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name={`${name}[${index}].completeBy`}
                      render={({ field }) => (
                        <div className="flex-1 ml-0 sm:ml-12 mt-12 sm:mt-0">
                          <CommonDatepicker
                            {...field}
                            controls={['calendar']}
                            disabled={!watchFields?.[index]?.serviceResponseSeverity}
                            error={_.get(errors, `${name}[${index}].completeBy`)}
                            min={moment().add(1, 'day').startOf('day')}
                            placeholder="Select Complete By Date..."
                            select="date"
                          />
                        </div>
                      )}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      )}
    </>
  );
};

export default ProgramCampaignCreateDialogFacilities;
