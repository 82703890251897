import { forwardRef, useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import _ from '@lodash';
import { ListItem, ListItemText, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { components } from 'react-select';
import { CommonSelect } from 'app/shared-components/Common/CommonSelect';
import { showMessage } from 'app/store/fuse/messageSlice';

const CommonAddressSelectOption = (props) => {
  const commonAddress = props.data?.result;

  const item = useMemo(() => {
    if (!commonAddress) return null;

    return (
      <ListItem component="div" dense>
        <ListItemText
          disableTypography
          primary={<Typography variant="body2">{commonAddress.name}</Typography>}
        />
      </ListItem>
    );
  }, [commonAddress]);

  return <components.Option {...props}>{item}</components.Option>;
};

const CommonAddressSelectField = forwardRef(({ className, filters, ...SelectProps }, rootRef) => {
  const dispatch = useDispatch();
  const [filtersCache, setFiltersCache] = useState(null);
  const [options, setOptions] = useState(null);

  useQuery(
    gql`
      query GetCommonAddressSelectFieldData($where: CommonAddressWhereInput!) {
        commonAddresses(where: $where) {
          id
          address1
          address2
          city
          country
          owner {
            id
            name
          }
          postalZipCode
          region
        }
      }
    `,
    {
      skip: _.isEqual(filters, filtersCache) && !!options,
      variables: { where: { ...(filters || {}) } },
      onCompleted: ({ commonAddresses }) => {
        setFiltersCache(filters);

        setOptions(
          commonAddresses?.map((result) => ({
            result,
            label: `${result?.address1}, ${result?.city}, ${result?.region} ${result?.postalZipCode}`,
            value: result?.id,
          }))
        );
      },
      onError: ({ message }) =>
        dispatch(
          showMessage({ message: message || 'An Unknown Error Occurred!', variant: 'error' })
        ),
    }
  );

  return (
    <div className={className} ref={rootRef}>
      <CommonSelect
        {...SelectProps}
        // ROADMAP: Implement
        // components={{ Option: CommonAddressSelectOption }}
        options={options}
      />
    </div>
  );
});

export default CommonAddressSelectField;
