import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import numeral from 'numeral';
import { useMemo } from 'react';
import { components } from 'react-select';

const IssueTemplateHitsListOption = (props) => {
  // ROADMAP: Switch to `props.data` when `EntitySearchSelectFieldV2` is Fully Deployed
  const issueTemplate = props.data?.hit || props.data;

  const option = useMemo(() => {
    if (!issueTemplate) return null;

    return (
      <ListItem component="div" dense>
        <ListItemAvatar>
          <Avatar src={null}>
            <FontAwesomeIcon icon={faCopy} />
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          disableTypography
          primary={
            <Typography variant="body2">{`${issueTemplate.name} (NTE: ${numeral(
              issueTemplate.workOrderIssue.notToExceedAmount
            ).format('$0,0.00')})`}</Typography>
          }
          secondary={
            <div className="flex flex-1">
              <div className="flex items-center">
                <Typography variant="body2">
                  {issueTemplate.description || 'No Description Set'}
                </Typography>
              </div>
            </div>
          }
        />
      </ListItem>
    );
  }, [issueTemplate]);

  return <components.Option {...props}>{option}</components.Option>;
};

export default IssueTemplateHitsListOption;
