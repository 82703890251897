import { gql, useMutation } from '@apollo/client';
import _ from '@lodash';
import { Alert, AlertTitle, Button, Divider } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { CommonCircularProgressWithLabel } from 'app/shared-components/Common/CommonCircularProgress';
import { WorkOrderIssueScheduleSiteVisitDialog } from 'app/shared-components/WorkOrderIssue';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { WORK_ORDER_VIEW_APP_SERVICE_TICKET_FRAGMENT } from '../fragments';

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.mode === 'light' ? grey[400] : grey[800],
}));

const WorkOrderViewAppSiteVisitPhaseBar = ({
  consumer,
  isApprovalPhaseCompleted,
  phase,
  serviceTicketId,
  user,
  vendor,
  workOrder,
}) => {
  const dispatch = useDispatch();

  const [
    completeServiceTicketSiteVisitPhase,
    { loading: completeServiceTicketSiteVisitPhaseLoading },
  ] = useMutation(
    gql`
      mutation CompleteServiceTicketSiteVisitPhase($where: ServiceTicketWhereUniqueInput!) {
        completeServiceTicketSiteVisitPhase(where: $where) {
          id
          ...WorkOrderViewAppServiceTicketFragment
        }
      }
      ${WORK_ORDER_VIEW_APP_SERVICE_TICKET_FRAGMENT}
    `,
    {
      onCompleted: (data) => {
        dispatch(closeDialog());
        dispatch(
          showMessage({
            message: 'Ticket Successfully Confirmed',
            variant: 'success',
          })
        );
      },
      onError: (error) => {
        dispatch(showMessage({ message: 'Failed Confirming Ticket', variant: 'error' }));
      },
    }
  );

  const [recallServiceTicketSiteVisitPhase, { loading: recallServiceTicketSiteVisitPhaseLoading }] =
    useMutation(
      gql`
        mutation RecallServiceTicketSiteVisitPhase($where: ServiceTicketWhereUniqueInput!) {
          recallServiceTicketSiteVisitPhase(where: $where) {
            id
            ...WorkOrderViewAppServiceTicketFragment
          }
        }
        ${WORK_ORDER_VIEW_APP_SERVICE_TICKET_FRAGMENT}
      `,
      {
        onCompleted: (data) => {
          dispatch(closeDialog());
          dispatch(
            showMessage({
              message: 'Ticket Successfully Recalled',
              variant: 'success',
            })
          );
        },
        onError: (error) => {
          dispatch(showMessage({ message: 'Failed Recalling Ticket', variant: 'error' }));
        },
      }
    );

  const consumerCanRecall = useMemo(
    () =>
      !isApprovalPhaseCompleted &&
      workOrder?.issues?.length &&
      !_.some(
        workOrder?.issues || [],
        ({ status }) => !['CANCELLED', 'PENDING'].includes(status?.enum)
      ),
    [isApprovalPhaseCompleted, workOrder?.issues]
  );

  const loading = useMemo(
    () =>
      [
        completeServiceTicketSiteVisitPhaseLoading,
        recallServiceTicketSiteVisitPhaseLoading,
      ].includes(true),
    [completeServiceTicketSiteVisitPhaseLoading, recallServiceTicketSiteVisitPhaseLoading]
  );

  const scheduledWorkOrderIssues = useMemo(
    () =>
      workOrder?.issues?.filter(({ status }) => ['CANCELLED', 'SCHEDULED'].includes(status?.enum)),
    [workOrder?.issues]
  );

  const scheduledPerc = workOrder?.issues?.length
    ? Math.round(((scheduledWorkOrderIssues || []).length / workOrder?.issues.length) * 100)
    : 0;

  return (
    <>
      {phase.enum === 'SITE_VISIT' && consumer?.id === user?.data?.organization?.id && (
        <>
          {consumerCanRecall ? (
            <Alert
              square
              action={
                <Button
                  className="mr-8"
                  disabled={loading}
                  size="small"
                  variant="outlined"
                  onClick={() =>
                    recallServiceTicketSiteVisitPhase({
                      variables: { where: { id: serviceTicketId } },
                    })
                  }
                >
                  Recall Request
                </Button>
              }
              classes={{ action: 'items-center' }}
              severity="info"
            >
              <AlertTitle>Recall Request For Service</AlertTitle>
              Cancel schedule site visit(s) task and return ticket to draft.
            </Alert>
          ) : (
            <Alert
              square
              action={
                <div className="flex items-center">
                  <div className="mr-24">
                    <CommonCircularProgressWithLabel value={scheduledPerc} />
                  </div>
                </div>
              }
              classes={{ action: 'items-center' }}
              severity="info"
            >
              <AlertTitle>Site Visit Scheduling Underway</AlertTitle>
              One or more service issues have been scheduled for a site visit.
            </Alert>
          )}

          <StyledDivider />
        </>
      )}

      {phase.enum === 'SITE_VISIT' && vendor?.id === user?.data?.organization?.id && (
        <>
          <Alert
            square
            action={
              <div className="flex items-center">
                <div className="mr-24">
                  <CommonCircularProgressWithLabel value={scheduledPerc} />
                </div>

                <Button
                  className="mr-8"
                  disabled={loading}
                  size="small"
                  variant="outlined"
                  onClick={() =>
                    dispatch(
                      openDialog({
                        children: (
                          <WorkOrderIssueScheduleSiteVisitDialog
                            defaultWorkOrderIssueId={null}
                            serviceTicketId={serviceTicketId}
                            onClose={() => dispatch(closeDialog())}
                          />
                        ),
                        classes: {
                          paper: 'w-full max-w-640 min-w-320 rounded-8',
                        },
                      })
                    )
                  }
                >
                  Schedule
                </Button>

                <Button
                  className="mr-8"
                  disabled={loading}
                  size="small"
                  variant="outlined"
                  onClick={() =>
                    completeServiceTicketSiteVisitPhase({
                      variables: { where: { id: serviceTicketId } },
                    })
                  }
                >
                  Next
                </Button>
              </div>
            }
            classes={{ action: 'items-center' }}
            severity="warning"
          >
            <AlertTitle>Schedule Site Visit(s)</AlertTitle>
            Complete scheduling site visit(s) for each service issue where required, and confirm
            once complete.
          </Alert>

          <StyledDivider />
        </>
      )}
    </>
  );
};

export default WorkOrderViewAppSiteVisitPhaseBar;
