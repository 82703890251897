import { useLazyQuery, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { grey } from '@mui/material/colors';
import { CommonDatepicker } from 'app/shared-components/Common';
import { ServiceResponseSeveritySelectField } from 'app/shared-components/ServiceResponseSeverity';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import {
  FETCH_WORK_ORDER_ISSUE_RESPONSE_SEVERITY_UPDATE_DIALOG_DATA,
  UPDATE_WORK_ORDER_ISSUE_RESPONSE_SEVERITY,
} from './queries';

const defaultValues = {
  completeBy: null,
  serviceResponseSeverity: null,
};

const schema = yup.object().shape({
  completeBy: yup.date().nullable().required('A complete by date must be provided'),
  serviceResponseSeverity: yup
    .object()
    .nullable()
    .required('A response severity must be provided.'),
});

const WorkOrderIssueResponseSeverityUpdateDialog = ({
  serviceTicketId,
  workOrderIssueId,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(false);
  const { clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods } =
    useForm({
      defaultValues,
      mode: 'onChange',
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;

  const watchFields = watch();

  const [
    fetchWorkOrderIssueResponseSeverityUpdateDialogData,
    {
      data: workOrderIssueResponseSeverityUpdateDialogData,
      loading: workOrderIssueResponseSeverityUpdateDialogLoading,
      refetch: workOrderIssueResponseSeverityUpdateDialogRefetch,
    },
  ] = useLazyQuery(FETCH_WORK_ORDER_ISSUE_RESPONSE_SEVERITY_UPDATE_DIALOG_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: () => setFetched(true),
    onError: (error) => {
      dispatch(
        showMessage({
          message: 'Failed Fetching Work Order Issue Data',
          variant: 'error',
        })
      );
    },
  });

  const [
    updateWorkOrderIssueResponseSeverity,
    { loading: updateWorkOrderIssueResponseSeverityLoading },
  ] = useMutation(UPDATE_WORK_ORDER_ISSUE_RESPONSE_SEVERITY, {
    onCompleted: (data) => {
      dispatch(closeDialog());
      dispatch(
        showMessage({
          message: 'Work Order Issue Successfully Updated',
          variant: 'success',
        })
      );
    },
    onError: (error) => {
      dispatch(showMessage({ message: 'Failed Updating Work Order Issue', variant: 'error' }));
    },
  });

  const loading = useMemo(
    () =>
      [
        updateWorkOrderIssueResponseSeverityLoading,
        workOrderIssueResponseSeverityUpdateDialogLoading,
      ].includes(true),
    [updateWorkOrderIssueResponseSeverityLoading, workOrderIssueResponseSeverityUpdateDialogLoading]
  );

  const workOrderIssue = useMemo(
    () => workOrderIssueResponseSeverityUpdateDialogData?.workOrderIssue,
    [workOrderIssueResponseSeverityUpdateDialogData?.workOrderIssue]
  );

  useEffect(() => {
    if (workOrderIssue) {
      const formData = {
        completeBy: workOrderIssue.completeBy,
        serviceResponseSeverity: {
          result: workOrderIssue?.serviceIssue?.serviceResponseSeverity,
          label: workOrderIssue?.serviceIssue?.serviceResponseSeverity?.name,
          value: workOrderIssue?.serviceIssue?.serviceResponseSeverity?.id,
        },
      };

      reset(formData);
    }
  }, [reset, workOrderIssue]);

  useEffect(() => {
    if (workOrderIssueId) {
      fetchWorkOrderIssueResponseSeverityUpdateDialogData({
        variables: { where: { id: workOrderIssueId } },
      });
    }
  }, [fetchWorkOrderIssueResponseSeverityUpdateDialogData, workOrderIssueId]);

  const onSubmit = async (data) => {
    try {
      await updateWorkOrderIssueResponseSeverity({
        variables: {
          where: { id: serviceTicketId },
          data: {
            id: workOrderIssue?.id,
            completeBy: data.completeBy && moment(data.completeBy).format(),
            serviceResponseSeverity: { id: data.serviceResponseSeverity?.value },
          },
        },
      });
    } catch (err) {
      //
    }
  };

  return (
    <>
      <DialogTitle sx={{ borderBottom: `1px solid ${grey[400]}` }}>
        Update Work Order Issue
      </DialogTitle>

      <DialogContent sx={{ pb: 0 }}>
        <FormProvider
          {...{ clearErrors, control, formState, handleSubmit, reset, setValue, watch, ...methods }}
        >
          <form
            className="w-full pt-20"
            id="work-order-issue-response-severity-update-form"
            name="work-order-issue-response-severity-update-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-1 flex-col sm:flex-row mb-24">
              <Controller
                control={control}
                name="serviceResponseSeverity"
                render={({ field }) => (
                  <ServiceResponseSeveritySelectField
                    {...field}
                    className="flex-1 mr-0 sm:mr-12 mb-12 sm:mb-0"
                    error={errors?.serviceResponseSeverity}
                    filters={null}
                    isClearable
                    isDisabled={false}
                    isMulti={false}
                    placeholder="Select Response Severity..."
                    onChange={(params) => {
                      setValue(
                        'completeBy',
                        params
                          ? moment()
                              .add(
                                params?.result.value,
                                params?.result.commonTemporalDurationUnit.enum
                              )
                              .format()
                          : null,
                        { shouldValidate: true }
                      );

                      field.onChange(params);
                    }}
                  />
                )}
              />

              <Controller
                control={control}
                name="completeBy"
                render={({ field }) => (
                  <div className="flex-1 ml-0 sm:ml-12 mt-12 sm:mt-0">
                    <CommonDatepicker
                      {...field}
                      controls={['calendar']}
                      disabled={!watchFields?.serviceResponseSeverity}
                      error={errors?.completeBy}
                      min={moment().add(1, 'day').startOf('day')}
                      placeholder="Select Complete By Date..."
                      select="date"
                    />
                  </div>
                )}
              />
            </div>
          </form>
        </FormProvider>
      </DialogContent>

      <DialogActions sx={{ padding: 2, borderTop: `1px solid ${grey[400]}` }}>
        <Button color="primary" onClick={onClose} variant="contained">
          Cancel
        </Button>

        <Button
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid || loading}
          form="work-order-issue-response-severity-update-form"
          type="submit"
          variant="contained"
        >
          Update
        </Button>
      </DialogActions>
    </>
  );
};

export default WorkOrderIssueResponseSeverityUpdateDialog;
