import FuseLoading from '@fuse/core/FuseLoading';
import FusePageCarded from '@fuse/core/FusePageCarded';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  buildServiceCompletionItemSearchScreenColumnDefs,
  serviceCompletionItemSearchScreenFilterDefs,
} from 'app/screens/ServiceCompletionItemSearchScreen';
import { ServiceCompletionItemCreateDialog } from 'app/shared-components/ServiceCompletionItem';
import {
  EntitySearchAppProvider,
  EntitySearchDrawer,
  EntitySearchHeader,
  EntitySearchInfiniteHitsGrid,
  EntitySearchToggleRefinement,
  makeEntitySearchCommonClientAdapter,
} from 'app/shared-components/EntitySearch';
import { closeDialog, openDialog } from 'app/store/fuse/dialogSlice';
import { selectUser } from 'app/store/userSlice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import BudgetProjectViewScreenCompletionItemActiveCellRenderer from './BudgetProjectViewScreenCompletionItemActiveCellRenderer';
import BudgetProjectViewScreenHeader from './BudgetProjectViewScreenHeader';

const BudgetProjectViewScreenCompletionItemsTabRoot = ({ budgetProject }) => {
  const dispatch = useDispatch();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [leftSidebarOpen, setLeftSidebarOpen] = useState(!isMobile);
  const navigate = useNavigate();
  const params = useParams();
  const user = useSelector(selectUser);

  useEffect(() => {
    setLeftSidebarOpen(!isMobile);
  }, [isMobile]);

  const basePath = useMemo(
    () => `${params?.sectionName}/${params?.viewName}`,
    [params?.sectionName, params?.viewName]
  );

  const canCreateServiceCompletionItem = useMemo(
    () =>
      Boolean(
        user?.data?.team?.isOrganizationAdmin === true &&
          ['FRANCHISOR', 'OPERATOR'].includes(user?.data?.organization?.type.enum)
      ),
    [user]
  );

  const leftSidebarWidth = useMemo(() => 341, []);

  const handleLeftSidebarClose = useCallback(() => setLeftSidebarOpen(false), []);

  const handleLeftSidebarToggle = useCallback(
    () => setLeftSidebarOpen(!leftSidebarOpen),
    [leftSidebarOpen]
  );

  return (
    <FusePageCarded
      content={
        <>
          {!budgetProject ? (
            <FuseLoading className="h-full" />
          ) : (
            <div className="flex flex-col w-full">
              <EntitySearchHeader
                dateRangeAttribute="createdAtTimestamp"
                leftSidebarToggle={handleLeftSidebarToggle}
                rightSidebarToggle={null}
                rightToolbarContent={
                  <>
                    <EntitySearchToggleRefinement
                      attribute="budgetProjects.budgetProject.id"
                      label="Hide Inactive"
                      labelPlacement="bottom"
                      on={budgetProject?.id}
                      size="small"
                    />

                    <Button
                      color="success"
                      disabled={!canCreateServiceCompletionItem}
                      size="small"
                      variant="contained"
                      onClick={() =>
                        dispatch(
                          openDialog({
                            children: (
                              <ServiceCompletionItemCreateDialog
                                onClose={() => dispatch(closeDialog())}
                              />
                            ),
                            classes: { paper: 'w-full max-w-640 min-w-320 rounded-8' },
                            onClose: () => null,
                          })
                        )
                      }
                    >
                      Create
                    </Button>
                  </>
                }
              />

              <SizeMe monitorHeight monitorWidth={false}>
                {({ size }) => (
                  <div
                    style={{
                      // NOTE: NavBar + ScreenHeader + SearchHeader
                      height: `calc(100vh - ${isMobile ? 48 + 256 + 103 : 64 + 160 + 64}px)`,
                    }}
                  >
                    <EntitySearchInfiniteHitsGrid {...size} />
                  </div>
                )}
              </SizeMe>
            </div>
          )}
        </>
      }
      header={
        <>
          {!budgetProject ? (
            <FuseLoading />
          ) : (
            <BudgetProjectViewScreenHeader basePath={basePath} budgetProject={budgetProject} />
          )}
        </>
      }
      leftSidebarContent={
        <>
          {!budgetProject ? (
            <FuseLoading />
          ) : (
            <>
              <EntitySearchDrawer
                contentWidth={leftSidebarWidth - 1}
                dateRangeAttribute="createdAtTimestamp"
                disableUserSearchViewControl
                filterDefs={serviceCompletionItemSearchScreenFilterDefs}
                offsetHeight={isMobile ? 0 : 160}
                title="Completion Items"
                toggleDrawer={handleLeftSidebarToggle}
              />
            </>
          )}
        </>
      }
      leftSidebarOnClose={handleLeftSidebarClose}
      leftSidebarOpen={leftSidebarOpen}
      leftSidebarWidth={leftSidebarWidth}
      scroll={isMobile ? 'normal' : 'content'}
    />
  );
};

const BudgetProjectViewScreenCompletionItemsTab = (props) => {
  const theme = useTheme();
  const user = useSelector(selectUser);

  const serviceCompletionItemSearchScreenColumnDefs =
    buildServiceCompletionItemSearchScreenColumnDefs({
      theme,
      user,
    });

  if (props.budgetProject?.id) {
    serviceCompletionItemSearchScreenColumnDefs[0].children.unshift({
      cellRenderer: BudgetProjectViewScreenCompletionItemActiveCellRenderer,
      cellRendererParams: {
        budgetProjectId: props.budgetProject.id,
      },
      field: 'budgetProjects',
      headerName: 'Active',
      lockPinned: true,
      lockPosition: 'left',
      lockVisible: true,
      maxWidth: 100,
      minWidth: 100,
      pinned: 'left',
      resizable: false,
      sortable: false,
      width: 100,
    });
  }

  return (
    <EntitySearchAppProvider
      availableViewModes={['grid']}
      indexName="service_completion_items"
      initialColumnDefs={serviceCompletionItemSearchScreenColumnDefs}
      initialViewMode="grid"
      makeSearchAdapter={makeEntitySearchCommonClientAdapter}
      makeSearchAdapterParams={{ dateRangeAttribute: 'createdAtTimestamp' }}
      socketIoIncludeSockets={['organization']}
      socketIoRoomId="aggregate:service_completion_items"
    >
      <BudgetProjectViewScreenCompletionItemsTabRoot {...props} />
    </EntitySearchAppProvider>
  );
};

export default BudgetProjectViewScreenCompletionItemsTab;
