import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const WORK_ORDER_ISSUE_DELETE_DIALOG_SERVICE_TICKET_FRAGMENT = gql`
  fragment WorkOrderIssueDeleteDialogServiceTicketFragment on ServiceTicket {
    id
    phase {
      id
      enum
      name
    }
    workOrder {
      id
      issues {
        id
        actionItems {
          id
          commonStandardFlatRate {
            id
            quantity
          }
          commonStandardHourlyRate {
            id
            hours
            rateAmount
            technicians
          }
          commonStandardRateType {
            id
            enum
            name
          }
          costCode {
            id
            csiClassification {
              id
              name
              number
            }
          }
          description
          notToExceedAmount
          subtotalAmount
          totalAmount
        }
        notToExceedAmount
        serviceIssue {
          id
          commonTargetType {
            id
            enum
            name
          }
          targetFacilityAreas {
            id
            type {
              id
            }
          }
        }
        shippingAmount
        subtotalAmount
        taxAmount
        totalAmount
      }
      notToExceedAmount
      shippingAmount
      subtotalAmount
      taxAmount
      totalAmount
    }
  }
`;
